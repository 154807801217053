import React, { useState } from "react";
import styled from "styled-components";
import TemplateModal from "./TemplateModal.jsx";
import StyledButton from "./StyledButton.jsx";
import {
  getFinalStringJSX,
  mergeEvidence,
} from "../utils/helper functions/keyterms_contains_helper.js";
import { updateContains } from "../utils/mutations.js";
import { KEY_TERMS_BOLD_DELIMINATOR } from "../utils/constants.js";

const Wrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  overflow: scroll;
`;

const InfoText = styled.div`
  font-weight: 600;
  font-size: 1.1rem;
`;

const SubText = styled.div`
  font-size: 0.9rem;
  font-style: italic;
  margin-top: 10px;
  border-bottom: 1px solid lightgrey;
  padding-bottom: 20px;
`;

const KeyTermsWrapper = styled.div`
  flex-grow: 1;
  margin-top: 20px;
  box-sizing: border-box;
  width: 100%;
  background-color: ${(props) => props.theme.keyTermsBlue};
  border-radius: 25px;
  padding: 10px;
  overflow: scroll;
  color: black;
`;

const ButtonWrapper = styled.div`
    width: 100%;
    display flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 20px;
`;

const MergeWrapper = styled(KeyTermsWrapper)``;

const ReplaceWrapper = styled(KeyTermsWrapper)`
  background-color: #e6e6e6;
  border: 1px solid ${(props) => props.theme.keyTermsBlue};
`;

const TermHeader = styled.div`
  font-weight: 600;
  font-size: 1.1rem;
  text-decoration: underline;
  margin-bottom: 5px;
`;

const MarkEvidenceModal = ({
  open,
  setOpen,
  evidenceID,
  currentCalls,
  containsCalls,
  setSuccessMessage,
  setErrorMessage,
  setContainsCalls,
}) => {
  const [loading, setLoading] = useState(false);

  const currentEvidence = currentCalls.find((obj) => obj.id === evidenceID);
  const newEvidence = containsCalls.find((obj) => obj.id === evidenceID);

  const HideUpdatedContains = () => {
    newEvidence.contains = [];
    setContainsCalls([...containsCalls]);
  };

  if (evidenceID && currentEvidence) {
    let bodyElements = undefined;

    // What does the body look like if the call already contains evidence
    if (currentEvidence.contains && currentEvidence.contains.length > 0) {
      const mergedEvidence = mergeEvidence(
        currentEvidence.contains,
        newEvidence.contains,
        newEvidence.transcription,
        KEY_TERMS_BOLD_DELIMINATOR
      );
      bodyElements = (
        <Wrapper>
          <InfoText>
            It appears this called has already been marked as having evidence.
            Please confirm if you would like to merge or replace the current
            evidence.
          </InfoText>
          <SubText>
            (If merged or replaced, the selected content will be added to the
            column “contains” in the project view.)
          </SubText>
          <MergeWrapper>
            <TermHeader>Merge:</TermHeader>
            {getFinalStringJSX(mergedEvidence, KEY_TERMS_BOLD_DELIMINATOR)}
          </MergeWrapper>
          <ReplaceWrapper>
            <TermHeader>Replace:</TermHeader>
            {getFinalStringJSX(
              newEvidence.contains,
              KEY_TERMS_BOLD_DELIMINATOR
            )}
          </ReplaceWrapper>
          <ButtonWrapper>
            <StyledButton
              color={"#209BCF"}
              style={{
                background: "linear-gradient(#FFFFFF, #E9E9E9)",
                marginLeft: "10px",
                marginRight: "10px",
              }}
              disabled={loading}
              onClick={async () => {
                setLoading(true);
                const result = await updateContains(
                  evidenceID,
                  newEvidence.contains
                ); //Updates call in database to be newEvidence.contains
                setLoading(false);
                if (result) {
                  setSuccessMessage("Evidence Successfully Updated.");
                  HideUpdatedContains();
                } else {
                  setErrorMessage(
                    "Failed to update evidence. Please try again later."
                  );
                }
                setOpen(false);
              }}
            >
              Replace
            </StyledButton>
            <StyledButton
              color={"white"}
              style={{
                background: "linear-gradient(#209BCF, #26B7F5)",
                border: "none",
              }}
              disabled={loading}
              onClick={async () => {
                setLoading(true);
                const result = await updateContains(evidenceID, mergedEvidence); //Updates call in database to be the merged evidence
                setLoading(false);
                if (result) {
                  setSuccessMessage("Evidence Successfully Updated.");
                  HideUpdatedContains();
                } else {
                  setErrorMessage(
                    "Failed to update evidence. Please try again later."
                  );
                }
                setOpen(false);
              }}
            >
              Merge
            </StyledButton>
          </ButtonWrapper>
        </Wrapper>
      );
    } else {
      // What does the body look like if it doesn't already contain evidence.
      bodyElements = (
        <Wrapper>
          <InfoText>
            This appears to be new evidence or is important to you for this
            call. Please confirm what is below.
          </InfoText>
          <SubText>
            (If confirmed, the sentence below will be added to the column
            “contains” in the project view.)
          </SubText>
          <KeyTermsWrapper>
            {getFinalStringJSX(
              newEvidence.contains,
              KEY_TERMS_BOLD_DELIMINATOR
            )}
          </KeyTermsWrapper>
          <ButtonWrapper>
            <StyledButton
              color={"white"}
              style={{
                background: "linear-gradient(#209BCF, #26B7F5)",
                border: "none",
              }}
              disabled={loading}
              onClick={async () => {
                setLoading(true);
                const result = await updateContains(
                  evidenceID,
                  newEvidence.contains
                ); //Updates call in database to be newEvidence.contains
                setLoading(false);
                if (result) {
                  setSuccessMessage("Evidence Successfully Updated.");
                  HideUpdatedContains();
                } else {
                  setErrorMessage(
                    "Failed to update evidence. Please try again later."
                  );
                }
                setOpen(false);
              }}
            >
              Confirm
            </StyledButton>
          </ButtonWrapper>
        </Wrapper>
      );
    }

    return (
      <TemplateModal
        open={open}
        setOpen={setOpen}
        header="Contains Evidence"
        subHeader={
          currentEvidence.contains && currentEvidence.contains.length > 0
            ? "Merge or Replace Evidence"
            : "Confirm Evidence"
        }
        bodyElements={bodyElements}
        size="large"
      />
    );
  } else {
    return <div />;
  }
};

export default MarkEvidenceModal;
