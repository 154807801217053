import React, { useState, useEffect, useCallback, useContext } from "react";
import styled from "styled-components";
import MainJumbotron from "../components/MainJumbotron.jsx";
import { getCall } from "../utils/queries.js";
import TextField from "@mui/material/TextField";
import Switch from "@mui/material/Switch";
import CustomTooltip from "../components/CustomTooltip.js";
import IconButton from "@mui/material/IconButton";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import StyledButton from "../components/StyledButton.jsx";
import { updateTranscription } from "../utils/mutations.js";
import { subscribeUpdateCall } from "../utils/subscriptions.js";
import { useHistory, Link } from "react-router-dom";
import { getKeyTermsOnlyJSX } from "../utils/helper functions/keyterms_contains_helper.js";
import Backdrop from "@mui/material/Backdrop";
import Loader from "../components/Loader.jsx";
import { PDFDownloadLink } from "@react-pdf/renderer";
import WordTranscription from "../components/WordTranscription.jsx";
import PDFTranscription from "../components/PDFTranscription.jsx";
import ClassifierSummary from "../components/ClassifierSummary.jsx";
import WarningErrorSnackbars from "../components/WarningErrorSnackbar.jsx";
import { UserContext } from "../utils/context.js";
import SEO from "../components/SEO.jsx";
import { secsToMinsHours } from "../utils/time.js";
import {
  KEY_TERMS_BOLD_DELIMINATOR,
  DISPLAY_CLASSIFIER_IN_CALL_PAGE,
} from "../utils/constants.js";
import Avatar from "@mui/material/Avatar";
import { stringToColor } from "../utils/helper functions/call_helpers.js";
import { IN_PROGRESS, COMPLETE, TIMEOUT } from "../utils/constants.js";

const Wrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  min-width: 800px;

  .MuiBackdrop-root {
    z-index: 3;
  }
`;

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
`;

// >>>>>>>>>>>>>> LEFT SIDE >>>>>>>>>>>>>>
const LeftSideWrapper = styled.div`
  flex-grow: 1;
  padding: 40px 40px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const TranscriptHeading = styled.div`
  font-weight: 650;
  font-size: 1.3rem;
  color: ${(props) => props.theme.heading};
  text-decoration: underline;
  margin-bottom: 20px;
`;

const TranscriptBox = styled.div`
  padding: 10px 25px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

const TranscriptInfo = styled.div`
  padding: 0px 25px;
  display: flex;
  flex-direction: column;
`;

const TranscriptionHeader = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
`;

const SpeakerName = styled.span`
  font-weight: bold;
  color: black;
  margin-right: 15px;
`;

const SpeakerTime = styled.span`
  color: #8294a5;
  font-weight: 400;
`;

const SpeakerText = styled.div``;

// >>>>>>>>>>>>>> END LEFT SIDE >>>>>>>>>>>>>>

// >>>>>>>>>>>>>> RIGHT SIDE >>>>>>>>>>>>>>
const RightSideWrapper = styled.div`
  width: 25%;
  border-left: 2px solid grey;
  min-width: 290px;
`;

const ContentWrapper = styled.div`
  position: sticky;
  top: 20px;
`;

const BorderedBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 2px solid lightgrey;
  border-radius: 20px;
  margin: 20px;
  padding: 10px;
`;

const ButtonsWrapper = styled(BorderedBox)`
  border: none;
`;

const BoxHeading = styled.div`
  font-weight: 600;
  color: ${(props) => props.theme.heading};
  margin-bottom: 10px;
`;

const EvidenceLine = styled.div`
  font-size: 1rem;
`;

const SpeakersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-self: stretch;
`;

const SingleSpeakerWrapper = styled.div`
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`;

const TextFieldWrapper = styled.div`
  flex-grow: 1;
  margin-left: 10px;
`;

const StyledTextField = styled(TextField)`
  &.MuiFormControl-fullWidth {
  }
  .MuiFormLabel-root.Mui-focused {
    color: ${(props) => props.theme.primaryLight};
  }
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${(props) => props.theme.primaryLight};
  }
  .MuiInput-underline:after {
    border-bottom: 2px solid ${(props) => props.theme.primaryLight};
  }
`;

const LoadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const TranscribeAudio = styled.div`
  color: ${(props) => props.theme.primaryWhite};
  margin-top: 10px;
`;

const InfoText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  font-size: 1.4rem;
`;

const StyledLink = styled(Link)`
  margin: 0px 10px;
  cursor: pointer;
  color: ${(props) => props.theme.linkBlue};
  text-decoration: none;
  font-size: 1.4rem;
  margin 0px;
`;
const FailedWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  color: ${(props) => props.theme.textGrey};
  font-size: 1.9rem;
`;

// >>>>>>>>>>>>>> END RIGHT SIDE >>>>>>>>>>>>>>

const CallPage = ({ match }) => {
  const [currentCall, setCurrentCall] = useState(undefined);
  const [speakers, setSpeakers] = useState(undefined);
  const [newSpeakerNames, setNewSpeakerNames] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [stillTranscribing, setStillTranscribing] = useState(false);
  const [callFailed, setCallFailed] = useState(false);
  const [failureReason, setFailureReason] = useState(undefined);
  const [ErrorSnackbar, setErrorSnackbar] = useState(false);
  const [WarnSnackbar, setWarnSnackbar] = useState(false);
  const [showRedacted, setShowRedacted] = useState(false);

  const history = useHistory();
  const user = useContext(UserContext);

  /**
   * Stops loading and transcribing and sets the current call
   * @param {Objects} call Object which represents the call
   */
  const setCall = async (call) => {
    setLoading(false);
    setStillTranscribing(false);
    setCurrentCall(call);
  };

  /**
   * Gets the call from AWS, edit as needed and set in state. If call is still being transcribed, subscribes
   * to the call and wait till it is ready.
   */
  useEffect(() => {
    let callSubscription;

    const getCallFromAWS = async () => {
      try {
        const callID = match.params.id;
        const call = await getCall(callID);

        if (typeof call === "string") {
          console.error(`Failed to receive call due to error: ${call}`);
          history.push("/unauthorized");
          return;
        }

        switch (call.status) {
          case COMPLETE:
            // Handle completed call
            setCall(call);
            break;
          case IN_PROGRESS:
            // Handle call that is still being transcribed
            setStillTranscribing(true);
            callSubscription = subscribeUpdateCall(
              call.project.id,
              callID,
              setCall
            );
            break;
          default:
            // Handle call that failed
            setCallFailed(true);
            setFailureReason(call.errorMessage || TIMEOUT);
            setLoading(false);
            setStillTranscribing(false);
            setCurrentCall(call);
        }
      } catch (error) {
        console.error("Error fetching call:", error);
        // Handle any errors that occurred during the call fetch
      }
    };

    if (user) {
      getCallFromAWS();
    }

    // Unsubscribe from call updates when the component unmounts or dependencies change
    return () => {
      if (callSubscription) {
        callSubscription.unsubscribe();
      }
    };
  }, [history, match.params.id, user]);

  /**
   * Gets the unique speakers from the call
   */
  useEffect(() => {
    if (currentCall && currentCall.transcription && !callFailed) {
      // Extract and deduplicate speakers
      const speakers = [
        ...new Set(currentCall.transcription.map(({ speaker }) => speaker)),
      ];

      // Update speakers state
      setSpeakers(speakers);

      // Initialize an array of empty strings for speaker names
      setNewSpeakerNames(new Array(speakers.length).fill(""));
    }
  }, [currentCall, callFailed]);

  /**
   * Given a name, returns back an avatar with colors an initials
   * @param {string} name name of person on call
   * @param {string} color Hex color if you already generated it. Otherwise generates color.
   * @param {string} small Display smaller avatar
   * @returns Avatar with color and initials
   */
  const stringAvatar = useCallback((name, color, small = false) => {
    let initials = name.split(" ")[0][0];
    if (name.split(" ").length > 1) {
      initials += name.split(" ")[1][0];
    }
    if (!color) color = stringToColor(name);

    if (small) {
      return {
        sx: {
          bgcolor: color,
          width: "30px",
          height: "30px",
          fontSize: "12px",
          fontWeight: "400",
          border: "2px solid lightgrey",
          boxSizing: "border-box",
        },
        children: initials,
      };
    }

    return {
      sx: {
        bgcolor: color,
        width: "40px",
        height: "40px",
        fontSize: "16px",
        fontWeight: "600",
        border: "2px solid lightgrey",
        boxSizing: "border-box",
      },
      children: initials,
    };
  }, []);

  /**
   * Gets the JSX for the speakers and updated when user updated
   * @returns JSX for the speakers
   */
  const getSpeakers = useCallback(() => {
    //Gets the speakers JSX
    return speakers.map((speaker, index) => {
      return (
        <SingleSpeakerWrapper key={index}>
          <Avatar {...stringAvatar(speaker, undefined, true)} />
          <TextFieldWrapper>
            <StyledTextField
              index={index}
              fullWidth
              label={speaker}
              value={newSpeakerNames[index]}
              variant="standard"
              onChange={(e) => {
                const newVal = e.target.value;
                let newSpeakers = [...newSpeakerNames];
                newSpeakers[
                  e.target.parentElement.parentElement.getAttribute("index")
                ] = newVal;
                setNewSpeakerNames(newSpeakers);
              }}
            />
          </TextFieldWrapper>
        </SingleSpeakerWrapper>
      );
    });
  }, [newSpeakerNames, speakers, stringAvatar]);

  /**
   * Gets transcript and returns JSX of it
   * @returns JSX of transcript
   */
  const getTranscript = useCallback(() => {
    // Get the color once for each speaker
    let speakerColorMap = {};

    // Use normal or redacted transcript
    let callTranscript = currentCall.transcription;

    callTranscript.forEach((line) => {
      let speaker = line.speaker;
      if (!speakerColorMap[speaker]) {
        speakerColorMap[speaker] = stringToColor(speaker);
      }
    });

    //Loop through each line to make transcript
    return callTranscript.map((line, index) => {
      let speaker = line.speaker;
      return (
        <TranscriptBox key={index}>
          <Avatar {...stringAvatar(speaker, speakerColorMap[speaker])} />
          <TranscriptInfo>
            <TranscriptionHeader>
              <SpeakerName>{speaker}:</SpeakerName>
              <SpeakerTime>{secsToMinsHours(line.start_time)}</SpeakerTime>
            </TranscriptionHeader>
            <SpeakerText>
              {showRedacted && line.redactedText
                ? line.redactedText
                : line.text}
            </SpeakerText>
          </TranscriptInfo>
        </TranscriptBox>
      );
    });
  }, [currentCall, stringAvatar, showRedacted]);

  /**
   * Get's the jsx for the summary at the top of the page based on the classifier
   */
  const getSummary = useCallback(() => {
    if (currentCall && currentCall.classifierSummary) {
      return (
        <ClassifierSummary
          currentCall={currentCall}
          setCurrentCall={setCurrentCall}
        />
      );
    } else {
      return <React.Fragment />;
    }
  }, [currentCall]);

  /**
   * Update  the speakers in AWS
   */
  const updateSpeakers = () => {
    // Generate a map of speakers old names to new names.
    const speakerMap = createSpeakerMap();
    let updatedTranscript = [];
    for (const line of currentCall.transcription) {
      updatedTranscript.push({
        speaker: speakerMap[line.speaker]
          ? speakerMap[line.speaker]
          : line.speaker,
        text: line.text,
        start_time: line.start_time,
        end_time: line.end_time,
      });
    }

    setCurrentCall({
      ...currentCall,
      transcription: updatedTranscript,
    });
    updateTranscription(match.params.id, JSON.stringify(updatedTranscript));
  };

  /**
   * Creates a mapping of the old speaker names to the new speaker names
   * Ensures no duplicate values in the speakerMap.
   * Logs a message whenever a name is already taken.
   */
  const createSpeakerMap = () => {
    let speakerMap = {};
    let usedNames = new Set(speakers);

    for (const index in speakers) {
      const trimmedNewSpeakerName = newSpeakerNames[index].trim();

      // Check if the name is not empty and not already used
      if (trimmedNewSpeakerName.length > 0) {
        if (!usedNames.has(trimmedNewSpeakerName)) {
          speakerMap[speakers[index]] = trimmedNewSpeakerName;
          usedNames.add(trimmedNewSpeakerName);
        } else {
          // Log if the name is already taken
          setWarnSnackbar(
            `Please do not use a name already in use: ${trimmedNewSpeakerName}`
          );
          speakerMap[speakers[index]] = speakers[index];
        }
      } else {
        speakerMap[speakers[index]] = speakers[index];
      }
    }
    return speakerMap;
  };

  const getEvidence = useCallback(() => {
    if (
      currentCall &&
      currentCall.contains &&
      currentCall.contains.length > 0
    ) {
      return (
        <b>
          {getKeyTermsOnlyJSX(currentCall.contains, KEY_TERMS_BOLD_DELIMINATOR)}
        </b>
      );
    }
    return "No key terms searched/found on audio yet.";
  }, [currentCall]);

  return (
    <Wrapper>
      <SEO
        title="Transcript | WireTap"
        description="Look at a transcript for your call, body cam, or audio file. Adjust the speakers names on the file. Download the transcription of the file for future use."
      />
      <MainJumbotron
        title={currentCall ? currentCall.audioFileName : ""}
        metrics={[
          { header: "Date", body: currentCall ? currentCall.date : "" },
          { header: "Time", body: currentCall ? currentCall.time : "" },
          {
            header: "Duration",
            body: currentCall ? currentCall.durationString : "",
          },
        ]}
        breadcrumb={[
          { name: "My Cases", link: `/home` },
          {
            name: currentCall ? currentCall.project.title : "",
            link: currentCall ? `/case/${currentCall.project.id}` : "",
          },
          { name: currentCall ? currentCall.audioFileName : "" },
        ]}
      />
      {!loading && currentCall && speakers && !callFailed && (
        <BodyWrapper>
          <LeftSideWrapper>
            {DISPLAY_CLASSIFIER_IN_CALL_PAGE && getSummary()}
            <TranscriptHeading>Transcript</TranscriptHeading>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "20px",
              }}
            >
              {
                // Only show switch if PII redaction exists
                currentCall.transcription?.some(
                  (item) => item.redactedText
                ) && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "20px",
                    }}
                  >
                    <Switch
                      checked={showRedacted}
                      onChange={() => setShowRedacted(!showRedacted)}
                      color="primary"
                      title="Toggle to show PII Redacted Trancript"
                    />
                    <span>Show PII Redacted Trancript</span>
                    <CustomTooltip
                      title="PII (Personally Identifiable Information) redaction removes sensitive information to protect individual privacy. Toggle the switch to view or download the redacted version."
                      placement="right"
                    >
                      <IconButton size="small">
                        <HelpOutlineIcon fontSize="inherit" />
                      </IconButton>
                    </CustomTooltip>
                  </div>
                )
              }
            </div>
            {getTranscript()}
          </LeftSideWrapper>

          <RightSideWrapper>
            <ContentWrapper>
              <BorderedBox>
                <BoxHeading>Key Terms</BoxHeading>
                <EvidenceLine>{getEvidence()}</EvidenceLine>
              </BorderedBox>
              <BorderedBox>
                <BoxHeading>Speakers</BoxHeading>
                <SpeakersWrapper>{getSpeakers()}</SpeakersWrapper>
                <StyledButton
                  color={"#209BCF"}
                  style={{
                    background: "linear-gradient(#FFFFFF, #E9E9E9)",
                    alignSelf: "flex-end",
                    marginTop: "10px",
                  }}
                  size="small"
                  onClick={updateSpeakers}
                >
                  Update Speakers
                </StyledButton>
              </BorderedBox>
              <ButtonsWrapper>
                {/* <StyledButton
                  color={"white"}
                  style={{
                    background: "linear-gradient(#209BCF, #26B7F5)",
                    marginBottom: "15px",
                  }}
                  onClick={() =>
                    alert(
                      "Editing Transcript is not yet implemented. Please contact Rory McBryde for more info."
                    )
                  }
                >
                  Edit Transcript
                </StyledButton> */}

                <StyledButton
                  color={"white"}
                  style={{
                    background: "linear-gradient(#209BCF, #26B7F5)",
                    marginBottom: "15px",
                  }}
                  onClick={() => WordTranscription(currentCall, showRedacted)}
                >
                  {showRedacted ? "Download Redacted Word" : "Download Word"}
                </StyledButton>

                {currentCall.audioFileName && (
                  <PDFDownloadLink
                    document={
                      <PDFTranscription
                        currentCall={currentCall}
                        redacted={showRedacted}
                      />
                    }
                    fileName={
                      currentCall.audioFileName.substring(
                        0,
                        currentCall.audioFileName.lastIndexOf(".")
                      ) +
                      (showRedacted ? "_PII_redacted" : "") +
                      ".pdf"
                    }
                  >
                    {({ blob, url, loading, error }) => (
                      <StyledButton
                        color={"#209BCF"}
                        style={{
                          background: "linear-gradient(#FFFFFF, #E9E9E9)",
                        }}
                        disabled={loading || error}
                      >
                        {error
                          ? "Error creating PDF"
                          : loading
                          ? "Loading Doc"
                          : showRedacted
                          ? "Download Redacted PDF"
                          : "Download PDF"}
                      </StyledButton>
                    )}
                  </PDFDownloadLink>
                )}
                {!currentCall.audioFileName && (
                  <StyledButton
                    color={"#209BCF"}
                    style={{
                      background: "linear-gradient(#FFFFFF, #E9E9E9)",
                    }}
                    disabled={true}
                  >
                    Loading Doc
                  </StyledButton>
                )}
              </ButtonsWrapper>
            </ContentWrapper>
          </RightSideWrapper>
        </BodyWrapper>
      )}
      {!loading && callFailed && failureReason && (
        <FailedWrapper>
          <h1>Call Failed</h1>
          <InfoText>
            <div>{failureReason}</div>
            <div>
              To return home, click <StyledLink to="/home">here</StyledLink>
            </div>
          </InfoText>
        </FailedWrapper>
      )}
      <Backdrop open={loading}>
        <LoadingWrapper>
          <Loader width={40} height={40} fill="white" />
          {stillTranscribing && (
            <TranscribeAudio>
              Please wait while we transcribe audio...
            </TranscribeAudio>
          )}
        </LoadingWrapper>
      </Backdrop>
      <WarningErrorSnackbars
        errorMessage={ErrorSnackbar}
        setErrorMessage={setErrorSnackbar}
        warningMessage={WarnSnackbar}
        setWarningMessage={setWarnSnackbar}
      />
    </Wrapper>
  );
};

export default CallPage;
