import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import userGuide from "../utils/pdfs/User Guide.pdf";
import privacyPolicy from "../utils/pdfs/Privacy Policy.pdf";
import termsAndConditions from "../utils/pdfs/Terms and Conditions.pdf";
import PDFViewer from "../components/pdfViwer.jsx";
import { Link, useHistory } from "react-router-dom";
import SEO from "../components/SEO.jsx";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: stretch;

  @media (min-width: 900px) {
    margin-right: 12vw;
    margin-left: 12vw;
  }
`;

const LeftNavWrapper = styled.div`
  width: 250px;
  display: flex;
  flex-direction: row;
`;

const LeftNav = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 250px;
  margin-top: 35px;
`;

const LeftNavItem = styled(Link)`
  margin-top: 15px;
  cursor: pointer;
  text-decoration: ${(props) => (props.viewing ? "underline" : "default")};
  color: ${(props) =>
    props.viewing ? props.theme.primaryDark : props.theme.linkLightBlue};

  :hover {
    text-decoration: underline;
  }
`;

const ContentWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
`;

const HelpCenterPage = ({ location, match }) => {
  const containerRef = useRef(null);
  const [pdf, setPdf] = useState("userguide");
  const history = useHistory();

  let goToPage = location.hash;
  if (goToPage.length > 1) {
    goToPage = goToPage.substring(1);
  } else {
    goToPage = 0;
  }

  useEffect(() => {
    let newPDF = match.params.pdf;
    if (newPDF !== "userguide" && newPDF !== "terms" && newPDF !== "privacy") {
      history.push("/pageNotFound");
    }
    setPdf(newPDF);
  }, [match.params.pdf, history]);

  return (
    <Wrapper>
      <SEO
        title="Help Central | WireTap"
        description="Have a question about WireTap? Find our User Guide, Privacy Policy, and Terms of Service here. Reach out to us at wiretap.llc@gmail.com"
      />
      <LeftNavWrapper>
        <LeftNav>
          <LeftNavItem
            name="terms"
            viewing={pdf === "terms" ? 1 : 0}
            to="/helpcenter/terms"
          >
            Terms and Conditions
          </LeftNavItem>
          <LeftNavItem
            name="privacy"
            viewing={pdf === "privacy" ? 1 : 0}
            to="/helpcenter/privacy"
          >
            Privacy Policy
          </LeftNavItem>
          <LeftNavItem
            name="userguide"
            viewing={pdf === "userguide" ? 1 : 0}
            to="/helpcenter/userguide"
          >
            User Guide
          </LeftNavItem>
        </LeftNav>
      </LeftNavWrapper>
      <ContentWrapper ref={containerRef}>
        {pdf === "userguide" && (
          <PDFViewer
            pdf={userGuide}
            goToPage={goToPage}
            containerRef={containerRef}
          />
        )}
        {pdf === "privacy" && (
          <PDFViewer
            pdf={privacyPolicy}
            goToPage={goToPage}
            containerRef={containerRef}
          />
        )}
        {pdf === "terms" && (
          <PDFViewer
            pdf={termsAndConditions}
            goToPage={goToPage}
            containerRef={containerRef}
          />
        )}
      </ContentWrapper>
    </Wrapper>
  );
};

export default HelpCenterPage;
