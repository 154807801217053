import { API } from "aws-amplify";
import { DEFAULT_FREE_SECONDS_TO_TRANSCRIBE } from "./constants.js";

export const createUser = async (
  userID,
  secondsAvailableToTranscribe = DEFAULT_FREE_SECONDS_TO_TRANSCRIBE
) => {
  const response = await API.graphql({
    query: `
        mutation CreateUser($id: ID!, $secondsAvailableToTranscribe: Int!) {
            createUser(input: {id: $id, secondsAvailableToTranscribe: $secondsAvailableToTranscribe, showIntroTutorial: true}) {
              id
            }
          }
          `,
    variables: {
      id: userID,
      secondsAvailableToTranscribe: secondsAvailableToTranscribe,
    },
  });
  return response.data.createUser.id;
};

export const createProject = async (userID, title, type, date) => {
  const response = await API.graphql({
    query: `
        mutation CreateProject($userID: ID!, $title: String!, $type: String!, $date: Int!) {
            createProject(input: {title: $title, userID: $userID, date: $date, type: $type}) {
                id
              }
      }
      `,
    variables: { userID: userID, title: title, date: date, type: type },
  });
  return response.data.createProject.id;
};

export const createCall = async (
  id,
  projectID,
  audioFileName,
  date,
  duration
) => {
  const response = await API.graphql({
    query: `
      mutation CreateCall($id: ID!, $projectID: ID!, $audioFileName: String!, $date: Int!, $duration: Float!) {
        createCall(input: {id: $id, audioFileName: $audioFileName, projectID: $projectID, date: $date, duration:$duration, transcriptionReady: false, contains: [], classifierHasBeenReviewed: false, classifierResultWasAccepted: false, userMarkedHasEvidence: false}) {
          id
        }
      }
        `,
    variables: {
      id: id,
      projectID: projectID,
      audioFileName: audioFileName,
      date: date,
      duration: duration,
    },
  });
  return response.data.createCall.id;
};

/////////////////////// Call Updates /////////////////////////

export const updateTranscription = async (id, transcription) => {
  try {
    const response = await API.graphql({
      query: `
      mutation UpdateTranscription($id: ID!, $transcription: String!) {
        updateCall(input: {id: $id, transcription: $transcription}) {
          id
        }
      }
        `,
      variables: {
        id: id,
        transcription: transcription,
      },
    });
    return response.data.updateCall.id;
  } catch (error) {
    console.error(error);
    console.log(id);
    console.log(transcription);
  }
};

export const updateContains = async (id, contains) => {
  try {
    const response = await API.graphql({
      query: `
      mutation UpdateContains($id: ID!, $contains: [String]) {
        updateCall(input: {id: $id, contains: $contains}) {
          id
        }
      }
        `,
      variables: {
        id: id,
        contains: contains,
      },
    });
    return response.data.updateCall.id;
  } catch (error) {
    console.error(error);
    return null;
  }
};

/* When user marks classifier evidence as not relevant. This function marks:
 * classifierHasBeenReviewed = true
 * classifierResultWasAccepted = false
 */
export const classifierRejected = async (id) => {
  try {
    const response = await API.graphql({
      query: `
      mutation ClassifierRejected($id: ID!) {
        updateCall(input: {id: $id, classifierHasBeenReviewed: true, classifierResultWasAccepted: false}) {
          id
        }
      }
        `,
      variables: {
        id: id,
      },
    });
    return response.data.updateCall.id;
  } catch (error) {
    console.error(error);
    return null;
  }
};

/* When user accepts classifier evidence . This function marks:
 * classifierHasBeenReviewed = true
 * classifierResultWasAccepted = true
 */
export const classifierAccepted = async (id) => {
  try {
    const response = await API.graphql({
      query: `
      mutation ClassifierAccepted($id: ID!) {
        updateCall(input: {id: $id, classifierHasBeenReviewed: true, classifierResultWasAccepted: true}) {
          id
        }
      }
        `,
      variables: {
        id: id,
      },
    });
    return response.data.updateCall.id;
  } catch (error) {
    console.error(error);
    return null;
  }
};

/////////////////////// ADMIN /////////////////////////

export const updateSecondsAvailableToTranscribe = async (id, seconds) => {
  try {
    const response = await API.graphql({
      query: `
      mutation UpdateSecondsAvailableToTranscribe($id: ID!, $seconds: Int!) {
        updateUser(input: {id: $id, secondsAvailableToTranscribe: $seconds}) {
          id,
          secondsAvailableToTranscribe
        }
      }
        `,
      variables: {
        id: id,
        seconds: seconds,
      },
    });
    return response.data.updateUser.secondsAvailableToTranscribe;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const updateShowIntroTutorial = async (id, showIntroTutorial) => {
  try {
    const response = await API.graphql({
      query: `
      mutation UpdateShowIntroTutorial($id: ID!, $showIntroTutorial: Boolean) {
        updateUser(input: {id: $id, showIntroTutorial: $showIntroTutorial}) {
          id,
          showIntroTutorial
        }
      }
        `,
      variables: {
        id: id,
        showIntroTutorial: showIntroTutorial,
      },
    });
    return response.data.updateUser.showIntroTutorial;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const deleteCalls = async (callIDs) => {
  for (const call of callIDs) {
    await API.graphql({
      query: `
        mutation DeleteCall($id: ID!) {
            deleteCall(input: {id: $id}){
                id
            }
          }
            `,
      variables: {
        id: call,
      },
    });
  }
};

export const deleteProjects = async (projectIDs) => {
  for (const project of projectIDs) {
    await API.graphql({
      query: `
          mutation DeleteProject($id: ID!) {
              deleteProject(input: {id: $id}){
                  id
              }
            }
              `,
      variables: {
        id: project,
      },
    });
  }
};

export const deleteUser = async (userID) => {
  await API.graphql({
    query: `
            mutation DeleteUser($id: ID!) {
                deleteUser(input: {id: $id}){
                    id
                }
              }
                `,
    variables: {
      id: userID,
    },
  });
};
