import { API } from "aws-amplify";
import { unixToLocal, unixToLocalTime } from "./time.js";
import {
  durationOfAllCallsInCase,
  durationOfCall,
  secsToString,
} from "../utils/helper functions/files_data_helpers.js";
import {
  IN_PROGRESS,
  COMPLETE,
  FAILED,
  CALL_TIMEOUT_SECONDS,
} from "./constants.js";

/**
 * Checks if the user exists within the database
 * @param {string} username uuid of user
 * @returns Bool wether the user exists in the database
 */
export const doesUserExist = async (username) => {
  const response = await API.graphql({
    query: `
        query doesUserExist($id: ID!) {
          getUser(id: $id) {
            id
          }
        }
      `,
    variables: { id: username },
  });
  if (response.data.getUser && response.data.getUser.id) return true;
  return false;
};

//TODO: Check if I really need to return the calls. Doesn't seem like it.
/**
 * Gets all the users projects and calls.
 * @param {string} username uuid of user
 * @param {bool} returnCalls Whether to return the calls or not
 * @returns An object with all the user's projects and calls
 */
export const getUsersProjectsAndCalls = async (
  username,
  returnCalls = true
) => {
  let projects = [];
  let nextToken = null;
  do {
    const response = await API.graphql({
      query: `
        query MyQuery($id: ID!, $nextToken: String) {
          getUser(id: $id) {
            projects(nextToken: $nextToken) {
              items {
                id
                title
                type
                date
              }
              nextToken
            }
          }
        }
      `,
      variables: { id: username, nextToken },
    });

    if (
      response.data &&
      response.data.getUser &&
      response.data.getUser.projects
    ) {
      projects.push(...response.data.getUser.projects.items);
      nextToken = response.data.getUser.projects.nextToken;
    } else {
      break;
    }
  } while (nextToken);

  //Now that we have all the projects, lets get every call for each project
  for (let project of projects) {
    let projectNextToken = null;
    let calls = [];

    do {
      const projectResponse = await API.graphql({
        query: `
          query GetProjectCalls($id: ID!, $nextToken: String) {
            getProject(id: $id) {
              id
              calls(nextToken: $nextToken) {
                items {
                  id
                  createdAt
                  duration
                  transcriptionReady
                  errorMessage
                  classifierContainsEvidence
                  classifierHasBeenReviewed
                  classifierResultWasAccepted
                  userMarkedHasEvidence
                }
                nextToken
              }
            }
          }
        `,
        variables: { id: project.id, nextToken: projectNextToken },
      });

      if (
        projectResponse.data &&
        projectResponse.data.getProject &&
        projectResponse.data.getProject.calls
      ) {
        calls.push(...projectResponse.data.getProject.calls.items);
        projectNextToken = projectResponse.data.getProject.calls.nextToken;
      } else {
        break;
      }
    } while (projectNextToken);

    project.calls = calls;
  }

  //Loop through every project and summarize it
  let finalResults = [];
  for (const project of projects) {
    // Get status of project by looking at all calls
    let projectStatus = COMPLETE;
    let callsWithEvidence = 0;
    for (const call of project.calls) {
      // Get status of call and project
      if (!call.transcriptionReady) {
        if (call.errorMessage != null) {
          call.status = call.errorMessage;
        } else {
          const timeSinceCreation =
            Math.floor(Date.now() / 1000) -
            Math.floor(new Date(call.createdAt).getTime() / 1000);
          if (timeSinceCreation > CALL_TIMEOUT_SECONDS) {
            call.status = FAILED;
          } else {
            call.status = IN_PROGRESS;
            projectStatus = IN_PROGRESS;
          }
        }
      } else {
        call.status = COMPLETE;
      }

      //Find number of calls with evidence on it
      if (call.userMarkedHasEvidence) {
        callsWithEvidence++;
      } else if (call.classifierContainsEvidence) {
        if (
          !call.classifierHasBeenReviewed ||
          (call.classifierHasBeenReviewed && call.classifierResultWasAccepted)
        ) {
          callsWithEvidence++;
        }
      }
    }

    // Get duration of all calls
    const duration = durationOfAllCallsInCase(project.calls, "int");

    //Create summary of project
    let item = {
      id: project.id,
      title: project.title,
      dateMadeUnix: project.date,
      dateMade: unixToLocal(project.date, true),
      type: project.type,
      callCount: project.calls.length,
      totalDuration: duration,
      totalDurationString: secsToString(duration),
      status: projectStatus,
      totalCallWithEvidence: callsWithEvidence,
    };
    if (returnCalls) {
      item.calls = project.calls;
    }
    finalResults.push(item);
  }
  return finalResults;
};

export const getUser = async (username) => {
  try {
    const response = await API.graphql({
      query: `
          query doesUserExist($id: ID!) {
            getUser(id: $id) {
              id
              secondsAvailableToTranscribe
              showIntroTutorial
            }
          }
        `,
      variables: { id: username },
    });
    return response.data.getUser;
  } catch (err) {
    console.err(err);
  }
};

/**
 * Gets a call from AWS
 * @param {string} id uuid of call
 * @returns call object from AWS
 */
export const getCall = async (id) => {
  try {
    const response = await API.graphql({
      query: `
        query MyQuery($id: ID!) {
            getCall(id: $id) {
              id
              createdAt
              audioFileName
              contains
              duration
              date
              transcriptionReady
              errorMessage
              transcription
              classifierContainsEvidence
              classifierTags
              classifierSummary
              classifierHasBeenReviewed
              classifierResultWasAccepted
              userMarkedHasEvidence
              project {
                title
                id
              }
            }
        }
          `,
      variables: { id: id },
    });
    let call = response.data.getCall;
    let callStatus = COMPLETE;
    if (!call.transcriptionReady) {
      if (call.errorMessage != null) {
        callStatus = call.errorMessage;
      } else {
        const timeSinceCreation =
          Math.floor(Date.now() / 1000) -
          Math.floor(new Date(call.createdAt).getTime() / 1000);
        if (timeSinceCreation > CALL_TIMEOUT_SECONDS) {
          callStatus = FAILED;
        } else {
          callStatus = IN_PROGRESS;
        }
      }
    }
    call.status = call.transcriptionReady ? COMPLETE : callStatus;
    call.transcription = JSON.parse(call.transcription);

    call.time = unixToLocalTime(call.date, false);
    call.date = unixToLocal(call.date, true);
    call.durationString = durationOfCall(call);
    return call;
  } catch (error) {
    // Typically unauthorized
    if (error.errors && error.errors.length > 0) {
      return error.errors[0].errorType;
    }
  }
};

/**
 * Gets a specific project from AWS and all the calls associated with it
 * @param {string} projectID ID of project
 * @param {Boolean} getTranscriptions whether to return the transcriptions of the calls or not
 * @returns project object from AWS
 */
export const getProject = async (projectID, getTranscriptions = false) => {
  let calls = [];
  let nextToken = null;
  let projectStatus = COMPLETE;
  let caseResponse;

  try {
    do {
      const response = await API.graphql({
        query: `
        query MyQuery($id: ID!, $nextToken: String) {
          getProject(id: $id) {
            id
            date
            title
            type
            calls(nextToken: $nextToken) {
              items {
                createdAt
                contains
                date
                duration
                id
                audioFileName
                transcriptionReady
                errorMessage
                ${getTranscriptions ? "transcription" : ""}
                classifierContainsEvidence
                classifierTags
                classifierSummary
                classifierHasBeenReviewed
                classifierResultWasAccepted
                userMarkedHasEvidence
              }
              nextToken
            }
          }
        }
      `,
        variables: { id: projectID, nextToken },
      });

      caseResponse = response.data.getProject;
      const caseCalls = caseResponse.calls.items;

      calls = calls.concat(caseCalls);

      if (nextToken !== caseResponse.calls.nextToken) {
        nextToken = caseResponse.calls.nextToken;
      } else {
        nextToken = null;
      }

      for (let call of caseCalls) {
        if (!call.transcriptionReady) {
          if (call.errorMessage != null) {
            call.status = call.errorMessage;
          } else {
            const timeSinceCreation =
              Math.floor(Date.now() / 1000) -
              Math.floor(new Date(call.createdAt).getTime() / 1000);
            if (timeSinceCreation > CALL_TIMEOUT_SECONDS) {
              call.status = FAILED;
            } else {
              call.status = IN_PROGRESS;
              projectStatus = IN_PROGRESS;
            }
          }
        } else {
          call.status = COMPLETE;
        }
        call.durationString = durationOfCall(call);
        call.dateUnixTime = call.date;
        call.date = unixToLocal(call.date, false);
      }
    } while (nextToken !== null);

    const caseData = {
      title: caseResponse.title,
      dateMade: unixToLocal(caseResponse.date, true),
      type: caseResponse.type,
      callCount: calls.length,
      totalDuration: durationOfAllCallsInCase(calls),
      status: projectStatus,
      calls: calls,
    };

    return caseData;
  } catch (error) {
    // Typically unauthorized
    if (error.errors && error.errors.length > 0) {
      return error.errors[0].errorType;
    }
  }
};

///////////////////// ADMIN /////////////////////

/**
 * Gets all users from AWS database.
 * @param {boolean} secondsAvailable Whether or not to return the available seconds
 * @param {boolean} showIntroTutorial Whether or not to return the show Intro Tutorial field
 * @returns If all params are false, then returns an array of user IDs. Else, returns an array of objects.
 */
export const listUsers = async (
  secondsAvailable = false,
  showIntroTutorial = false
) => {
  try {
    const response = await API.graphql({
      query: `
        query MyQuery {
          listUsers {
            items {
              id
              ${secondsAvailable ? "secondsAvailableToTranscribe" : ""}
              ${showIntroTutorial ? "showIntroTutorial" : ""}
            }
          }
        }
          `,
    });
    if (!secondsAvailable)
      return response.data.listUsers.items.map((item) => item.id);
    return response.data.listUsers.items;
  } catch (err) {
    console.error(err);
  }
};

/**
 * Get all projects in AWS
 * @param {boolean} listOwners Whether or not to return owner ID with projects
 * @returns Array of project IDs or array of project objects
 */
export const listProjects = async (listOwners = true) => {
  try {
    const response = await API.graphql({
      query: `
        query MyQuery {
          listProjects {
            items {
              id
              ${listOwners ? "owner" : ""}
            }
          }
        }
          `,
    });
    if (!listOwners)
      return response.data.listProjects.items.map((item) => item.id);
    return response.data.listProjects.items;
  } catch (err) {
    console.error(err);
  }
};

/**
 * Get all calls in AWS
 * @param {boolean} listOwners Whether or not to return owner ID with projects
 * @returns Array of call IDs or array of call objects
 */
export const listCalls = async (
  listOwners = true,
  listTranscriptions = false
) => {
  try {
    let call_ids = [];
    let calls = [];
    let nextToken = null;

    do {
      const response = await API.graphql({
        query: `
          query MyQuery($nextToken: String) {
            listCalls(nextToken: $nextToken) {
              items {
                id
                ${listOwners ? "owner" : ""}
                ${listTranscriptions ? "transcription" : ""}
              }
              nextToken
            }
          }
        `,
        variables: { nextToken: nextToken },
      });

      for (let item of response.data.listCalls.items) {
        call_ids.push(item.id);
        calls.push(item);
        nextToken = response.data.listCalls.nextToken;
      }
    } while (nextToken);

    // Uncomment the lines below if you want to return the results from the function
    if (!listOwners && !listTranscriptions) return call_ids;
    return calls;
  } catch (err) {
    console.error(err);
  }
};
