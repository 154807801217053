import { MAX_FILE_SIZE_MB, MAX_DURATION_PER_FILE } from "../constants.js";

export const getFileSizeInMB = (fileSize) => {
  return Math.round((fileSize / 1000000) * 100) / 100;
};

export const filesUnderSizeLimit = (fileList) => {
  let newFiles = [];
  for (const file of fileList) {
    if (file.size / 1000000 <= MAX_FILE_SIZE_MB) {
      newFiles.push(file);
    }
  }
  return newFiles;
};

export const filesUnderDurationLimit = (fileList) => {
  let newFiles = [];
  for (const file of fileList) {
    if (file.duration && file.duration < MAX_DURATION_PER_FILE) {
      newFiles.push(file);
    }
  }
  return newFiles;
};

export const filesUnderLimits = (fileList) => {
  fileList = filesUnderSizeLimit(fileList);
  fileList = filesUnderDurationLimit(fileList);
  return fileList;
};

export const durationOfAllCallsInCase = (calls, type = "string") => {
  let totalTime = 0;
  for (const call of calls) {
    totalTime += call.duration;
  }
  if (type === "string") {
    return secsToString(totalTime, true);
  } else if (type === "int") {
    return totalTime;
  }
};

export const durationOfCall = (call) => {
  return secsToString(call.duration);
};

/**
 * Takes in seconds and returns back a string that changes based on params.
 * By default, if hours exists, it shows hours and mins. If mins exist it shows mins and seconds.
 * Turn shortestOnly to True to only show hours, mins, or seconds.
 * Turn on displayAll to show the highest value available to the lowest. So if hours, it shows mins and secs too.
 * @param {Int} seconds the number of seconds
 * @param {boolean} shortestOnly Only display hours or mins or secs. Whichever is the largest
 * @param {boolean} displayAll displays Hours, Mins, and Seconds if they exist.
 * @returns String of time
 */
export const secsToString = (
  seconds,
  shortestOnly = false,
  displayAll = false
) => {
  if (seconds === undefined) return "";
  if (seconds === 0) return "0 seconds";

  const isNegative = seconds < 0;
  seconds = Math.abs(seconds);

  const HOUR = 60 * 60;
  const MIN = 60;

  let hours = Math.floor(seconds / HOUR);
  seconds -= hours * HOUR;
  let mins = Math.floor(seconds / MIN);
  seconds -= mins * MIN;
  let secs = Math.floor(seconds);

  let timeString = "";

  if (shortestOnly) {
    if (hours) {
      timeString = hours + " hrs";
    } else if (mins) {
      timeString = mins + " mins";
    } else {
      timeString = secs + " secs";
    }
  } else if (displayAll) {
    if (hours) {
      timeString += hours + " hrs ";
    }
    if (hours || mins) {
      timeString += mins + " mins ";
    }
    if (secs) {
      timeString += secs + " secs";
    }
  } else {
    if (hours) {
      timeString += hours + " hrs ";
    }
    if (hours || mins) {
      timeString += mins + " mins ";
    }
    if (!hours) {
      timeString += secs + " secs";
    }
  }

  if (isNegative) {
    timeString = "-" + timeString;
  }

  return timeString;
};
