import React from "react";
import styled from "styled-components";
import SEO from "../components/SEO.jsx";
import logoSrc from "../utils/images/WT Logo.png";

const Wrapper = styled.div`
  display flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
`;

const Logo = styled.img`
  width: 200px;
  height: auto;
  margin-top: 20px;
`;

const Heading = styled.h1``;

const MaintenancePage = () => {
  return (
    <Wrapper>
      {" "}
      <SEO
        title="Maintenance | WireTap"
        description="Template Page. Use those transcriptions on your calls, body cams, or audio file. Download the transcription of the file for future use. Search through the transcriptions for key terms."
      />
      <Heading>WireTap Currently Under Maintenance</Heading>
      <Logo src={logoSrc} />
    </Wrapper>
  );
};

export default MaintenancePage;
