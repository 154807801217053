import React from "react";
import styled, { useTheme } from "styled-components";
import StyledButton from "../components/StyledButton.jsx";
import { useHistory } from "react-router-dom";
import { DEFAULT_FREE_SECONDS_TO_TRANSCRIBE } from "../utils/constants.js";
import { roundToDecimal } from "../utils/helper functions/general_helper.js";
import SEO from "../components/SEO.jsx";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import SocialCarouselComponent from "../components/SocialCarouselComponent.jsx";

//Images
import gavel3src from "../utils/images/gavel3.jpg";
import RoryMcBrydeImage from "../utils/images/Rory McBryde.jpeg";
import PodcastImage from "../utils/images/Criminal Thoughts podcast.jpeg";
import WTLogo from "../utils/images/WT White.png";
import UNICRILogo from "../utils/images/UNICRI Logo.png";

//Material UI
import PhoneEnabledIcon from "@mui/icons-material/PhoneEnabled";
import SearchIcon from "@mui/icons-material/Search";
import SmartToyIcon from "@mui/icons-material/SmartToy";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;

const Jumbotron = styled.div`
  width: 100%;
  height: ${(props) => props.theme.contentHeight};
  position: relative;
  min-height: 518px;

  ${(props) => props.theme.extraSmall} {
    max-height: 600px;
  }
`;

const JumbotronImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;

  ${(props) => props.theme.extraSmall} {
    object-position: 60% 100%;
  }

  ${(props) => props.theme.small} {
    object-position: 70% 100%;
  }
  ${(props) => props.theme.medium} {
    object-position: 70% 100%;
  }
  ${(props) => props.theme.large} {
    object-position: 70% 100%;
  }
  ${(props) => props.theme.extraLarge} {
    object-position: 100% 100%;
  }
`;

const JumbotronTextWrapper = styled.div`
  position: absolute;
  box-sizing: border-box;
  backdrop-filter: blur(2px);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px 30px 0px;
  background-color: rgba(255, 255, 255, 0.7);

  ${(props) => props.theme.extraSmall} {
    background-color: rgba(255, 255, 255, 0.6);
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    color: black;
  }

  ${(props) => props.theme.small} {
    width: 70%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: fit-content;
    color: ${(props) => props.theme.infoText};
  }

  ${(props) => props.theme.medium} {
    width: 45%;
    left: 5%;
    top: 50%;
    transform: translate(0%, -50%);
    height: fit-content;
    color: ${(props) => props.theme.infoText};
  }
`;

const JumbotronTitle = styled.div`
  color: ${(props) => props.theme.primaryLight};
  font-weight: bold;
  margin-top: 20px;
  ${(props) => props.theme.extraSmall} {
    font-size: 3.5rem;
    margin-bottom: 20px;
  }

  ${(props) => props.theme.small} {
    margin-bottom: 10px;
    font-size: 4.5rem;
  }
`;

const JumbotronTextHeader = styled.div`
  font-size: 1.5rem;
  margin-bottom: 40px;
  text-align: center;
`;

const JumbotronTextBody = styled.div`
  font-size: 1.1rem;
  ${(props) => props.theme.extraSmall} {
    text-align: center;
  }
  ${(props) => props.theme.small} {
    text-align: center;
  }
  ${(props) => props.theme.medium} {
    text-align: left;
  }
`;

const JumbotronButton = styled(StyledButton)`
  align-self: flex-end;
  margin-top: 20px;

  ${(props) => props.theme.extraSmall} {
    align-self: center;
    margin-top: 40px;
  }
`;

const InfoDivWrapper = styled.div`
  background-color: ${(props) => props.theme.primaryLight};
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 60px 20px;
`;

const InfoDivTextWrapper = styled.div`
  width: auto;
  height: 80%;
  display: flex;
  flex-direction: column;

  ${(props) => props.theme.large} {
    width: 70%;
  }
`;

const InfoDivTextWrapperExtended = styled(InfoDivTextWrapper)`
  ${(props) => props.theme.large} {
    width: 100%;
  }
`;

const InfoDivSocialWrapper = styled.div`
  width: 100%;
  ${(props) => props.theme.large} {
    width: 70%;
  }
`;

const InfoDivHeader = styled.div`
  font-size: 2rem;
  text-align: center;
  margin-bottom: 20px;
  font-weight: bold;

  ${(props) => props.theme.large} {
    font-size: 2.5rem;
  }
`;

const InfoDivBody = styled.div`
  text-align: center;
  font-size: 1.1rem;
  flex-direction: column;

  display: flex;
  justify-content: center;
  align-items: center;

  ${(props) => props.theme.medium} {
    flex-direction: row;
    align-items: flex-start;
  }
`;

const InfoDivListItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 2vw;
  padding-right: 2vw;
  margin-bottom: 60px;

  ${(props) => props.theme.medium}{
    width: 200px;
    margin-bottom: 0px;
  }

  ${(props) => props.theme.large}{
    width 300px;
  }

  ${(props) => props.theme.extraLarge}{
    width 350px;
  }
`;

const InfoDivSubHeader = styled.div`
  color: ${(props) => props.theme.primaryLight};
  margin-top: 35px;
  margin-bottom: 20px;
  font-size: 1.5rem;
  font-weight: 350;
`;

const InfoDivText = styled.div`
  color: ${(props) => props.theme.infoText};
`;

const PriceHeader = styled.div`
  margin-top: 40px;
  margin-bottom: 20px;
  font-size: 1.5rem;
  font-weight: 350;
  flex-grow: 1;
`;

const PriceBody = styled.div`
  border-top: 2px solid white;
  padding-top: 20px;
  padding-bottom: 40px;
  flex-grow: 1;
`;

const GetStartedButton = styled(StyledButton)`
  align-self: center;
  margin-top: 30px;
`;

const StyledLink = styled.a`
  font-weight: bold;
  color: white;
  :hover {
    color: ${(props) => props.theme.linkLightBlue};
  }
`;

const NoPaymentInfoWrapper = styled.div`
  color: #454545;
  font-size: 0.8rem;
  font-weight: bold;
  margin-top: 50px;
  margin-bottom: 5px;

  ${(props) => props.theme.small} {
    margin-top: 15px;
  }
`;

function WelcomePage() {
  const history = useHistory();
  const theme = useTheme();

  return (
    <Wrapper name="Welcome Wrapper">
      <SEO
        title="Welcome | WireTap"
        description="WireTap transcribes your audio files to find evidence. Search for key terms in jail calls. Transcribe body cam and other a/v files to find relevant evidence quickly. Stop wasting time listening to hours of audio. Just WireTap it."
        url="https://www.wiretaptech.come"
        type="website"
      />
      <Jumbotron>
        <JumbotronImage src={gavel3src} />
        <JumbotronTextWrapper>
          <JumbotronTitle>WireTap</JumbotronTitle>
          <JumbotronTextHeader>
            Discover Jail Call Evidence With AI
          </JumbotronTextHeader>
          <JumbotronTextBody>
            WireTap transcribes hours of jail calls in minutes. Our AI will
            instantly alert you to potential evidence. Wiretap helps you
            “listen” to jail calls faster than defendants can make them!
          </JumbotronTextBody>
          <JumbotronButton
            color={"white"}
            onClick={() => history.push("/login#signUp")}
          >
            Try For Free
          </JumbotronButton>
          <NoPaymentInfoWrapper></NoPaymentInfoWrapper>
        </JumbotronTextWrapper>
      </Jumbotron>

      {/* Section under Jumbotron */}
      <InfoDivWrapper>
        <InfoDivTextWrapper>
          <InfoDivHeader>Search Hours of Jail Calls in Minutes</InfoDivHeader>
          <InfoDivBody>
            <div>
              Wish you could keep up with inmates' jail calls without listening
              all day? Now you can with WireTap’s lightning fast transcripts and
              AI analysis! Try it yourself with a{" "}
              <StyledLink href="/login#signUp">free trial</StyledLink> or email
              us at{" "}
              <StyledLink href="mailto:sales@wiretaptech.com">
                sales@wiretaptech.com
              </StyledLink>{" "}
              and we’ll set up a demo!
            </div>
          </InfoDivBody>
        </InfoDivTextWrapper>
      </InfoDivWrapper>

      {/* Our Features */}
      <InfoDivWrapper style={{ backgroundColor: "white" }}>
        <InfoDivTextWrapperExtended>
          <InfoDivHeader
            style={{ color: theme.primaryLight, marginBottom: "60px" }}
          >
            Our Features
          </InfoDivHeader>
          <InfoDivBody>
            <InfoDivListItem>
              <SmartToyIcon sx={{ color: theme.primaryLight, fontSize: 50 }} />
              <InfoDivSubHeader>AI Analysis</InfoDivSubHeader>
              <InfoDivText>
                WireTap’s AI searches transcripts and instantly alerts you to
                evidence in all types of criminal cases!
              </InfoDivText>
            </InfoDivListItem>
            <InfoDivListItem>
              <PhoneEnabledIcon
                sx={{ color: theme.primaryLight, fontSize: 50 }}
              />
              <InfoDivSubHeader>Fast Transcriptions</InfoDivSubHeader>
              <InfoDivText>
                Load multiple hours of files into WireTap and the time stamped
                transcripts will be done in minutes!
              </InfoDivText>
            </InfoDivListItem>
            <InfoDivListItem>
              <SearchIcon sx={{ color: theme.primaryLight, fontSize: 50 }} />
              <InfoDivSubHeader>Keyword Search</InfoDivSubHeader>
              <InfoDivText>
                Defendants speaking in slang or code? No problem! Use custom
                keyword searches across all your transcripts to pinpoint
                relevant information quickly!
              </InfoDivText>
            </InfoDivListItem>
          </InfoDivBody>
        </InfoDivTextWrapperExtended>
      </InfoDivWrapper>

      <InfoDivWrapper style={{ backgroundColor: "white" }}>
        <InfoDivTextWrapperExtended>
          <InfoDivHeader
            style={{ color: theme.primaryLight, marginBottom: "60px" }}
          >
            Let's Get You Started
          </InfoDivHeader>
          <InfoDivBody
            style={{
              backgroundColor: theme.primaryLight,
              borderRadius: "15px",
            }}
          >
            <InfoDivListItem>
              <PriceHeader>Free Trial</PriceHeader>
              <PriceBody>
                <StyledLink href="/login#signUp">Sign up today</StyledLink> and
                get{" "}
                {roundToDecimal(
                  DEFAULT_FREE_SECONDS_TO_TRANSCRIBE / 60 / 60,
                  0
                )}{" "}
                free hours of transcriptions and analysis! No payment
                information required.
              </PriceBody>
            </InfoDivListItem>
            <InfoDivListItem>
              <PriceHeader>Standard Pricing</PriceHeader>
              <PriceBody>
                $14.99 per hour. ($1 per hour discount when purchasing 1000
                hours or more!)
              </PriceBody>
            </InfoDivListItem>
            <InfoDivListItem>
              <PriceHeader>Law Enforcement Pricing</PriceHeader>
              <PriceBody>
                <s style={{ color: "rgb(245, 100, 100)" }}>
                  <span style={{ color: "white" }}>$14.99</span>
                </s>{" "}
                <span style={{ color: "rgb(245, 100, 100)" }}>$9.99</span> or
                less for verified law enforcement! Contact us at{" "}
                <StyledLink href="mailto:sales@wiretaptech.com">
                  sales@wiretaptech.com
                </StyledLink>{" "}
                for pricing to fit your agency’s needs!
              </PriceBody>
            </InfoDivListItem>
          </InfoDivBody>
          <GetStartedButton
            color={"white"}
            onClick={() => history.push("/login#signUp")}
          >
            Get Started
          </GetStartedButton>
        </InfoDivTextWrapperExtended>
      </InfoDivWrapper>

      {/* Social Section */}
      <InfoDivWrapper>
        <InfoDivSocialWrapper>
          <Carousel
            autoPlay
            interval={5000}
            infiniteLoop
            showThumbs={false}
            showStatus={false}
          >
            <SocialCarouselComponent
              image={RoryMcBrydeImage}
              title="CEO Rory McBryde"
              text={
                <span>
                  "Throughout my career as a prosecutor, I witnessed an
                  overwhelming surge in digital evidence, but not enough
                  efficient tools to analyze it quickly. I founded WireTap to
                  help law enforcement “search” through jail calls instead of
                  manual listening. My goal is to make WireTap an affordable,
                  user-friendly tool for all law enforcement agencies,
                  regardless of budget. Questions? Email{" "}
                  <StyledLink href="mailto:rmcbryde@wiretaptech.com">
                    rmcbryde@wiretaptech.com
                  </StyledLink>{" "}
                  or connect on{" "}
                  <StyledLink
                    href="https://www.linkedin.com/in/rory-mcbryde-18b76951/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    LinkedIn
                  </StyledLink>
                  ! ”
                </span>
              }
            />
            <SocialCarouselComponent
              image={UNICRILogo}
              title="AI For Safer Children Tech Provider"
              subtitle={
                <StyledLink
                  href="https://unicri.it/topics/AI-for-Safer-Children"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  UNICR Website
                </StyledLink>
              }
              text="The AI for Safer Children Initiative is a collaboration between the UN Interregional Crime and Justice Research Institute and the UN Centre for AI and Robotics. It helps law enforcement use AI technology to combat child abuse globally. WireTap's AI transcriptions cover over 100 languages, and WireTap is one of the tools offered as part of the Initiative’s Global Hub resources."
            />
            <SocialCarouselComponent
              image={PodcastImage}
              link="https://www.youtube.com/watch?v=pPZWu-gEiPE"
              title="WireTap CEO Rory McBryde on the “Criminal Thoughts” podcast with Brett Johnson"
              subtitle={
                <StyledLink
                  href="https://www.youtube.com/watch?v=pPZWu-gEiPE"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Watch Interview
                </StyledLink>
              }
              text={
                <div>
                  <div style={{ marginBottom: "10px" }}>
                    Brett and Rory discuss their experience from both sides of
                    the bars on how criminals think and use jail phone calls,
                    and how detectives and prosecutors can now use AI to catch
                    them!
                  </div>
                </div>
              }
            />
            <SocialCarouselComponent
              image={WTLogo}
              title="Schedule Demo"
              subtitle=""
              text="Discover the power of WireTap firsthand! Schedule a quick 20 minute demo to see how our AI-driven transcription and search tool can revolutionize your investigations. Email us at info@wiretaptech.com to book your personalized demo! 
              "
            />
          </Carousel>
        </InfoDivSocialWrapper>
      </InfoDivWrapper>
    </Wrapper>
  );
}
export default WelcomePage;
