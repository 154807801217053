import React, { useState } from "react";
import styled from "styled-components";
import {
  getFinalStringJSX,
  getKeyTermsOnlyJSX,
} from "../utils/helper functions/keyterms_contains_helper.js";
import Popover from "@mui/material/Popover";
import { KEY_TERMS_BOLD_DELIMINATOR } from "../utils/constants.js";

const Wrapper = styled.div`
  width: ${(props) => props.maxwidth}px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-weight: bold;
`;

const StyledPopover = styled(Popover)`
  .MuiPaper-root {
    max-width: 459px;
  }
`;

const PopoverInfoWrapper = styled.div`
  padding: 10px;
  border-radius: 25px;
`;

const RenderCellExpand = ({ value, width }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Wrapper
      maxwidth={width}
      onMouseEnter={handlePopoverOpen}
      onMouseLeave={handlePopoverClose}
    >
      {getKeyTermsOnlyJSX(value, KEY_TERMS_BOLD_DELIMINATOR)}
      <StyledPopover
        maxwidth={width}
        anchorEl={anchorEl}
        id="mouse-over-popover"
        sx={{
          pointerEvents: "none",
        }}
        open={open}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <PopoverInfoWrapper>
          {getFinalStringJSX(value, KEY_TERMS_BOLD_DELIMINATOR)}
        </PopoverInfoWrapper>
      </StyledPopover>
    </Wrapper>
  );
};

export default RenderCellExpand;
