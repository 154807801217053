import React, { useState, useRef, useEffect, useCallback } from "react";
import styled from "styled-components";
import TemplateModal from "./TemplateModal.jsx";
import Chip from "@mui/material/Chip";
import TextField from "@mui/material/TextField";
import StyledButton from "./StyledButton.jsx";
import { useHistory } from "react-router-dom";

const Wrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  overflow: scroll;
`;

const KeyTermBackground = styled.div`
  box-sizing: border-box;
  width: 100%;
  background-color: ${(props) => props.theme.keyTermsBlue};
  border-radius: 25px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: scroll;
`;

const KeyTermHeader = styled.div`
  font-weight: 450;
  font-size: 1.1rem;
`;

const KeyTermBody = styled.div`
  margin-bottom: 10px;
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
`;

const StyledChip = styled(Chip)`
  &.MuiButtonBase-root {
    background-color: white;
    margin: 10px 5px 0px;
  }
`;

const Instructions = styled.div`
  margin-top: 15px;
  font-weight: 450;
`;

const BottomRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`;

const InputWrapper = styled.div`
  flex-grow: 1;
`;

const StyledTextField = styled(TextField)`
  &.MuiFormControl-fullWidth {
    margin-top: 20px;
  }
  .MuiFormLabel-root.Mui-focused {
    color: ${(props) => props.theme.primaryLight};
  }
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${(props) => props.theme.primaryLight};
  }
  .MuiInput-underline:after {
    border-bottom: 2px solid ${(props) => props.theme.primaryLight};
  }
`;

const KeyTermsModal = ({
  open,
  setOpen,
  caseID,
  startingKeyTerms = [],
  setSearchKeyTerms = undefined,
}) => {
  const [keyTerms, setKeyTerms] = useState(startingKeyTerms);
  const [keyTermsInput, setKeyTermsInput] = useState("");
  const history = useHistory();
  const inputElement = useRef(null);

  /**
   * Focus on text field when first open
   */
  useEffect(() => {
    if (inputElement.current && open) {
      inputElement.current.children[1].children[0].focus(); // Gets to the input element to focus on text field
    }
  }, [open]);

  const addKeyTerms = useCallback(() => {
    if (keyTermsInput.length > 0) {
      let newTerms = keyTermsInput.split(",").map((term) => term.trim());
      newTerms = newTerms.filter((a) => a.length > 0);
      let allTerms = new Set([...keyTerms, ...newTerms]);
      setKeyTerms([...allTerms]);
      setKeyTermsInput("");
    }
  }, [keyTerms, keyTermsInput]);

  const submitKeyTerms = useCallback(() => {
    if (keyTerms.length !== 0) {
      history.push(`/case/${caseID}/keytermsearch/${keyTerms.toString()}`);
      if (setSearchKeyTerms !== undefined) {
        setSearchKeyTerms(false);
      }
    }
  }, [caseID, history, keyTerms, setSearchKeyTerms]);

  useEffect(() => {
    const listener = (event) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
        if (keyTermsInput.length > 0) addKeyTerms();
        else if (keyTerms.length !== 0) submitKeyTerms();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [addKeyTerms, keyTerms.length, keyTermsInput.length, submitKeyTerms]);

  const handleDelete = (term) => {
    setKeyTerms(keyTerms.filter((t) => t !== term));
  };

  const keyTermsChips = keyTerms.map((term) => (
    <StyledChip label={term} key={term} onDelete={() => handleDelete(term)} />
  ));

  const bodyElements = (
    <Wrapper>
      <KeyTermBackground>
        <KeyTermHeader>Selected Key Terms</KeyTermHeader>
        <KeyTermBody>{keyTermsChips}</KeyTermBody>
        <div style={{ alignSelf: "flex-end" }}>
          <StyledButton
            color={"#209BCF"}
            style={{
              background: "linear-gradient(#FFFFFF, #E9E9E9)",
            }}
            disabled={keyTerms.length === 0}
            onClick={() => setKeyTerms([])}
            size="small"
          >
            Clear All
          </StyledButton>
        </div>
      </KeyTermBackground>
      <Instructions>
        Add key terms you would like to have searched by typing in the box below
        and pressing enter or clicking the “Add” button. You may add them one at
        a time or separate your key terms by commas{" "}
        <i>(e.g. term1, term2, term3, etc...)</i>.
      </Instructions>
      <form>
        <BottomRow>
          <InputWrapper>
            <StyledTextField
              ref={inputElement}
              value={keyTermsInput}
              onChange={(e) => setKeyTermsInput(e.target.value)}
              label="Key Term(s)"
              variant="outlined"
              fullWidth
            />
          </InputWrapper>
          <div>
            <StyledButton
              color={"#209BCF"}
              style={{
                background: "linear-gradient(#FFFFFF, #E9E9E9)",
                marginLeft: "10px",
                marginRight: "10px",
              }}
              disabled={keyTermsInput.length === 0}
              onClick={addKeyTerms}
            >
              Add
            </StyledButton>

            <StyledButton
              color={"white"}
              style={{
                background: "linear-gradient(#209BCF, #26B7F5)",
                border: "none",
              }}
              disabled={keyTerms.length === 0}
              onClick={submitKeyTerms}
            >
              Search
            </StyledButton>
          </div>
        </BottomRow>
      </form>
    </Wrapper>
  );
  return (
    <TemplateModal
      open={open}
      setOpen={setOpen}
      header="Search for Evidence"
      subHeader="Add Key Terms Below"
      bodyElements={bodyElements}
      size="large"
    />
  );
};

export default KeyTermsModal;
