import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import MainJumbotron from "../components/MainJumbotron.jsx";
import SEO from "../components/SEO.jsx";
import StyledButton from "../components/StyledButton.jsx";
import Loader from "../components/Loader.jsx";
import { useHistory, Link } from "react-router-dom";
import { getProject } from "../utils/queries.js";
import { classifierRejected, classifierAccepted } from "../utils/mutations.js";
import { UserContext } from "../utils/context.js";
import { KEY_TERMS_BOLD_DELIMINATOR } from "../utils/constants.js";
import {
  getKeyTermsOnlyJSX,
  getFinalStringJSX,
} from "../utils/helper functions/keyterms_contains_helper.js";

const Wrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  min-width: 800px;
`;

const TopRowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin: 20px;
  justify-content: space-between;
  align-items: center;
`;

const TopHeading = styled.div`
  font-weight: bold;
  font-size: 1.75rem;
  margin-left: 20px;
`;

const TopButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 1px solid lightgrey;
  margin: 0px 20px 20px;
  padding-top: 20px;
  box-sizing: border-box;
  flex-grow: 1;
`;

const EvidenceWrapper = styled.div`
  display: flex
  flex-direction: column;
  width: 100%;
  border: 1px solid lightgrey;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 25px;
  padding: 10px 20px;
  box-sizing: border-box;
  margin-bottom: 20px;
`;

const EvidenceTop = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: ${(props) => props.theme.textGrey};
  font-size: 0.85rem;
`;

const EvidenceButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const EvidenceButton = styled.div`
  margin: 0px 10px;
  cursor: pointer;
  color: ${(props) => props.theme.linkBlue};
`;

const EvidenceLink = styled(Link)`
  margin: 0px 10px;
  cursor: pointer;
  color: ${(props) => props.theme.linkBlue};
  text-decoration: none;
`;

const EvidenceBody = styled.div`
  flex-grow: 1;
  margin-top: 10px;
`;

const EvidenceTags = styled.div`
  margin-bottom: 10px;
  font-weight: bold;
  margin-left: 5px;
`;

const EvidenceText = styled.div`
  margin-left: 5px;
`;

const NoneFound = styled.div`
  display: flex;
  justify-content: center;
  font-size: 2.5rem;
  color: grey;
  flex-grow: 1;
  align-items: center;
`;

const MarkedEvidenceSubHeading = styled.div`
  font-weight: 600;
`;

const EvidencePage = ({ match }) => {
  const [currentCase, setCurrentCase] = useState({
    title: "",
    dateMade: "",
    type: "",
    callCount: "",
    totalDuration: "",
    status: "",
    calls: [],
  });
  const [classifierNeedsReviewCalls, setClassifierNeedsReviewCalls] = useState(
    []
  ); //Calls where classifier found evidence and has not been reviewed
  const [callsContainingMarkedEvidence, setCallsContainingMarkedEvidence] =
    useState([]); //Calls where user marked call as evidence (manually or from classifier)
  const [retrievedCalls, setRetrievedCalls] = useState(false);
  const [reviewingMarkedEvidence, setReviewingMarkedEvidence] = useState(false);

  const history = useHistory();
  const user = useContext(UserContext);

  // Gets case from AWS
  useEffect(() => {
    const getCase = async () => {
      const caseID = match.params.id;
      const projectInfo = await getProject(caseID, false);
      if (typeof projectInfo === "string") {
        console.error(`Failed to receive project due to error: ${projectInfo}`);
        history.push("/unauthorized");
        return;
      }
      setCurrentCase(projectInfo);
    };
    if (user) {
      getCase();
    }
  }, [match.params.id, user, history]);

  //Sorts out the calls that need to be reviewed and the calls which have been marked as evidence already
  useEffect(() => {
    if (currentCase.calls.length > 0) {
      let markedEvidence = [];
      let classifierReview = [];
      for (const call of currentCase.calls) {
        // loop through every call
        if (
          call.userMarkedHasEvidence ||
          (call.classifierContainsEvidence &&
            call.classifierResultWasAccepted) ||
          call.contains.length > 0
        ) {
          //If call has been marked by user as having evidence in some way
          markedEvidence.push(call);
        }
        if (
          call.classifierContainsEvidence && // If call contains evidence from classifier but has not been reviewed.
          !call.classifierHasBeenReviewed
        ) {
          classifierReview.push(call);
        }
      }
      setClassifierNeedsReviewCalls(classifierReview);
      setCallsContainingMarkedEvidence(markedEvidence);
      if (classifierReview.length === 0) {
        setReviewingMarkedEvidence(true);
      }
      setRetrievedCalls(true);
    }
  }, [currentCase.calls]);

  //When classifier result is accepted/rejected and AWS returns that everything went right, we can make the change locally as well
  const classifierLocalChange = (callId, accepted) => {
    const index = classifierNeedsReviewCalls.findIndex(
      (call) => call.id === callId
    );
    if (index >= 0) {
      if (accepted) {
        let markedIndex = callsContainingMarkedEvidence.findIndex(
          (call) => call.id === callId
        );
        if (markedIndex !== -1) {
          //call is already in marked evidence for key term or user marked manually
          let newMarkedEvidence = [...callsContainingMarkedEvidence];
          newMarkedEvidence[markedIndex].classifierHasBeenReviewed = true;
          newMarkedEvidence[markedIndex].classifierResultWasAccepted = true;
        } else {
          // Call not in marked evidence yet
          classifierNeedsReviewCalls[index].classifierHasBeenReviewed = true;
          classifierNeedsReviewCalls[index].classifierResultWasAccepted = true;
          setCallsContainingMarkedEvidence((oldCalls) => [
            ...oldCalls,
            classifierNeedsReviewCalls[index],
          ]);
        }
      }
      //Delete call from unreviewed array
      let updatedArray = [...classifierNeedsReviewCalls];
      updatedArray.splice(index, 1);
      if (updatedArray.length === 0) {
        setReviewingMarkedEvidence(true);
      }
      setClassifierNeedsReviewCalls(updatedArray);
    } else {
      console.warn("Call already removed from classifier needs review list");
    }
  };

  /**
   * Builds the jsx needed for reviewing the classifier evidence.
   * @returns JSX to show on page
   */
  const buildClassifierReviewJSX = () => {
    if (retrievedCalls) {
      let jsx = [];
      for (const call of classifierNeedsReviewCalls) {
        jsx.push(
          <EvidenceWrapper key={call.id}>
            <EvidenceTop>
              <div style={{ flexGrow: 1 }}>
                {call.audioFileName} - {call.date} - {call.durationString}
              </div>
              <EvidenceButtonsWrapper>
                <EvidenceLink to={`/call/${call.id}`}>
                  View Transcript
                </EvidenceLink>
                <EvidenceButton
                  onClick={async () => {
                    let id = await classifierAccepted(call.id);
                    if (id === call.id) {
                      classifierLocalChange(call.id, true);
                    } else {
                      console.error(
                        "Could not update database to accept classifier"
                      );
                      console.log(id);
                    }
                  }}
                >
                  Mark Call as Evidence
                </EvidenceButton>
                <EvidenceButton
                  onClick={async () => {
                    let id = await classifierRejected(call.id);
                    if (id === call.id) {
                      classifierLocalChange(call.id, false);
                    } else {
                      console.error(
                        "Could not update database to reject classifier"
                      );
                      console.log(id);
                    }
                  }}
                >
                  Not Relevant
                </EvidenceButton>
              </EvidenceButtonsWrapper>
            </EvidenceTop>
            <EvidenceBody>
              <EvidenceTags>{call.classifierTags.join(", ")}</EvidenceTags>
              {call.classifierSummary}
            </EvidenceBody>
          </EvidenceWrapper>
        );
      }
      if (jsx.length === 0) {
        jsx.push(
          <NoneFound key="No New or Unreviewed Evidence Found">
            No New or Unreviewed Evidence Found
          </NoneFound>
        );
      }
      return jsx;
    } else {
      return <Loader />;
    }
  };

  /**
   * Builds the jsx needed for all the items marked as evidence.
   * @returns JSX to show on page
   */
  const buildMarkedEvidenceJSX = () => {
    if (retrievedCalls) {
      let jsx = [];
      for (const call of callsContainingMarkedEvidence) {
        jsx.push(
          <EvidenceWrapper key={call.id}>
            <EvidenceTop>
              <div style={{ flexGrow: 1 }}>
                {call.audioFileName} - {call.date} - {call.durationString}
              </div>
              <EvidenceButtonsWrapper>
                <EvidenceLink to={`/call/${call.id}`}>
                  View Transcript
                </EvidenceLink>
              </EvidenceButtonsWrapper>
            </EvidenceTop>

            {call.classifierContainsEvidence &&
              call.classifierResultWasAccepted && (
                <EvidenceBody>
                  <MarkedEvidenceSubHeading>
                    Classifier:
                  </MarkedEvidenceSubHeading>
                  <EvidenceTags>{call.classifierTags.join(", ")}</EvidenceTags>
                  <EvidenceText>{call.classifierSummary}</EvidenceText>
                </EvidenceBody>
              )}
            {call.contains.length > 0 && (
              <EvidenceBody>
                <MarkedEvidenceSubHeading>Key Terms:</MarkedEvidenceSubHeading>
                <EvidenceTags>
                  {getKeyTermsOnlyJSX(
                    call.contains,
                    KEY_TERMS_BOLD_DELIMINATOR
                  )}
                </EvidenceTags>
                <EvidenceText>{getFinalStringJSX(call.contains)}</EvidenceText>
              </EvidenceBody>
            )}
            {call.userMarkedHasEvidence && (
              <EvidenceBody>
                <MarkedEvidenceSubHeading>
                  Manually Marked as Evidence
                </MarkedEvidenceSubHeading>
              </EvidenceBody>
            )}
          </EvidenceWrapper>
        );
      }
      if (jsx.length === 0) {
        jsx.push(
          <NoneFound key="No Marked Evidence Yet">
            No Marked Evidence Yet
          </NoneFound>
        );
      }
      return jsx;
    } else {
      return <Loader />;
    }
  };

  return (
    <Wrapper>
      {" "}
      <SEO
        title="Evidence | WireTap"
        description="Search through evidence found by WireTap or your previous keyword finds."
      />
      <MainJumbotron
        title={`${currentCase.title} Evidence`}
        metrics={[
          { header: "Date Made", body: currentCase.dateMade },
          { header: "Type", body: currentCase.type },
          { header: "Call Count", body: currentCase.callCount },
          { header: "Total Call Time", body: currentCase.totalDuration },
          { header: "Status", body: currentCase.status },
        ]}
        breadcrumb={[
          { name: "My Cases", link: `/home` },
          { name: currentCase.title, link: `/case/${match.params.id}` },
          { name: "Evidence" },
        ]}
      />
      <TopRowWrapper>
        <TopHeading>
          {reviewingMarkedEvidence ? "Marked Evidence" : "Unreviewed Evidence"}
        </TopHeading>
        <TopButtonsWrapper>
          {!reviewingMarkedEvidence && (
            <StyledButton
              color={"white"}
              style={{
                background: "linear-gradient(#209BCF, #26B7F5)",
                border: "none",
                marginRight: "10px",
              }}
              onClick={() => {
                setReviewingMarkedEvidence(true);
              }}
              disabled={!Boolean(currentCase.title)}
            >
              View Marked Evidence
            </StyledButton>
          )}
          {reviewingMarkedEvidence && classifierNeedsReviewCalls.length > 0 && (
            <StyledButton
              color={"white"}
              style={{
                background: "linear-gradient(#209BCF, #26B7F5)",
                border: "none",
                marginRight: "10px",
              }}
              onClick={() => {
                setReviewingMarkedEvidence(false);
              }}
              disabled={!Boolean(currentCase.title)}
            >
              View Unreviewed Evidence
            </StyledButton>
          )}
          <StyledButton
            color={"#209BCF"}
            style={{
              background: "linear-gradient(#FFFFFF, #E9E9E9)",
            }}
            onClick={() => history.push(`/case/${match.params.id}`)}
            disabled={!Boolean(currentCase.title)}
          >
            Return to Case
          </StyledButton>
        </TopButtonsWrapper>
      </TopRowWrapper>
      <BodyWrapper>
        {!reviewingMarkedEvidence && buildClassifierReviewJSX()}
        {reviewingMarkedEvidence && buildMarkedEvidenceJSX()}
      </BodyWrapper>
    </Wrapper>
  );
};

export default EvidencePage;
