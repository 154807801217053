const theme = {
  // Heading
  heading: "#484856",
  headingLight: "rgb(109, 114, 124)",
  infoText: "#444444",
  link: "#2193b0",

  jumbotronBackground: "#f5f9fe",
  footerBackground: "#f2f2f2",

  //Buttons
  button: "rgb(0, 122, 255)",
  buttonBorder: "#d6d6d6",

  //Colors
  primaryLight: "#2b6684",
  primaryDark: "#012e67",
  primaryDeep: "#032e42",
  primaryGrey: "#9cacbf",
  primaryWhite: "#ffffff",

  secondaryDark: "#22313F",
  secondaryLight: "#2B75AC",
  backgroundGrey: "#F5F6F7",
  black: "#000000",
  lightgrey: "#9C9A9A",
  keyTermsBlue: "#DCE6F1",
  textGrey: "#7A7A7A",
  linkBlue: "#2691be",
  linkLightBlue: "rgb(29, 155, 240)",

  //Media Queries

  /* Extra small devices (phones, 600px and down) */
  extraSmall: "@media only screen and (max-width: 600px)",
  /* Small devices (portrait tablets and large phones, 600px and up) */
  small: "@media only screen and (min-width: 600px)",
  /* Medium devices (landscape tablets, 768px and up) */
  medium: "@media only screen and (min-width: 768px)",
  /* Large devices (laptops/desktops, 992px and up) */
  large: "@media only screen and (min-width: 992px)",
  /* Extra large devices (large laptops and desktops, 1200px and up) */
  extraLarge: "@media only screen and (min-width: 1200px)",

  //Sizes
  contentHeight: "calc(100vh - 50px)",
};

export default theme;
