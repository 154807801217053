import React from "react";
import styled from "styled-components";
import Backdrop from "@mui/material/Backdrop";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import IconButton from "@mui/material/IconButton";
import StyledButton from "./StyledButton";

const StyledBackdrop = styled(Backdrop)`
  z-index: 6;
  display: flex;
  justify-content: centerl
  align-items: center;
`;

const ModalWrapper = styled.div`
  width: 75vw;
  height: ${(props) => props.height}vh;
  min-height: ${(props) => props.minHeight}px;
  overflow: scroll;
  background-color: white;
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  padding: 25px;
  box-sizing: border-box;
`;

const TopRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const HeadingWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const HeaderText = styled.div`
  color: ${(props) => props.theme.black};
  font-size: 24pt;
  font-weight: bold;
  display: flex;
  align-items: center;
`;

const HeaderSubText = styled.div`
  font-size: 22pt;
  font-weight: 500;
  border-left: 3px solid ${(props) => props.theme.lightgrey};
  color: ${(props) => props.theme.lightgrey};
  display: flex;
  align-items: center;
  margin-left: 10px;
  padding-left 10px;
`;

const Body = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

const TemplateModal = ({
  open,
  setOpen,
  header,
  subHeader,
  bodyElements,
  size = "medium",
  closeButton = undefined,
  closeCallback = undefined,
}) => {
  let height = 50;
  let minHeight = 175;
  if (size === "small") {
    height = 35;
  } else if (size === "medium") {
    height = 50;
    minHeight = 250;
  } else if (size === "large") {
    height = 80;
    minHeight = 400;
  }

  const handleXClose = () => {
    setOpen(false);
  };

  const handleButtonClicked = () => {
    setOpen(false);
    if (closeCallback) {
      closeCallback();
    }
  };

  return (
    <StyledBackdrop open={open}>
      <ModalWrapper height={height} minHeight={minHeight}>
        <TopRow>
          <HeadingWrapper>
            <HeaderText>{header}</HeaderText>
            <HeaderSubText>{subHeader}</HeaderSubText>
          </HeadingWrapper>
          <IconButton onClick={handleXClose}>
            <HighlightOffIcon />
          </IconButton>
        </TopRow>
        <Body>{bodyElements}</Body>
        {closeButton && (
          <StyledButton
            color={"white"}
            style={{
              background: "linear-gradient(#209BCF, #26B7F5)",
              border: "none",
              width: "100px",
              alignSelf: "flex-end",
            }}
            onClick={handleButtonClicked}
          >
            {closeButton}
          </StyledButton>
        )}
      </ModalWrapper>
    </StyledBackdrop>
  );
};

export default TemplateModal;
