import styled from "styled-components";

const StyledButton = styled.button`
  background: linear-gradient(
    ${(props) => props.theme.primaryLight},
    ${(props) => props.theme.primaryLight}
  );
  border: 0.1rem solid ${(props) => props.theme.buttonBorder};
  border-radius: 0.4rem;
  min-width: ${(props) => (props.size === "small" ? "1rem" : "3rem")};
  margin-top: 0;
  margin-bottom: 0;
  padding: ${(props) =>
    props.size === "small" ? "0.3rem .6rem" : "0.6rem 1.2rem"};
  font-size: ${(props) => (props.size === "small" ? ".8rem" : "1.1rem")};
  font-weight: 400;
  color: ${(props) => props.color};
  text-align: center;
  white-space: nowrap;
  cursor: pointer;

  &[disabled] {
    opacity: 0.5;
    cursor: default;
  }
`;

export default StyledButton;
