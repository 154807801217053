import React, { useContext } from "react";
import styled from "styled-components";
import SEO from "../components/SEO.jsx";
import { UserContext } from "../utils/context.js";

const Wrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Title = styled.h2``;
const Body = styled.div``;

const SuccessPaymentPage = ({ match }) => {
  /**
   * Gets username for event if we have it
   */
  let user = useContext(UserContext);
  if (user) {
    user = user.username;
  } else {
    user = "Unknown";
  }

  setTimeout(() => {
    window.close();
  }, 200);

  return (
    <Wrapper>
      <SEO
        title="Successful Payment | WireTap"
        description="Thank you for using WireTap!"
      />
      <Title>Closing Tab...</Title>
      <Body>
        If the tab does not close on its own, please close the tab and return to
        page.
      </Body>
    </Wrapper>
  );
};

export default SuccessPaymentPage;
