import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import TextField from "@mui/material/TextField";
import { Analytics } from "aws-amplify";
import { Auth } from "aws-amplify";

const Heading1 = styled.h1`
  font-weight: bold;
  margin: 0;
`;

const StyledSpan = styled.span`
  font-size: 0.75rem;
  margin: 10px 0px 15px;
  color: grey;
`;

const StyledButton = styled.button`
  border-radius: 20px;
  border: 1px solid #ffffff;
  background: ${(props) =>
    props.ghost
      ? `linear-gradient(#FFFFFF, #E9E9E9)`
      : `linear-gradient(#209BCF, #26B7F5)`};
  color: ${(props) => (props.ghost ? "#209BCF" : "#ffffff")};
  font-size: 12px;
  font-weight: bold;
  padding: 12px 45px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: transform 80ms ease-in;
  cursor: pointer;

  &:active {
    transform: scale(0.95);
  }

  &:focus {
    outline: none;
  }
`;

const StyledForm = styled.form`
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 50px;
  height: 100%;
  text-align: center;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const NameWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const StyledTextField = styled(TextField)`
  &.MuiFormControl-fullWidth {
    margin-top: 10px;
  }
  .MuiFormLabel-root.Mui-focused {
    color: ${(props) => props.theme.primaryLight};
  }
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${(props) => props.theme.primaryLight};
  }
  .MuiInput-underline:after {
    border-bottom: 2px solid ${(props) => props.theme.primaryLight};
  }
`;

const AgreementLink = styled(Link)`
  color: ${(props) => props.theme.linkLightBlue};

  :hover {
    text-decoration: underline;
  }
`;

const AgreementText = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  color: #777;
  font-size: 11px;
`;

function SignUpForm({
  setLoading,
  setError,
  setConfirm,
  setConfirmEmail,
  setConfirmPassword,
}) {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [department, setDepartment] = useState("");

  const sendAWSAnalyticsEvent = () => {
    try {
      let attr = { email: email };
      Analytics.record({
        name: "Sign Up",
        // Attribute values must be strings
        attributes: attr,
      });
    } catch {
      console.warn("Could not send analytics to AWS");
    }
  };

  /**
   * Handles the initial submit trying to sign-up
   * @param {obj} event
   * @returns None
   */
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (password.length < 8) {
      setError("Password must contain at least 8 characters.");
      return;
    }
    try {
      setLoading(true);
      sendAWSAnalyticsEvent();
      await Auth.signUp({
        username: email,
        password: password,
        attributes: {
          email: email,
          given_name: firstName,
          family_name: lastName,
          "custom:department": department,
        },
      });
      setConfirmEmail(email);
      setConfirmPassword(password);
      setConfirm(true);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(error.message);
      console.error("error signing up", error);
    }
  };
  return (
    <div className="form-container sign-up-container">
      <StyledForm onSubmit={handleSubmit}>
        <Heading1>Create Account</Heading1>
        <StyledSpan>And get started with WireTap today!</StyledSpan>
        <InputWrapper>
          <NameWrapper>
            <StyledTextField
              required
              style={{ width: "48%" }}
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              label="First Name"
            />
            <StyledTextField
              required
              style={{ width: "48%" }}
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              label="Last Name"
            />
          </NameWrapper>
          <StyledTextField
            value={department}
            onChange={(e) => setDepartment(e.target.value)}
            label="Agency/Department"
            fullWidth
          />
          <StyledTextField
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            label="Email"
            type="email"
            fullWidth
          />
          <StyledTextField
            required
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            label="Password"
            type="password"
            autoComplete="new-password"
            fullWidth
          />
          <AgreementText>
            By signing up, you agree to the{" "}
            <AgreementLink
              to="/helpcenter/terms"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms of Service
            </AgreementLink>{" "}
            and{" "}
            <AgreementLink
              to="/helpcenter/privacy"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </AgreementLink>
            .
          </AgreementText>
        </InputWrapper>
        <StyledButton type="submit">Sign Up</StyledButton>
      </StyledForm>
    </div>
  );
}

export default SignUpForm;
