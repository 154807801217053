import React from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const WarningErrorSnackbars = ({
  errorMessage = false,
  setErrorMessage = () => {},
  warningMessage = false,
  setWarningMessage = () => {},
}) => {
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  /**
   * Closes snackback when clicks close button
   * @param {event} event Event
   * @param {string} reason Why is the snackbar closing
   */
  const handleCloseError = (event, reason) => {
    if (reason !== "clickaway") {
      setErrorMessage(false);
    }
  };

  /**
   * Closes snackback when clicks close button
   * @param {event} event Event
   * @param {string} reason Why is the snackbar closing
   */
  const handleCloseWarning = (event, reason) => {
    if (reason !== "clickaway") {
      setWarningMessage(false);
    }
  };

  return (
    <React.Fragment>
      <Snackbar
        open={Boolean(errorMessage)}
        autoHideDuration={10000}
        onClose={handleCloseError}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          severity="error"
          action={
            <React.Fragment>
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleCloseError}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </React.Fragment>
          }
        >
          {errorMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        open={Boolean(warningMessage)}
        autoHideDuration={10000}
        onClose={handleCloseWarning}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          severity="warning"
          action={
            <React.Fragment>
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleCloseWarning}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </React.Fragment>
          }
        >
          {warningMessage}
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
};

export default WarningErrorSnackbars;
