export function unixToLocal(timestamp, dateOnly = false) {
  var d = new Date(timestamp * 1000), // Convert the passed timestamp to milliseconds
    yyyy = d.getFullYear(),
    mm = ("0" + (d.getMonth() + 1)).slice(-2), // Months are zero based. Add leading 0.
    dd = ("0" + d.getDate()).slice(-2), // Add leading 0.
    hh = d.getHours(),
    h = hh,
    min = ("0" + d.getMinutes()).slice(-2), // Add leading 0.
    ampm = "AM",
    time;

  if (hh > 12) {
    h = hh - 12;
    ampm = "PM";
  } else if (hh === 12) {
    h = 12;
    ampm = "PM";
  } else if (hh === 0) {
    h = 12;
  }

  if (dateOnly) {
    // ie: 2013-02-18
    time = mm + "-" + dd + "-" + yyyy;
  } else {
    // ie: 2013-02-18, 8:35 AM
    time = mm + "-" + dd + "-" + yyyy + ", " + h + ":" + min + " " + ampm;
  }

  return time;
}

export function unixToLocalTime(timestamp, useMilitary) {
  var d = new Date(timestamp * 1000), // Convert the passed timestamp to milliseconds
    hh = d.getHours(),
    h = hh,
    min = ("0" + d.getMinutes()).slice(-2), // Add leading 0.
    ampm = "AM",
    time;

  if (hh > 12) {
    h = hh - 12;
    ampm = "PM";
  } else if (hh === 12) {
    h = 12;
    ampm = "PM";
  } else if (hh === 0) {
    h = 12;
  }

  if (useMilitary) {
    if (hh < 12) hh = "0" + hh;
    time = `${hh}${min}`;
  } else {
    time = h + ":" + min + " " + ampm;
  }

  return time;
}

/**
 * Converts seconds to hour:minute:seconds format. Rounded down to the nearest second.
 * @param {float} seconds The number of seconds to convert
 */
export function secsToMinsHours(timeInSeconds) {
  const hours = Math.floor(timeInSeconds / 3600);
  let minutes = Math.floor((timeInSeconds % 3600) / 60);
  let seconds = Math.floor(timeInSeconds % 60);

  // treat any fractional part of a minute as a whole minute
  if (seconds === 60) {
    seconds = 0;
    minutes += 1;
  }

  if (hours > 0) {
    return `${hours}:${padNumber(minutes)}:${padNumber(seconds)}`;
  } else if (minutes > 0) {
    return `${minutes}:${padNumber(seconds)}`;
  } else {
    return `0:${padNumber(seconds)}`;
  }
}

function padNumber(number) {
  return number.toString().padStart(2, "0");
}
