import React, { useRef, useState } from "react";
import { FileDrop } from "react-file-drop";
import styled from "styled-components";
import WarningErrorSnackbars from "./WarningErrorSnackbar.jsx";

const StyledFileDrop = styled(FileDrop)`
  width: auto;
  margin-top: 20px;
  margin-right: 30px;
  height: 250px;
  background-color: ${(props) => props.theme.jumbotronBackground};

  .file-drop-target {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const InsideBorder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 2px dashed rgb(153, 175, 178);
  transition: 0.25s;
  width: calc(100% - ${(props) => props.margin}px);
  height: calc(100% - ${(props) => props.margin}px);

  :hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

const StyledChooseFile = styled.span`
  font-weight: bold;
`;

const DragAndDrop = (props) => {
  const { currentFiles, setFiles } = props;
  const inputRef = useRef(null);
  const [borderMargin, setBorderMargin] = useState("15");
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const chooseFile = () => {
    inputRef.current.click();
  };

  /**
   * Checks amount of files and file type then sends back to parent.
   * @param {Object} files Object with every file dropped.
   */
  const onDrop = (files, e) => {
    e.preventDefault();
    setBorderMargin("15");

    //Only Accepted files
    const currentFileNames = currentFiles.map((file) => file.name);
    let acceptedFiles = [...currentFiles];
    let displayWarning = false;
    for (var i = 0; i < files.length; i++) {
      const fileType = files[i].type;
      if (!fileType.startsWith("audio/") && !fileType.startsWith("video/")) {
        displayWarning = `Some or all calls added are in the wrong format. Currently only support audio and video files.`;
        continue;
      } else if (currentFileNames.includes(files[i].name)) {
        displayWarning = "Call(s) added with duplicate names.";
        continue;
      }

      //New file in right format
      acceptedFiles.push(files[i]);
    }
    setSnackbarOpen(displayWarning);
    setFiles(acceptedFiles);
  };

  return (
    <StyledFileDrop
      onDrop={onDrop}
      onDragOver={() => setBorderMargin("30")}
      onDragLeave={() => setBorderMargin("15")}
    >
      <InsideBorder margin={borderMargin} onClick={chooseFile}>
        <svg className="box__icon" width="80" height="69" viewBox="0 0 50 43">
          <path
            stroke="none"
            fill="rgb(153, 175, 178)"
            d="M48.4 26.5c-.9 0-1.7.7-1.7 1.7v11.6h-43.3v-11.6c0-.9-.7-1.7-1.7-1.7s-1.7.7-1.7 1.7v13.2c0 .9.7 1.7 1.7 1.7h46.7c.9 0 1.7-.7 1.7-1.7v-13.2c0-1-.7-1.7-1.7-1.7zm-24.5 6.1c.3.3.8.5 1.2.5.4 0 .9-.2 1.2-.5l10-11.6c.7-.7.7-1.7 0-2.4s-1.7-.7-2.4 0l-7.1 8.3v-25.3c0-.9-.7-1.7-1.7-1.7s-1.7.7-1.7 1.7v25.3l-7.1-8.3c-.7-.7-1.7-.7-2.4 0s-.7 1.7 0 2.4l10 11.6z"
          ></path>
        </svg>
        <div style={{ marginTop: "30px", color: "#415b66" }}>
          <StyledChooseFile>Choose audio files</StyledChooseFile>{" "}
          <span>or drag them here</span>
          <input
            ref={inputRef}
            accept={"audio/*,video/*"}
            type="file"
            style={{ display: "none" }}
            onChange={(e) => {
              onDrop(e.target.files, e);
              e.target.value = "";
            }}
            multiple
          />
        </div>
      </InsideBorder>
      <WarningErrorSnackbars
        warningMessage={snackbarOpen}
        setWarningMessage={setSnackbarOpen}
      />
    </StyledFileDrop>
  );
};

export default DragAndDrop;
