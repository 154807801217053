import React, { useEffect, useState, useContext } from "react";
import styled, { keyframes } from "styled-components";
import { Link, NavLink, useHistory } from "react-router-dom";
import { fadeInLeft } from "react-animations";
import logoSrc from "../utils/images/WT Logo.png";
import PersonIcon from "@mui/icons-material/Person";
import { Auth } from "aws-amplify";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { UserContext } from "../utils/context.js";
import { PAGES_TO_REDIRECT_HOME } from "../utils/constants.js";

const Wrapper = styled.div`
  background-color: white;
  height: 50px;
  width: 100vw;

  top: 0;
  border-bottom: 1px solid lightgrey;
  z-index: 2;
`;

const InternalWrapper = styled.div`
  max-width: 96%;
  margin: auto;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (min-width: 576px) {
    max-width: 94%;
  }

  @media (min-width: 840px) {
    max-width: 92%;
  }
  @media (min-width: 992px) {
    max-width: 90%;
  }

  @media (min-width: 1224) {
    max-width: 1200px;
  }
`;

const Title = styled(Link)`
  font-size: 1.15rem;
  color: ${(props) => props.theme.heading};
  text-decoration: none;
  font-weight: 500;
  display: flex;
  flex-direction: row;
  align-items: center;

  &:hover {
    color: ${(props) => props.theme.headingLight};
  }
`;

const NavLinksWrapper = styled.div`
  font-weight: normal;
  font-size: 0.95rem;
  display: flex;
  align-items: center;

  @media (max-width: 840px) {
    display: none;
  }
`;

const StyledNavLink = styled(NavLink)`
  color: ${(props) => props.theme.headingLight};
  text-decoration: none;
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;

  &.active {
    color: ${(props) => props.theme.link};
  }

  &:hover {
    text-decoration: underline;
  }

  @media (max-width: 840px) {
    border-bottom: 1px solid lightgrey;
    padding-bottom: 20px;
    padding-top: 20px;
  }
`;

const Name = styled.div`
  color: ${(props) => props.theme.headingLight};
  text-decoration: none;
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;

  &.active {
    color: ${(props) => props.theme.link};
  }

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  @media (max-width: 840px) {
    border-bottom: 1px solid lightgrey;
    padding-bottom: 20px;
    padding-top: 20px;
  }
`;

const NavIcon = styled.div`
  width: 1rem;
  display: none;
  margin-right: 20px;

  @media (max-width: 840px) {
    display: initial;
  }

  &:hover {
    cursor: pointer;
  }

  &:before,
  :after {
    border-bottom: 1px solid ${(props) => props.theme.heading};
    content: "";
    display: block;
    height: 5px;
    margin: 1px 0;
    transition: all 0.2s ease-in-out;
  }

  &:before {
    transform-origin: bottom center;
    transform: ${(props) =>
      props.expanded ? "translateY(4px) rotate(135deg)" : ""};
  }

  &:after {
    transform-origin: bottom center;
    transform: ${(props) =>
      props.expanded ? "translateY(-3px) rotate(-135deg)" : ""};
  }
`;

const ResponsiveNavWrapper = styled.div`
  display: block;
  position: absolute:
  margin-top: 50px;
  width:100vw;
  background-color: white;
  z-index: 5;
  transition: all 0.2s ease-in-out;
  height: ${(props) => (props.expanded ? "70vh" : "0px")};
  min-height: ${(props) => (props.expanded ? "250px" : "0px")};
`;

const fadeInLeftAnimation = keyframes`${fadeInLeft}`;

const NavOptionsWrapper = styled.div`
  display: ${(props) => (props.expanded ? "flex" : "none")};
  flex-direction: column;
  animation: 0.5s ${fadeInLeftAnimation};
  animation-delay: 0.1s;
  animation-fill-mode: both;
  margin-left: 20px;
`;

const Logo = styled.img`
  width: 40px;
  height: auto;
  margin-right: 5px;
`;

const HeaderRightDiv = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ResponsiveMenuItem = styled.div`
  color: ${(props) => props.theme.headingLight};
  text-decoration: none;
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;

  &.active {
    color: ${(props) => props.theme.link};
  }

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  @media (max-width: 840px) {
    border-bottom: 1px solid lightgrey;
    padding-bottom: 20px;
    padding-top: 20px;
  }
`;

const Header = ({ setUser }) => {
  const [expanded, setExpanded] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const history = useHistory();

  const onClickNav = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    const CheckExpanded = () => {
      if (window.innerWidth > 840) {
        setExpanded(false);
      }
    };
    window.addEventListener("resize", CheckExpanded);
    CheckExpanded();
    return () => window.removeEventListener("resize", CheckExpanded);
  }, []);

  /**
   * Gets the user info and sends to the right page if logged out
   */
  useEffect(() => {
    const getUserInfo = async () => {
      try {
        const updatedUser = await Auth.currentAuthenticatedUser();
        setUser(updatedUser);
        if (history.location.pathname === "/") {
          history.push("/home");
        }
      } catch {
        if (
          PAGES_TO_REDIRECT_HOME.find((element) =>
            history.location.pathname.startsWith(element)
          )
        ) {
          history.push("/");
        }
      }
    };
    getUserInfo();
  }, [history, setUser]);

  const handleClickUsername = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  /**
   * Logs the user out.
   */
  const logOut = async () => {
    try {
      await Auth.signOut();
      setAnchorEl(null);
      setUser(undefined);
      history.push("/");
    } catch (error) {
      console.log("error signing out: ", error);
    }
  };

  const user = useContext(UserContext);

  /**
   * Returns the JSX when a user is logged in and the header is NOT in responsive mode
   * @returns JSX
   */
  const userLoggedIn = () => {
    return (
      <HeaderRightDiv>
        <NavLinksWrapper>
          <StyledNavLink to="/home">Cases</StyledNavLink>
          <StyledNavLink to="/helpcenter/userguide">User Guide</StyledNavLink>
          <StyledNavLink to="/bulkpurchaseselect">Purchase Hours</StyledNavLink>
        </NavLinksWrapper>
        <NavLinksWrapper onClick={handleClickUsername}>
          <Name>
            {`${user.attributes.given_name} ${user.attributes.family_name[0]}.`}{" "}
            <PersonIcon style={{ marginLeft: "5px" }} />
          </Name>
        </NavLinksWrapper>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={logOut} style={{ padding: "0px 16px" }}>
            Logout
          </MenuItem>
        </Menu>
      </HeaderRightDiv>
    );
  };

  /**
   * Returns the JSX when a user is NOT logged in and the header is NOT in responsive mode
   * @returns JSX
   */
  const userNotLoggedIn = () => {
    return (
      <NavLinksWrapper>
        <StyledNavLink exact to="/login">
          {!user ? "Sign On" : user.username}{" "}
          <PersonIcon style={{ marginLeft: "5px" }} />
        </StyledNavLink>
      </NavLinksWrapper>
    );
  };

  /**
   * Returns the JSX when a user is logged in and the header is in responsive mode
   * @returns JSX
   */
  const userLoggedInResponsive = () => {
    return <ResponsiveMenuItem onClick={logOut}>Logout</ResponsiveMenuItem>;
  };

  /**
   * Returns the JSX when a user is NOT logged in and the header is NOT in responsive mode
   * @returns JSX
   */
  const userNotLoggedInResponsive = () => {
    return (
      <StyledNavLink exact to="/login" onClick={onClickNav}>
        Sign On <PersonIcon style={{ marginLeft: "5px" }} />
      </StyledNavLink>
    );
  };

  return (
    <Wrapper>
      <InternalWrapper>
        <Title to={user ? "/home" : "/"}>
          <Logo src={logoSrc} /> WireTap
        </Title>
        {user ? userLoggedIn() : userNotLoggedIn()}
        <NavIcon onClick={onClickNav} expanded={expanded} />
      </InternalWrapper>
      <ResponsiveNavWrapper expanded={expanded}>
        <NavOptionsWrapper expanded={expanded}>
          {user ? userLoggedInResponsive() : userNotLoggedInResponsive()}
        </NavOptionsWrapper>
      </ResponsiveNavWrapper>
    </Wrapper>
  );
};

export default Header;
