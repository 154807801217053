import React from "react";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.text.primary,
    maxWidth: 250,
    fontSize: theme.typography.pxToRem(13),
    border: `1px solid ${theme.palette.divider}`,
    boxShadow: theme.shadows[4],
    borderRadius: 8,
    padding: theme.spacing(1.5),
    lineHeight: 1.5,
    fontFamily: theme.typography.fontFamily,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.white,
  },
}));

const TooltipContent = ({ title }) => (
  <Typography variant="body2" color="inherit">
    {title}
  </Typography>
);

const CustomTooltip = ({ title, placement, children }) => (
  <StyledTooltip
    title={<TooltipContent title={title} />}
    arrow
    placement={placement}
  >
    {children}
  </StyledTooltip>
);

export default CustomTooltip;
