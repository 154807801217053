import { KEY_TERMS_BOLD_DELIMINATOR } from "../constants.js";

/**
 * Given an array of strings with a deliminator, it will bold all chars between the deliminators.
 * @param {[objects]} contains array of strings with deliminator where bold should be
 * @param {string} deliminator what chars deliminate where bold should be
 * @returns array of jsx with spans
 */
export const getFinalStringJSX = (
  contains,
  deliminator = KEY_TERMS_BOLD_DELIMINATOR
) => {
  let finalString = [];
  for (const i in contains) {
    let splitString = contains[i].split(deliminator);

    for (const index in splitString) {
      if (index % 2 === 0) {
        finalString.push(
          <span key={`sentence=${i} index=${index}`}>{splitString[index]}</span>
        );
      } else {
        finalString.push(
          <span key={`sentence=${i} index=${index}`}>
            <b>{splitString[index]}</b>
          </span>
        );
      }
    }
    finalString.push(<span key={`sentence=${i} ...`}>...</span>);
  }
  return finalString;
};

export const getKeyTermsOnlyJSX = (contains, deliminator) => {
  let keyTerms = new Set([]);
  for (const i in contains) {
    let splitString = contains[i].split(deliminator);

    for (const index in splitString) {
      if (index % 2 === 1) {
        keyTerms.add(splitString[index]);
      }
    }
  }
  return [...keyTerms].join(", ");
};

/**
 * Merged 2 strings key terms by searching for the deliminator and merging them
 * @param {String} a one string that is a duplicate of b except deliminator in different spot
 * @param {String} b one string that is a duplicate of a except deliminator in different spot
 * @param {String} deliminator What deliminates the key term
 * @returns final string with the key terms merged
 */
const mergeStrings = (a, b, deliminator) => {
  let aPointer = 0;
  let bPointer = 0;
  let finalString = "";
  while (a[aPointer] || b[bPointer]) {
    if (a[aPointer] === b[bPointer]) {
      finalString += a[aPointer];
      aPointer++;
      bPointer++;
    } else {
      if (a.startsWith(deliminator, aPointer)) {
        finalString += deliminator;
        aPointer += deliminator.length;
      } else if (b.startsWith(deliminator, bPointer)) {
        finalString += deliminator;
        bPointer += deliminator.length;
      } else {
        console.error("Found diff in strings but no deliminator.");
      }
    }
  }
  return finalString;
};

export const mergeEvidence = (
  oldEvidence,
  newEvidence,
  transcription,
  deliminator = KEY_TERMS_BOLD_DELIMINATOR
) => {
  let merged = [];
  let duplicateIndex = [];

  for (let indexA in oldEvidence) {
    let isDuplicate = false;
    for (let indexB in newEvidence) {
      if (
        oldEvidence[indexA].replaceAll(KEY_TERMS_BOLD_DELIMINATOR, "") ===
        newEvidence[indexB].replaceAll(KEY_TERMS_BOLD_DELIMINATOR, "")
      ) {
        // Evidence is the same
        duplicateIndex.push(parseInt(indexB));
        let newString = mergeStrings(
          oldEvidence[indexA],
          newEvidence[indexB],
          deliminator
        );
        isDuplicate = true;
        merged.push(newString);
        break;
      }
    }
    if (!isDuplicate) merged.push(oldEvidence[indexA]);
  }
  // Get all newEvidence not in old evidence
  for (let i in newEvidence) {
    if (duplicateIndex.includes(parseInt(i))) {
      continue;
    }
    merged.push(newEvidence[i]);
  }

  // Sort in the order they were said
  let mergedWithIndex = [];
  const transcript = JSON.parse(transcription);
  const transcriptList = transcript.map((item) => item.text);
  for (let res of merged) {
    const index = transcriptList.indexOf(
      res.replaceAll(KEY_TERMS_BOLD_DELIMINATOR, "")
    );
    mergedWithIndex.push({ text: res, index: index });
  }
  mergedWithIndex.sort((a, b) => a.index - b.index);
  merged = [];
  for (const item of mergedWithIndex) {
    merged.push(item.text);
  }
  return merged;
};
