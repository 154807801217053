import React, { useState, useEffect } from "react";
import Markdown from "markdown-to-jsx";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import LILogo from "../utils/images/linkedinLogo.png";
import YTLogo from "../utils/images/youtubeLogo.png";
import { Analytics } from "aws-amplify";
import SEO from "../components/SEO.jsx";

const BlogContainer = styled.div`
  max-width: 800px;
  margin: auto;
  padding: 20px;
`;

const ProgressBar = styled.div`
  width: ${(props) => props.scroll}%;
  height: 5px;
  background-color: ${(props) => props.theme.linkBlue};
  position: fixed;
  top: 0;
  left: 0;
`;

const Title = styled.h1`
  text-align: center;
`;

const ReadTime = styled.div`
  text-align: center;
  font-size: 14px;
`;

const Author = styled.h3`
  text-align: center;
  font-size: smaller;
`;

const PostContent = styled.div`
  font-size: 18px;
  line-height: 1.6;
  text-align: left;

  img {
    max-width: 100%;
    height: auto;
  }
`;

const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;
`;

const Logo = styled.img`
  width: 24px;
  height: 24px;
  object-fit: contain;
`;

const StyledLink = styled.a`
  cursor: pointer;
  text-decoration: underline;
  color: ${(props) => props.theme.linkLightBlue};
`;

const Disclaimer = styled.div`
  font-size: 11px;
  color: #666;
  border-top: 2px solid #eee;
  padding-top: 20px;
  margin-top: 40px;

  a {
    color: ${(props) => props.theme.linkLightBlue};
    text-decoration: none;
  }
`;

const TopDisclaimer = styled.div`
  font-size: 14px;
  color: #333; /* Dark gray text */
  border: 1px solid #ccc; /* Light gray border */
  padding: 10px;
  margin: 20px 0;
  background-color: #f9f9f9; /* Light gray background */
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px; /* Adjust based on your preference */
  font-size: 18px;
  color: #666;
`;

const Callout = styled.blockquote`
  border-left: 5px solid ${(props) => props.theme.linkBlue};
  background: #f9f9f9;
  padding: 15px;
  margin: 20px 0;
  font-size: 18px;
  line-height: 1.6;
  color: #333;
  font-style: italic;

  & > p {
    margin: 0;
  }
`;

const VideoContainer = styled.div`
  margin: 20px 0;
  iframe {
    width: 100%;
    height: 400px;
    border: none;
  }
`;

function extractYouTubeVideoID(url) {
  const regex = /[?&]v=([^&#]*)/;
  const match = url.match(regex);
  return match ? match[1] : null;
}

// Custom component for rendering links
const CustomLink = ({ children, href }) => {
  if (href.includes("linkedin.com")) {
    return (
      <LogoContainer>
        <StyledLink href={href} target="_blank" rel="noreferrer">
          <Logo src={LILogo} alt="LinkedIn" />
        </StyledLink>
        <StyledLink
          href={
            "https://www.youtube.com/playlist?list=PLl-F3CEVYjbAd_VOf45wgHJejohh9on1Q"
          }
          target="_blank"
          rel="noreferrer"
        >
          <Logo src={YTLogo} alt="YouTube" />
        </StyledLink>
      </LogoContainer>
    );
  } else if (href.includes("youtube.com") || href.includes("youtu.be")) {
    // Extract video ID from the YouTube URL
    const videoId = extractYouTubeVideoID(href);
    const embedUrl = `https://www.youtube.com/embed/${videoId}`;
    return (
      <VideoContainer>
        <iframe title={videoId} src={embedUrl} allowFullScreen></iframe>
      </VideoContainer>
    );
  } else {
    return (
      <StyledLink target="_blank" rel="noreferrer" href={href}>
        {children}
      </StyledLink>
    );
  }
};

function stripMarkdown(markdownText) {
  // Patterns to remove: HTML tags, markdown links, images, headers, bold/italic, etc.
  const plainText = markdownText
    .replace(/<\/?[^>]+(>|$)/g, "") // Remove HTML tags
    .replace(/!\[.*?\]\(.*?\)/g, "") // Remove images
    .replace(/\[.*?\]\(.*?\)/g, "") // Remove links
    .replace(/#{1,6} /g, "") // Remove headers
    .replace(/\*\*(.*?)\*\*|__(.*?)__/g, "$1$2") // Remove bold
    .replace(/\*(.*?)\*|_(.*?)_/g, "$1$2") // Remove italic
    .replace(/~~(.*?)~~/g, "$1") // Remove strikethrough
    .replace(/`+(.*?)`+/g, "$1") // Remove inline code
    .replace(/>[ \t]*(.*)/g, "$1"); // Remove blockquotes

  return plainText;
}

function calculateReadTime(text) {
  const words = text.trim().split(/\s+/).length;
  const readingSpeed = 200; // Average reading speed in words per minute
  const time = Math.ceil(words / readingSpeed);
  return `${time} min read`;
}

function formatSlug(slug) {
  return slug.replace(/-/g, " ").replace(/\b\w/g, (char) => char.toUpperCase());
}

function extractFirstImage(markdown) {
  const imageRegex = /!\[.*?\]\((.*?)\)/;
  const match = markdown.match(imageRegex);
  if (match) {
    const imageUrl = match[1];
    // Check if the URL is a relative path (local image)
    if (!imageUrl.startsWith("http") && !imageUrl.startsWith("https")) {
      return `https://www.wiretaptech.com${imageUrl}`;
    }
    return imageUrl;
  }
  return "https://www.wiretaptech.com/logo512.png"; // Default image if no image found
}

const BlogPost = () => {
  const [content, setContent] = useState("");
  const [readTime, setReadTime] = useState("");
  const [scrollPercentage, setScrollPercentage] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [firstImage, setFirstImage] = useState("");
  const { articleSlug } = useParams();

  useEffect(() => {
    const sendAWSAnalyticsEvent = () => {
      // Check if not localhost
      if (!window.location.hostname.includes("localhost")) {
        try {
          Analytics.record({
            name: "Read Blog",
            attributes: {
              blogTitle: articleSlug, // Ensure values are strings for AWS Analytics
            },
          });
        } catch (error) {
          console.warn("Could not send analytics to AWS", error);
        }
      } else {
        console.log("Localhost detected, not sending analytics.");
      }
    };

    sendAWSAnalyticsEvent();
  }, [articleSlug]); // This effect runs every time the articleSlug changes.

  useEffect(() => {
    fetch(`/blogPosts/${articleSlug}/${articleSlug}.md`)
      .then((response) => response.text())
      .then((text) => {
        setContent(text);
        const strippedText = stripMarkdown(text);
        setReadTime(calculateReadTime(strippedText));
        setFirstImage(extractFirstImage(text));
        setIsLoading(false); // Set loading to false once content is loaded
      })
      .catch((err) => {
        console.error("Failed to load markdown file:", err);
        setIsLoading(false); // Set loading to false even if there's an error
      });

    const handleScroll = () => {
      const totalScroll = document.documentElement.scrollTop;
      const windowHeight =
        document.documentElement.scrollHeight -
        document.documentElement.clientHeight;
      const scroll = (totalScroll / windowHeight) * 100;
      setScrollPercentage(scroll);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [articleSlug]);

  const markdownOptions = {
    overrides: {
      h1: {
        component: Title,
      },
      h3: {
        component: Author,
      },
      a: {
        component: CustomLink,
      },
      blockquote: {
        component: Callout,
      },
    },
  };

  return (
    <BlogContainer>
      <SEO
        title={formatSlug(articleSlug)}
        description={`${formatSlug(articleSlug)} - ${readTime}`}
        image={firstImage}
        url={`https://www.wiretaptech.com/blog/${articleSlug}`}
        type="article"
      />
      <ProgressBar scroll={scrollPercentage} />
      <PostContent>
        {isLoading ? (
          <LoadingContainer>Loading content...</LoadingContainer>
        ) : (
          <>
            <ReadTime>{readTime}</ReadTime>
            <TopDisclaimer>
              [Legal Disclaimer] I’m a retired prosecutor and still a lawyer,
              but I am not YOUR lawyer. Nothing mentioned in these posts are
              meant to constitute legal advice nor establish an attorney-client
              relationship. Please see the Disclaimer at the bottom of this post
              as well as our{" "}
              <StyledLink href="/helpcenter/terms">
                Terms and Conditions
              </StyledLink>
              .
            </TopDisclaimer>
            <Markdown options={markdownOptions}>{content}</Markdown>
          </>
        )}
      </PostContent>
      {!isLoading && (
        <Disclaimer>
          <strong>Disclaimer:</strong>
          <br />
          Content on WireTap’s blog is for informational and entertainment
          purposes only. It does not constitute legal advice or establish an
          attorney-client relationship. Consult a qualified attorney for legal
          guidance. We do not guarantee the accuracy or completeness of the
          information provided.{" "}
          <StyledLink href="/helpcenter/terms">
            See our Terms and Conditions
          </StyledLink>
          .<br />
          <br />
          WireTap’s Blog mentions legal tech products and different applications
          for informational and entertainment purposes. We do not endorse any
          specific product, service, or company or process mentioned. We do not
          receive compensation for these mentions. Your decision to engage with
          any mentioned entity is at your own risk.{" "}
          <StyledLink href="/helpcenter/terms">
            See our Terms and Conditions
          </StyledLink>
          .
        </Disclaimer>
      )}
    </BlogContainer>
  );
};

export default BlogPost;
