import React from "react";
import styled, { keyframes } from "styled-components";

const spin = keyframes`
    from {
    transform: rotate(0deg);
    }

    to {
    transform: rotate(360deg);
    }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  animation: ${spin} 1s infinite linear;
`;

const Loader = ({ width = "32", height = "32", fill = "#484856" }) => {
  return (
    <Wrapper>
      <svg
        className="svg-icon"
        width={width}
        height={height}
        viewBox="0 0 32 32"
      >
        <g fill={fill}>
          <path
            d="M24.5857947 27.6710326C25.0275374 28.4361534 24.7706035 29.4114983 24 29.8564065 23.2347318 30.2982343 22.259386 30.0415746 21.8145134 29.2710326L19.4142053 25.1135771C18.9724626 24.3484563 19.2293965 23.3731114 20 22.9282032 20.7652682 22.4863754 21.740614 22.743035 22.1854866 23.5135771L24.5857947 27.6710326zM29.2710326 21.8145134C30.0361534 22.2562561 30.3013146 23.2293965 29.8564065 24 29.4145787 24.7652682 28.4415746 25.0306673 27.6710326 24.5857947L23.5135771 22.1854866C22.7484563 21.7437439 22.4832951 20.7706035 22.9282032 20 23.370031 19.2347318 24.343035 18.9693327 25.1135771 19.4142053L29.2710326 21.8145134zM27.6710326 7.41420535C28.4361534 6.9724626 29.4114983 7.22939649 29.8564065 8 30.2982343 8.7652682 30.0415746 9.74061396 29.2710326 10.1854866L25.1135771 12.5857947C24.3484563 13.0275374 23.3731114 12.7706035 22.9282032 12 22.4863754 11.2347318 22.743035 10.259386 23.5135771 9.81451336L27.6710326 7.41420535zM30.400308 14.4C31.2837935 14.4 32 15.1101837 32 16 32 16.8836556 31.2900534 17.6 30.400308 17.6L25.599692 17.6C24.7162065 17.6 24 16.8898163 24 16 24 15.1163444 24.7099466 14.4 25.599692 14.4L30.400308 14.4zM21.8145134 2.72896744C22.2562561 1.96384656 23.2293965 1.6986854 24 2.14359354 24.7652682 2.58542134 25.0306673 3.55842536 24.5857947 4.32896744L22.1854866 8.48642287C21.7437439 9.25154375 20.7706035 9.51670491 20 9.07179677 19.2347318 8.62996897 18.9693327 7.65696495 19.4142053 6.88642287L21.8145134 2.72896744z"
            opacity=".3"
          />
          <path d="M14.4,1.59969199 C14.4,0.716206498 15.1101837,0 16,0 C16.8836556,0 17.6,0.709946632 17.6,1.59969199 L17.6,6.40030801 C17.6,7.2837935 16.8898163,8 16,8 C15.1163444,8 14.4,7.29005337 14.4,6.40030801 L14.4,1.59969199 Z" />
          <path
            d="M17.6,30.400308 C17.6,31.2837935 16.8898163,32 16,32 C15.1163444,32 14.4,31.2900534 14.4,30.400308 L14.4,25.599692 C14.4,24.7162065 15.1101837,24 16,24 C16.8836556,24 17.6,24.7099466 17.6,25.599692 L17.6,30.400308 Z"
            opacity=".4"
          />
          <path
            d="M10.1854866,29.2710326 C9.7437439,30.0361534 8.77060351,30.3013146 8,29.8564065 C7.2347318,29.4145787 6.96933267,28.4415746 7.41420535,27.6710326 L9.81451336,23.5135771 C10.2562561,22.7484563 11.2293965,22.4832951 12,22.9282032 C12.7652682,23.370031 13.0306673,24.343035 12.5857947,25.1135771 L10.1854866,29.2710326 Z"
            opacity=".5"
          />
          <path
            d="M4.32896744,24.5857947 C3.56384656,25.0275374 2.58850168,24.7706035 2.14359354,24 C1.70176574,23.2347318 1.95842536,22.259386 2.72896744,21.8145134 L6.88642287,19.4142053 C7.65154375,18.9724626 8.62688863,19.2293965 9.07179677,20 C9.51362457,20.7652682 9.25696495,21.740614 8.48642287,22.1854866 L4.32896744,24.5857947 Z"
            opacity=".6"
          />
          <path
            d="M1.59969199,17.6 C0.716206498,17.6 0,16.8898163 0,16 C0,15.1163444 0.709946632,14.4 1.59969199,14.4 L6.40030801,14.4 C7.2837935,14.4 8,15.1101837 8,16 C8,16.8836556 7.29005337,17.6 6.40030801,17.6 L1.59969199,17.6 Z"
            opacity=".7"
          />
          <path
            d="M2.72896744,10.1854866 C1.96384656,9.7437439 1.6986854,8.77060351 2.14359354,8 C2.58542134,7.2347318 3.55842536,6.96933267 4.32896744,7.41420535 L8.48642287,9.81451336 C9.25154375,10.2562561 9.51670491,11.2293965 9.07179677,12 C8.62996897,12.7652682 7.65696495,13.0306673 6.88642287,12.5857947 L2.72896744,10.1854866 Z"
            opacity=".8"
          />
          <path
            d="M7.41420535,4.32896744 C6.9724626,3.56384656 7.22939649,2.58850168 8,2.14359354 C8.7652682,1.70176574 9.74061396,1.95842536 10.1854866,2.72896744 L12.5857947,6.88642287 C13.0275374,7.65154375 12.7706035,8.62688863 12,9.07179677 C11.2347318,9.51362457 10.259386,9.25696495 9.81451336,8.48642287 L7.41420535,4.32896744 Z"
            opacity=".9"
          />
        </g>
      </svg>
    </Wrapper>
  );
};

export default Loader;
