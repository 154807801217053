import React from "react";
import { Helmet } from "react-helmet";

const SEO = ({ title, description, image, url, type = "website" }) => {
  const defaultTitle = "WireTap"; // Default title for fallback
  const defaultDescription =
    "WireTap transcribes your audio files to find evidence. Search for key terms in jail calls. Transcribe body cam and other a/v files to find relevant evidence quickly. Stop wasting time listening to hours of audio. Just WireTap it.";
  const defaultImage = "https://www.wiretaptech.com/logo512.png"; // Default image URL
  const defaultUrl = "https://www.wiretaptech.com"; // Default URL

  return (
    <Helmet
      title={title || defaultTitle}
      htmlAttributes={{ lang: "en" }}
      meta={[
        {
          name: "description",
          content: description || defaultDescription,
        },
        {
          property: "og:title",
          content: title || defaultTitle,
        },
        {
          property: "og:description",
          content: description || defaultDescription,
        },
        {
          property: "og:image",
          content: image || defaultImage,
        },
        {
          property: "og:url",
          content: url || defaultUrl,
        },
        {
          property: "og:type",
          content: type,
        },
      ]}
    />
  );
};

export default SEO;
