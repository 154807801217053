import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { useHistory, Link } from "react-router-dom";
import { UserContext } from "../utils/context.js";
import SEO from "../components/SEO.jsx";

// MUI Components
import Divider from "@mui/material/Divider";

// Components
import MainJumbotron from "../components/MainJumbotron.jsx";
import DragAndDrop from "../components/DragAndDrop.jsx";
import WarningErrorSnackbars from "../components/WarningErrorSnackbar.jsx";
import {
  RightSideCallList,
  BottomButtons,
  ModalView,
  IntroTutorial,
} from "../components/AddAudioComponents.jsx";

// Utils
import { refreshAlert } from "../utils/helper functions/Alerts.js";
import { MAX_FILE_SIZE_MB, MAX_DURATION_PER_FILE } from "../utils/constants.js";
import { getFileSizeInMB } from "../utils/helper functions/files_data_helpers.js";

// AWS
import { AddCallsToCase } from "../utils/helper functions/aws_cases_helper.js";
import { getProject, getUser } from "../utils/queries.js";
import { subscribeUpdateUser } from "../utils/subscriptions.js";
import { Analytics } from "aws-amplify";

const Wrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  min-width: 800px;

  .MuiBackdrop-root {
    z-index: 3;
  }
`;

const Body = styled.div`
  margin: 20px 14% 0px;
  display: flex;
  min-width: 588px;
`;

const SideWrapper = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
`;

const HeaderText = styled.div`
  align-self: center;
  font-weight: 600;
  font-size: 1.2rem;
  margin-bottom: 15px;
`;

const StyledDivider = styled(Divider)`
  &.MuiDivider-vertical {
    width: 2px;
  }
`;

const StyledLink = styled(Link)`
  cursor: pointer;
  color: ${(props) => props.theme.primaryDeep};
  text-decoration: underline;
`;

const AddCallsToCasePage = ({ match }) => {
  const [currentCase, setCurrentCase] = useState({
    title: "",
    dateMade: "",
    type: "",
    callCount: "",
    totalDuration: "",
    status: "",
    calls: [],
  });
  const [secondsAvailableToTranscribe, setSecondsAvailableToTranscribe] =
    useState(undefined);
  const [timeAvailableAfterTranscription, setTimeAvailableAfterTranscription] =
    useState(undefined);
  const [files, setFiles] = useState([]);
  const [filesLength, setFilesLength] = useState(0);
  const [ErrorSnackbar, setErrorSnackbar] = useState(false);
  const [WarnSnackbar, setWarnSnackbar] = useState(false);
  const [uploadingCallsPercent, setUploadingCallsPercent] = useState(undefined);
  const [uploadingCallsMessage, setUploadingCallsMessage] = useState("");
  const [showIntroTutorial, setShowIntroTutorial] = useState(false);
  const history = useHistory();
  const user = useContext(UserContext);

  const sendAWSAnalyticsEvent = (files) => {
    try {
      if (user) {
        const numFiles = files.length;
        let duration = 0;
        for (let file of files) {
          duration += file.duration;
        }
        let attr = { ...user.attributes };
        attr["id"] = attr.sub;
        delete attr.sub;
        Analytics.record({
          name: "Added Call(s) To Case",
          // Attribute values must be strings
          attributes: attr,
          metrics: { numFiles: numFiles, duration: Math.round(duration) },
        });
      }
    } catch {
      console.warn("Could not send analytics to AWS");
    }
  };

  //Subscribe to secondsAvailableToTranscribe.
  useEffect(() => {
    if (user) {
      let sub = subscribeUpdateUser(
        user.username,
        setSecondsAvailableToTranscribe
      );
      return () => {
        if (sub) {
          sub.unsubscribe();
        }
      };
    }
  }, [user]);

  /**
   * Gets current case info and seconds available to transcribe
   */
  useEffect(() => {
    const getCase = async () => {
      try {
        const caseID = match.params.id;
        const projectInfo = await getProject(caseID, false);
        if (typeof projectInfo === "string") {
          console.error(
            `Failed to receive project due to error: ${projectInfo}`
          );
          history.push("/unauthorized");
          return;
        }
        setCurrentCase(projectInfo);
      } catch {
        history.push("/pagenotfound");
      }
      try {
        // Get seconds available to transcribe
        const databaseUser = await getUser(user.username);
        if (databaseUser.showIntroTutorial) {
          setShowIntroTutorial(true);
        }
        setSecondsAvailableToTranscribe(
          databaseUser.secondsAvailableToTranscribe
        );
      } catch (err) {
        console.error(err);
        refreshAlert("validate users transcription time", err);
      }
    };
    if (user) {
      getCase();
    }
  }, [match.params.id, history, user]);

  //Adds duration to each file.
  useEffect(() => {
    if (files.length !== filesLength) {
      // Change in the number of files
      if (files.length > filesLength) {
        // added new file
        for (const file of files) {
          if (file.duration === undefined) {
            let myAudio = document.createElement("video");
            myAudio.preload = "metadata";
            myAudio.onloadedmetadata = function () {
              window.URL.revokeObjectURL(myAudio.src);
              file.duration = myAudio.duration;
              setFiles([...files]);
            };
            myAudio.onerror = function () {
              file.duration = 1;
              setFiles([...files]);
            };
            myAudio.src = URL.createObjectURL(file);
          }
        }
      }
      setFilesLength(files.length);
      setFiles([...files]);
    }
  }, [files, filesLength]);

  /**
   * Checks if all uploaded files have a duration
   * @returns true if all files have a duration false otherwise
   */
  const filesHaveDuration = () => {
    for (const file of files) {
      if (!file.duration) {
        setErrorSnackbar(
          <span>
            Could not get duration for 1 or more files. Please make sure you
            have converted your calls as described{" "}
            <StyledLink
              target="_blank"
              to="/helpcenter/userguide#19"
              rel="noopener noreferrer"
            >
              here
            </StyledLink>
            .
          </span>
        );
        return false;
      }
    }
    return true;
  };

  /**
   * Submits all calls to backend for processing. All calls must have duration.
   * @param {event} event event
   */
  const handleSubmit = (event) => {
    event.preventDefault();
    if (timeAvailableAfterTranscription < 0) {
      const transcriptionTimeNeeded = Math.abs(timeAvailableAfterTranscription);
      const hoursNeeded = Math.floor(transcriptionTimeNeeded / (60 * 60)); // transcriptionTimeNeeded is in seconds.
      let minutesNeeded = Math.ceil(
        (transcriptionTimeNeeded - hoursNeeded * 60 * 60) / 60
      );
      if (hoursNeeded === 0 && minutesNeeded < 2) minutesNeeded = 2; // Stripe's lowest accepted amount is $0.50
      window.open(
        `${window.location.origin}/payment/${hoursNeeded}/${minutesNeeded}`,
        "_blank"
      );
      return;
    }

    // Check all past and current calls aren't past max duration
    if (files.length === 0) {
      setErrorSnackbar("Please upload at least 1 audio file.");
    } else {
      if (filesHaveDuration()) {
        // Check all files have a duration
        sendAWSAnalyticsEvent(files);
        AddCallsToCase(
          match.params.id,
          files,
          setUploadingCallsPercent,
          setUploadingCallsMessage,
          history,
          `/case/${match.params.id}`
        );
      }
    }
  };

  /**
   * Renders warning if any files are over max size or over max duration or more than time you have remaining to transcribe
   */
  useEffect(() => {
    let dur = 0;
    // Loop through files and check file size and durations
    for (const file of files) {
      if (getFileSizeInMB(file.size) > MAX_FILE_SIZE_MB) {
        setWarnSnackbar(
          `Current max file size is ${MAX_FILE_SIZE_MB}MB. Some files will not be uploaded.`
        );
      }
      if (file.duration) {
        dur += file.duration;
        if (file.duration > MAX_DURATION_PER_FILE) {
          setWarnSnackbar(
            `Current max file duration is ${Math.round(
              MAX_FILE_SIZE_MB / 60 / 60
            )} hours. Some files will not be uploaded.`
          );
        }
      }
    }
    if (secondsAvailableToTranscribe !== undefined) {
      setTimeAvailableAfterTranscription(secondsAvailableToTranscribe - dur);
    }
  }, [files, secondsAvailableToTranscribe]);

  return (
    <Wrapper>
      <SEO
        title="Add Calls To Case | WireTap"
        description="Add new calls to your existing WireTap cases. Transcribe your audio files to find evidence. Search for
            key terms in jail calls. Stop wasting time listening to hours
            of audio. Just WireTap it."
      />
      <MainJumbotron
        title={"Add Calls"}
        metrics={[
          { header: "Date Made", body: currentCase.dateMade },
          { header: "Type", body: currentCase.type },
          { header: "Call Count", body: currentCase.callCount },
          { header: "Total Call Time", body: currentCase.totalDuration },
          { header: "Status", body: currentCase.status },
        ]}
        breadcrumb={[
          { name: "My Cases", link: `/home` },
          { name: currentCase.title, link: `/case/${match.params.id}` },
          { name: "Add Calls" },
        ]}
      />
      <form onSubmit={handleSubmit}>
        <Body>
          <SideWrapper>
            <HeaderText>New Audio Files</HeaderText>
            <DragAndDrop currentFiles={files} setFiles={setFiles} />
          </SideWrapper>
          <StyledDivider orientation="vertical" flexItem />
          <RightSideCallList files={files} setFiles={setFiles} />
        </Body>
        <BottomButtons
          uploadingCallsPercent={uploadingCallsPercent}
          timeAvailableAfterTranscription={timeAvailableAfterTranscription}
          numCallsLoaded={files.length}
        />
      </form>
      <WarningErrorSnackbars
        errorMessage={ErrorSnackbar}
        setErrorMessage={setErrorSnackbar}
        warningMessage={WarnSnackbar}
        setWarningMessage={setWarnSnackbar}
      />
      <ModalView
        uploadingCallsPercent={uploadingCallsPercent}
        uploadingCallsMessage={uploadingCallsMessage}
      />
      <IntroTutorial
        showIntroTutorial={showIntroTutorial}
        setShowIntroTutorial={setShowIntroTutorial}
      />
    </Wrapper>
  );
};

export default AddCallsToCasePage;
