import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { UserContext } from "../utils/context.js";
import MainJumbotron from "../components/MainJumbotron.jsx";
import Autocomplete from "@mui/material/Autocomplete";
import { TextField } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import Tooltip from "@mui/material/Tooltip";
import StyledButton from "../components/StyledButton.jsx";
import TemplateModal from "../components/TemplateModal.jsx";
import { DataGrid } from "@mui/x-data-grid";
import { useHistory } from "react-router-dom";
import { getUsersProjectsAndCalls, getProject } from "../utils/queries.js";
import { deleteCalls, deleteProjects } from "../utils/mutations.js";
// import { deleteDatabaseForUser } from "../utils/helper functions/aws_cases_helper.js";
import { secsToString } from "../utils/helper functions/files_data_helpers.js";
import { COMPLETE, IN_PROGRESS } from "../utils/constants.js";
import SEO from "../components/SEO.jsx";
import Backdrop from "@mui/material/Backdrop";
import Loader from "../components/Loader.jsx";
import { getCookieConsentValue } from "react-cookie-consent";
// import {
//   updateEveryUser,
//   updateEveryProject,
//   updateEveryCall,
// } from "../utils/helper functions/admin_templates.js";

const Wrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  min-width: 800px;
`;

const MainContentWrapper = styled.div`
  margin: 0px 60px;
`;

const MainContentHeading = styled.div`
  padding: 30px 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const TableWrapper = styled.div`
  width: 100%;
  margin-bottom: 20px;
  .MuiDataGrid-root .MuiDataGrid-row {
    cursor: pointer;
  }
`;

const TableButtonsWrapper = styled.div`
  border-top: 1px solid rgb(74, 74, 74);
  width: 100%;
  display: flex;
  flex-direction: row;
  height: 41px;
  padding-left: 6px;
`;

const columns = [
  { field: "title", headerName: "Case Name", flex: 1 },
  {
    field: "dateMadeUnix",
    headerName: "Date Made",
    type: "date",
    valueGetter: ({ value }) => new Date(value * 1000),
    flex: 1,
  },
  { field: "type", headerName: "Type", flex: 1 },
  {
    field: "callCount",
    headerName: "Call Count",
    type: "number",
    headerAlign: "left",
    align: "left",
    flex: 1,
  },
  {
    field: "totalDuration",
    headerName: "Total Duration",
    type: "number",
    headerAlign: "left",
    align: "left",
    valueFormatter: ({ value }) => secsToString(value),
    flex: 1,
  },
  {
    field: "totalCallWithEvidence",
    headerName: "Calls With Evidence",
    type: "number",
    headerAlign: "left",
    align: "left",
    flex: 1,
  },
];

const HomePage = () => {
  const consent = getCookieConsentValue("WireTapCookieConsent");
  const [pageSize, setPageSize] = useState(() => {
    if (consent === "false") {
      return 50;
    } else {
      return localStorage.getItem("CallsPageSize") || 50;
    }
  });
  const [inputValue, setInputValue] = useState("");
  const [projectData, setProjectData] = useState(undefined);
  const [projectsMetadata, setProjectsMetadata] = useState({
    totalCases: "",
    totalCalls: "",
    totalDuration: "",
  });
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedRows, setSelectedRows] = useState([]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [sortModel, setSortModel] = useState([
    {
      field: "dateMadeUnix",
      sort: "desc",
    },
  ]);
  const history = useHistory();
  const user = useContext(UserContext);

  const GetCasesData = async () => {
    const username = user.username;
    const projectsAndCalls = await getUsersProjectsAndCalls(username, false);
    const totalCases = projectsAndCalls.length;
    let totalCalls = 0;
    let totalDuration = 0;
    projectsAndCalls.forEach((project) => {
      totalCalls += project.callCount;
      totalDuration += project.totalDuration;
    });
    setProjectsMetadata({
      totalCases: totalCases,
      totalCalls: totalCalls,
      totalDuration: secsToString(totalDuration, true),
    });
    setProjectData(projectsAndCalls);
  };

  /**
   * Get all the cases from AWS and set it in state
   */
  useEffect(() => {
    const GetCasesData = async () => {
      const username = user.username;
      const projectsAndCalls = await getUsersProjectsAndCalls(username, false);
      const totalCases = projectsAndCalls.length;
      let totalCalls = 0;
      let totalDuration = 0;
      projectsAndCalls.forEach((project) => {
        totalCalls += project.callCount;
        totalDuration += project.totalDuration;
      });
      setProjectsMetadata({
        totalCases: totalCases,
        totalCalls: totalCalls,
        totalDuration: secsToString(totalDuration, true),
      });
      setProjectData(projectsAndCalls);
    };
    if (user) {
      GetCasesData();
    }
  }, [user]);

  /**
   * Filter on user search bar for cases
   */
  useEffect(() => {
    if (projectData) {
      let rows = [];

      // Check case type and name to match search
      projectData.forEach((item) => {
        if (
          item.type.toLowerCase().includes(inputValue.toLowerCase()) ||
          item.title.toLowerCase().includes(inputValue.toLowerCase())
        ) {
          if (item.status === COMPLETE) {
            item.status = `${COMPLETE} ✓`;
          } else if (item.status === IN_PROGRESS) {
            item.status = `${IN_PROGRESS}...`;
          }
          rows.push(item);
        }
      });
      setRows(rows);
      setLoading(false);
    }
  }, [inputValue, projectData]);

  return (
    <Wrapper>
      <SEO
        title="Home | WireTap"
        description="Review all your cases in Wiretap. Use WireTap to transcribe your audio files and search through them for evidence. Download the transcriptions to use for future use."
      />
      <MainJumbotron
        title="My Cases"
        metrics={[
          { header: "Total Cases", body: projectsMetadata.totalCases },
          { header: "Total Calls", body: projectsMetadata.totalCalls },
          { header: "Total Duration", body: projectsMetadata.totalDuration },
        ]}
      />
      <MainContentWrapper>
        <MainContentHeading>
          <Autocomplete
            id="combo-box-demo"
            options={projectData ? projectData : []}
            getOptionLabel={(option) => option.title}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
            }}
            style={{ width: 300 }}
            renderInput={(params) => (
              <TextField {...params} label="Search Cases" variant="outlined" />
            )}
          />
          <StyledButton
            color={"white"}
            style={{
              background: "linear-gradient(#209BCF, #26B7F5)",
              border: "none",
            }}
            onClick={() => history.push("/createcase")}
          >
            + Create New Case
          </StyledButton>
          {/* <CheckoutButton /> */}
          {/* <StyledButton
            color={"white"}
            style={{
              background: "red",
              border: "none",
            }}
            onClick={() => updateEveryCall()}
          >
            Admin Button
          </StyledButton> */}
        </MainContentHeading>
        <TableButtonsWrapper>
          {selectedRows.length > 0 && (
            <Tooltip title="Delete" arrow>
              <IconButton
                aria-label="delete"
                onClick={() => setOpenDeleteModal(true)}
              >
                <DeleteIcon sx={{ color: "rgb(74, 74, 74)" }} />
              </IconButton>
            </Tooltip>
          )}
        </TableButtonsWrapper>
        <TableWrapper>
          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={parseInt(pageSize)}
            disableColumnMenu
            autoHeight
            disableColumnFilter
            sortModel={sortModel}
            checkboxSelection
            onSelectionModelChange={(selectedRowIds) => {
              setSelectedRows(selectedRowIds);
            }}
            onSortModelChange={(model) => {
              setSortModel(model);
            }}
            rowsPerPageOptions={[5, 10, 20, 50, 100]}
            onPageSizeChange={(newPageSize) => {
              if (consent !== "false") {
                localStorage.setItem("CallsPageSize", newPageSize);
              }
              setPageSize(newPageSize);
            }}
            onRowClick={(gridRowParam, event) => {
              history.push(`/case/${gridRowParam.row.id}`);
            }}
            loading={loading}
          />
        </TableWrapper>
      </MainContentWrapper>
      <TemplateModal
        open={openDeleteModal}
        setOpen={setOpenDeleteModal}
        header={selectedRows.length > 1 ? "Delete Cases" : "Delete Case"}
        subHeader={`Deleting ${selectedRows.length} case${
          selectedRows.length > 1 ? "s" : ""
        }`}
        bodyElements={
          <span style={{ marginTop: "10px" }}>
            You are about to delete {selectedRows.length} case
            {selectedRows.length > 1 ? "s" : ""}. <b>This cannot be undone.</b>{" "}
            Please confirm you wish to delete{" "}
            {selectedRows.length > 1 ? "these cases" : "this case"}.
          </span>
        }
        size="small"
        closeButton="Delete"
        closeCallback={async () => {
          setDeleteLoading(true);
          for (const id of selectedRows) {
            let project = await getProject(id);
            let callIds = [];
            for (const call of project.calls) {
              callIds.push(call.id);
            }
            await deleteCalls(callIds);
          }
          await deleteProjects(selectedRows);
          await GetCasesData();
          setDeleteLoading(false);
        }}
      />
      <Backdrop open={deleteLoading}>
        <Loader width={40} height={40} fill="white" />
      </Backdrop>
    </Wrapper>
  );
};

export default HomePage;
