import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Link,
} from "@react-pdf/renderer";
import { secsToMinsHours } from "../utils/time";

const styles = StyleSheet.create({
  header: { flexDirection: "column", alignItems: "center" },
  subHeader: { fontStyle: "italic", fontSize: 10, color: "grey", marginTop: 5 },
  title: {
    fontSize: 24,
    textAlign: "center",
  },
  body: {
    paddingTop: 35,
    paddingBottom: 35,
    paddingHorizontal: 35,
    // fontFamily: "Helvetica Neue",
  },
  mainText: {
    flexGrow: 1,
    marginTop: 10,
  },
  transcriptionBox: {
    display: "flex",
    flexDirection: "column",
    margin: "8px 25px",
  },
  transcriptionLineHeader: {
    display: "flex",
    flexDirection: "row",
    marginBottom: "5px",
    fontSize: 14,
    textDecoration: "underline",
    alignItems: "center",
  },
  speakerName: {
    fontWeight: "bold",
    color: "black",
    marginRight: "15px",
  },
  speakerTime: {
    color: "#8294a5",
    fontWeight: "400",
    fontSize: 11,
  },
  speakerText: {
    fontSize: 12,
  },
  footer: {
    fontSize: 8,
    marginBottom: 20,
    textAlign: "center",
    color: "grey",
    flexDirection: "row",
    justifyContent: "center",
    bottom: "0px",
    position: "fixed",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 10,
    bottom: 10,
    right: 35,
    textAlign: "center",
    color: "grey",
  },
});

const PDFTranscription = ({ currentCall, redacted }) => {
  const transcription = currentCall.transcription;
  const lines = transcription.map((line, index) => {
    return (
      <View key={index} style={styles.transcriptionBox}>
        <View style={styles.transcriptionLineHeader}>
          <Text style={styles.speakerName}>{line.speaker}:</Text>
          <Text style={styles.speakerTime}>
            {secsToMinsHours(line.start_time)}
          </Text>
        </View>
        <Text style={styles.speakerText}>
          {redacted && line.redactedText ? line.redactedText : line.text}
        </Text>
      </View>
    );
  });

  return (
    <Document style={styles.doc}>
      <Page style={styles.body}>
        <View style={styles.header}>
          <Text>{currentCall.audioFileName}</Text>
          <Text style={styles.subHeader}>{currentCall.project.title}</Text>
          <Text
            style={[
              styles.subHeader,
              {
                paddingBottom: 15,
                width: "100%",
                borderBottom: "1px solid black",
                textAlign: "center",
              },
            ]}
          >
            {currentCall.date} - {currentCall.time}
          </Text>
        </View>
        <View style={styles.mainText}>{lines}</View>
        <View style={styles.footer} fixed>
          <Text style={{ marginRight: "5px" }}>~</Text>
          <Text>
            Created With <Link src="https://wiretaptech.com">WireTap</Link>
          </Text>
          <Text style={{ marginLeft: "5px" }}>~</Text>
        </View>
        {/* Add page number */}
        <Text
          render={({ pageNumber, totalPages }) =>
            `Page ${pageNumber} of ${totalPages}`
          }
          fixed
          style={styles.pageNumber}
        />
      </Page>
    </Document>
  );
};

export default PDFTranscription;
