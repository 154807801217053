/**
 * Given any string value, returns back a hex color
 * @param {string} string any string value
 * @returns hex color
 */
export function stringToColor(string) {
  if (string === "Speaker 0") return "#52d0f0";
  if (string === "Speaker 1") return "#19c185";
  var i = string.length;
  var hash1 = 5381;
  var hash2 = 52711;

  while (i--) {
    const char = string.charCodeAt(i);
    hash1 = (hash1 * 33) ^ char;
    hash2 = (hash2 * 33) ^ char;
  }

  let hash = (hash1 >>> 0) * 4096 + (hash2 >>> 0);
  let color = "#";

  for (let i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}
