import React from "react";
import styled from "styled-components";
import { Breadcrumbs } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Link } from "react-router-dom";

const Jumbotron = styled.div`
  height: 25vh;
  min-height: 200px;
  background-image: linear-gradient(
    ${(props) => `${props.theme.secondaryDark},${props.theme.secondaryLight}`}
  );
  display: flex;
  justify-content: space-between;
  position: relative;
`;

const Title = styled.div`
  color: ${(props) => props.theme.primaryWhite};
  font-weight: bold;
  font-size: 2.5rem;
  margin-left: 5%;
  align-self: center;
`;

const MetricsWrapper = styled.div`
  margin-right: 5%;
  background-color: rgba(255, 255, 255, 0.15);
  align-self: flex-end;
  margin-bottom: 10px;
  color: ${(props) => props.theme.primaryWhite};
  display: flex;
  flex-direction: row;
`;

const SingleMetric = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 10px;
  font-size: 1.1rem;
  font-weight: 600;
  white-space: nowrap;
`;

const MetricHeader = styled.div`
  text-align: center;
  margin-bottom: 15px;
`;

const MetricBody = styled.div`
  text-align: center;
`;

const BreadcrumbWrapper = styled.div`
  position: absolute;
  margin-top: 20px;
  margin-left: 20px;
`;

const StyledLink = styled(Link)`
  color: ${(props) => props.theme.primaryGrey};
  text-decoration: none;
  font-size: 0.9rem;
  :hover {
    text-decoration: underline;
    color: ${(props) => props.theme.primaryWhite};
  }
`;

const PageOnLink = styled.div`
  color: white;
  font-size: 0.9rem;
`;

const MainJumbotron = ({ title, metrics, breadcrumb }) => {
  if (!metrics) {
    metrics = [];
  }
  const metricsJSX = metrics.map((met) => (
    <SingleMetric key={met.header}>
      <MetricHeader>{met.header}</MetricHeader>
      <MetricBody>{met.body}</MetricBody>
    </SingleMetric>
  ));

  const getBreadcrumb = () => {
    if (breadcrumb) {
      return breadcrumb.map((item, index) => {
        if (index === breadcrumb.length - 1) {
          return <PageOnLink key={item.name}>{item.name}</PageOnLink>;
        }
        return (
          <StyledLink key={item.name} to={item.link}>
            {item.name}
          </StyledLink>
        );
      });
    } else {
      return [];
    }
  };

  return (
    <Jumbotron>
      <BreadcrumbWrapper>
        <Breadcrumbs
          separator={
            <NavigateNextIcon style={{ color: "white" }} fontSize="small" />
          }
          aria-label="breadcrumb"
        >
          {getBreadcrumb()}
        </Breadcrumbs>
      </BreadcrumbWrapper>
      <Title>{title}</Title>
      <MetricsWrapper>{metricsJSX}</MetricsWrapper>
    </Jumbotron>
  );
};

export default React.memo(MainJumbotron);
