import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Document, Page } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = "/pdf.worker.js";

const AnchorLink = styled.div`
  border-bottom: 1px solid black;
`;

const options = {
  cMapUrl: "cmaps/",
  cMapPacked: true,
  standardFontDataUrl: "standard_fonts/",
};

export default function PDFViewer({ pdf, goToPage = 0, containerRef }) {
  const [numPages, setNumPages] = useState(null);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  useEffect(() => {
    scrollToPage(goToPage);
  });

  const scrollToPage = (page) => {
    const element = document.getElementById(`page_${page}`);
    if (element) {
      setTimeout(() => {
        element.scrollIntoView({ behavior: "smooth" });
      }, 100);
    }
  };

  const itemClicked = (pageInfo) => {
    scrollToPage(pageInfo.pageNumber);
  };

  let pdfWidth = Math.max(
    containerRef.current ? containerRef.current.offsetWidth - 50 : 500,
    500
  );

  return (
    <Document
      file={pdf}
      options={options}
      onLoadSuccess={onDocumentLoadSuccess}
      onItemClick={itemClicked}
    >
      {Array.from(new Array(numPages), (el, index) => (
        <AnchorLink id={`page_${index + 1}`} key={`page_${index + 1}`}>
          <Page width={pdfWidth} pageNumber={index + 1} pageIndex={index} />
        </AnchorLink>
      ))}
    </Document>
  );
}
