import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import SEO from "../components/SEO.jsx";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  color: ${(props) => props.theme.textGrey};
  font-size: 1.9rem;
`;

const InfoText = styled.div`
  font-size: 1.4rem;
`;

const StyledLink = styled(Link)`
  margin: 0px 10px;
  cursor: pointer;
  color: ${(props) => props.theme.linkBlue};
  text-decoration: none;
  font-size: 1.4rem;
  margin 0px;
`;

function PageNotFound() {
  return (
    <Wrapper name="Page Not Found Wrapper">
      <SEO
        title="Page Not Found | WireTap"
        description="This page does not exist. Please check your URL again. WireTap"
      />
      <h1>404 Page not Found</h1>
      <InfoText>
        To return home, click <StyledLink to="/home">here</StyledLink>
      </InfoText>
    </Wrapper>
  );
}
export default PageNotFound;
