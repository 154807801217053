import React, { useState } from "react";
import styled from "styled-components";
import TextField from "@mui/material/TextField";
import StyledButton from "../../components/StyledButton.jsx";
import { Auth } from "aws-amplify";
import WarningErrorSnackbars from "../../components/WarningErrorSnackbar.jsx";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import ReactCodeInput from "react-verification-code-input";
import { useHistory } from "react-router-dom";
import SEO from "../../components/SEO.jsx";
import { Analytics } from "aws-amplify";

const Wrapper = styled.div`
  background-color: ${(props) => props.themebackgroundGrey}
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  align-self: stretch;
`;

const LoginWrapper = styled.form`
  width: 500px;
  height: 500px;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  display flex;
  flex-direction: row;
  margin: 40px 45px 10px;
  justify-content: center;
  align-items: center;
`;

const HeadingText = styled.div`
  font-size: 2rem;
  text-align: center;
  color: ${(props) => props.theme.heading};
  font-weight: 600;
`;

const SloganWraper = styled.div`
  display flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  ${(props) => props.theme.headingLight};
  font-size: 1.5rem;
  margin-bottom: 30px;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ForgotPasswordHeader = styled.div`
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
  font-size: 1.3rem;
`;

const ForgotPasswordText = styled.div`
  width: 100%;
  text-align: center;
`;

const SingleInputWrapper = styled.div`
  margin: 0px 20px;
`;

const VerifyWrapper = styled(SingleInputWrapper)`
    display flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
`;

const StyledTextField = styled(TextField)`
  &.MuiFormControl-fullWidth {
    margin-top: 20px;
  }
  .MuiFormLabel-root.Mui-focused {
    color: ${(props) => props.theme.primaryLight};
  }
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${(props) => props.theme.primaryLight};
  }
  .MuiInput-underline:after {
    border-bottom: 2px solid ${(props) => props.theme.primaryLight};
  }
`;

const ForgotPasswordPage = ({ setUser }) => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [verify, setVerify] = useState(false);
  const [verificationCode, setVerificationCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordVerify, setNewPasswordVerify] = useState("");
  let history = useHistory();

  const sendAWSAnalyticsEvent = () => {
    try {
      let attr = { email: email };

      Analytics.record({
        name: "Forgot Password",
        // Attribute values must be strings
        attributes: attr,
      });
    } catch {
      console.warn("Could not send analytics to AWS");
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    sendAWSAnalyticsEvent();
    // Send confirmation code to user's email
    if (!verify) {
      try {
        await Auth.forgotPassword(email);
        setVerify(true);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        if (error.code === "UserNotFoundException") {
          setError("User not found.");
        } else if (error.code === "InvalidParameterException") {
          setError("Invalid format.");
        } else {
          setError(error.message);
        }
      }
    } else {
      //Sets new password
      if (newPassword === newPasswordVerify) {
        //Set new password
        try {
          await Auth.forgotPasswordSubmit(email, verificationCode, newPassword);
          const user = await Auth.signIn(email, newPassword);
          setUser(user);
          setLoading(false);
          history.push("/home");
        } catch (error) {
          setError(error.message);
          setLoading(false);
        }
      } else {
        setLoading(false);
        setError("Passwords do not match.");
        setNewPasswordVerify("");
        setNewPassword("");
      }
    }
  };

  return (
    <Wrapper>
      <SEO
        title="Forgot Password | WireTap"
        description="Forgot your password for WireTap? Click here to generate a new password to your email."
      />
      <LoginWrapper onSubmit={handleSubmit}>
        <Header>
          <HeadingText>WireTap</HeadingText>
        </Header>
        <SloganWraper>Tap Into Your Evidence</SloganWraper>
        {!verify && (
          <InputWrapper>
            <ForgotPasswordHeader>Forgot Password</ForgotPasswordHeader>
            <ForgotPasswordText>
              Please enter your email below to search for your account
            </ForgotPasswordText>
            <SingleInputWrapper>
              <StyledTextField
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                label="Email"
                type="email"
                fullWidth
              />
            </SingleInputWrapper>
            <StyledButton
              type="submit"
              color={"white"}
              style={{
                background: "linear-gradient(#209BCF, #26B7F5)",
                border: "none",
                margin: "20px 20px",
              }}
            >
              Search
            </StyledButton>
          </InputWrapper>
        )}
        {verify && (
          <InputWrapper>
            {" "}
            <ForgotPasswordText>
              Please enter verification code sent to your email and enter new
              password.
            </ForgotPasswordText>
            <VerifyWrapper>
              <ReactCodeInput
                required
                onComplete={(code) => setVerificationCode(code)}
                autoFocus
              />
            </VerifyWrapper>
            <SingleInputWrapper>
              <StyledTextField
                required
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                label="New Password"
                type="password"
                autoComplete="new-password"
                fullWidth
              />
            </SingleInputWrapper>
            <SingleInputWrapper>
              <StyledTextField
                required
                value={newPasswordVerify}
                onChange={(e) => setNewPasswordVerify(e.target.value)}
                label="Verify New Password"
                type="password"
                autoComplete="new-password"
                fullWidth
              />
            </SingleInputWrapper>
            <StyledButton
              type="submit"
              color={"white"}
              style={{
                background: "linear-gradient(#209BCF, #26B7F5)",
                border: "none",
                margin: "20px 20px",
              }}
            >
              Submit
            </StyledButton>
          </InputWrapper>
        )}
      </LoginWrapper>
      {/* Additions to page such as modal and snackbar below */}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <WarningErrorSnackbars errorMessage={error} setErrorMessage={setError} />
    </Wrapper>
  );
};

export default ForgotPasswordPage;
