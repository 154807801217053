import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import MainJumbotron from "../components/MainJumbotron.jsx";
import { getProject } from "../utils/queries.js";
import Chip from "@mui/material/Chip";
import StyledButton from "../components/StyledButton.jsx";
import { useHistory, Link } from "react-router-dom";
import KeyTermsModal from "../components/KeyTermsModal.jsx";
import fuzzysort from "fuzzysort";
import { getFinalStringJSX } from "../utils/helper functions/keyterms_contains_helper.js";
import WarningErrorSnackbars from "../components/WarningErrorSnackbar.jsx";
import Loader from "../components/Loader.jsx";
import MarkEvidenceModal from "../components/MarkEvidenceModal.jsx";
import SuccessSnackbar from "../components/successSnackbar.jsx";
import { KEY_TERMS_BOLD_DELIMINATOR, FAILED } from "../utils/constants.js";
import { UserContext } from "../utils/context.js";
import SEO from "../components/SEO.jsx";

const Wrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  min-width: 800px;
`;

const TopRowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin: 20px;
`;

const KeyTermBackground = styled.div`
  box-sizing: border-box;
  background-color: ${(props) => props.theme.keyTermsBlue};
  border-radius: 25px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: scroll;
  margin-right: 20px;
`;

const KeyTermHeader = styled.div`
  font-weight: 450;
  font-size: 1.1rem;
`;

const KeyTermBody = styled.div`
  margin-bottom: 10px;
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
`;

const StyledChip = styled(Chip)`
  &.MuiChip-root {
    background-color: white;
    margin: 10px 5px 0px;
  }
`;

const TopButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 1px solid lightgrey;
  margin: 0px 20px 20px;
  padding-top: 20px;
  box-sizing: border-box;
  flex-grow: 1;
`;

const KeyTermWrapper = styled.div`
  display: flex
  flex-direction: column;
  width: 100%;
  border: 1px solid lightgrey;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 25px;
  padding: 10px 20px;
  box-sizing: border-box;
  margin-bottom: 20px;
`;

const KeyTermTop = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: ${(props) => props.theme.textGrey};
  font-size: 0.85rem;
`;

const KeyTermsButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const KeyTermButton = styled.div`
  margin: 0px 10px;
  cursor: pointer;
  color: ${(props) => props.theme.linkBlue};
`;

const KeyTermLink = styled(Link)`
  margin: 0px 10px;
  cursor: pointer;
  color: ${(props) => props.theme.linkBlue};
  text-decoration: none;
`;

const KeyTermsBody = styled.div`
  flex-grow: 1;
  margin-top: 10px;
`;

const NoneFound = styled.div`
  display: flex;
  justify-content: center;
  font-size: 2.5rem;
  color: grey;
  flex-grow: 1;
  align-items: center;
`;

// Options for fuzzy search
const fuzzySortOptions = {
  key: "text",
  allowTypo: true,
  threshold: -10000,
  limit: 100,
};

const KeyTermsSearchPage = ({ match }) => {
  const [currentCase, setCurrentCase] = useState({
    title: "",
    dateMade: "",
    type: "",
    callCount: "",
    totalDuration: "",
    status: "",
    calls: [],
  });
  const [ErrorSnackbar, setErrorSnackbar] = useState(false);
  const [WarnSnackbar, setWarnSnackbar] = useState(false);
  const [searchKeyTerms, setSearchKeyTerms] = useState(false); // Opens modal to search for key terms
  const [markEvidenceID, setMarkEvidenceID] = useState(false); // Opens modal to mark text as evidence. False if modal is closer. ID if true.
  const [keyTerms, setKeyTerms] = useState(false);
  const [keyTermsChips, setKeyTermsChips] = useState([]);
  const [containsCalls, setContainsCalls] = useState([]); // List of calls with **Potential** evidence
  const [successMessage, setSuccessMessage] = useState(false);
  const history = useHistory();
  const user = useContext(UserContext);

  /**
   * Gets case info and transcriptions
   */
  useEffect(() => {
    const getCase = async () => {
      const caseID = match.params.id;
      const projectInfo = await getProject(caseID, true);
      if (typeof projectInfo === "string") {
        console.error(`Failed to receive project due to error: ${projectInfo}`);
        history.push("/unauthorized");
        return;
      }
      setCurrentCase(projectInfo);
    };
    if (user) {
      getCase();
    }
  }, [match.params.id, user, history]);

  /**
   * Get key terms and chips for JSX
   */
  useEffect(() => {
    const updatedKeyTerms = match.params.terms.split(",");
    setKeyTerms(updatedKeyTerms);
    setKeyTermsChips(
      updatedKeyTerms.map((term) => <StyledChip label={term} key={term} />)
    );
  }, [match.params.terms]);

  /**
   * Checks if the ints in the passed indexes are within the allowed distance also passed in.
   * Used to determine if the found chars are from one word or multiple.
   * @param {[int]} indexes The indexes of the found chars
   * @param {int} allowedDistance The distance between chars that are allowed. Must be >= 1
   * @returns Bool True if all elements are within allowedDistance. False otherwise
   */
  const indexesCloseBy = (indexes, allowedDistance) => {
    for (let i = 0; i < indexes.length; i++) {
      if (indexes[i + 1] === undefined) return true; // Reached end of array
      if (indexes[i + 1] - indexes[i] > allowedDistance) {
        return false;
      }
    }
    return true;
  };

  /**
   * Finds if the result target is in the contains list and returns the index. Returns -1 if not found
   * @param {object} result result from search fuzzysort
   * @param {[object]} contains List of results from fuzzysort
   * @returns index of where result target is in contains. Or -1 if not in contains
   */
  const indexOfContainsArray = (result, contains) => {
    for (const index in contains) {
      if (contains[index].target === result.target) {
        return index;
      }
    }
    return -1;
  };

  /**
   * Gets all potential uses of key Terms and sets them in the "contains" field
   * and stores this as a new object in "containsCalls" state variable
   */
  useEffect(() => {
    if (keyTerms.length > 0 && currentCase.calls.length > 0) {
      let newCalls = JSON.parse(JSON.stringify(currentCase.calls)); // Deep copy

      // Loop through each call to find key terms. Using fuzzysort to allow for basic typos.
      for (let call of newCalls) {
        if (!call.transcriptionReady) {
          //Only check calls with ready transcriptions.
          if (call.status === FAILED) {
            continue;
          } else {
            setWarnSnackbar("Some calls still being transcribed or failed.");
            continue;
          }
        }
        call.hide = false;
        let transcript = JSON.parse(call.transcription);
        let contains = []; // Array of all potential key terms found

        // Loop through each key term and add found terms to contains array
        for (const searchTerm of keyTerms) {
          let result = fuzzysort.go(searchTerm, transcript, fuzzySortOptions); // fuzzy search results
          // Loop through each of the potentially found key terms
          for (let res of result) {
            if (indexesCloseBy(res.indexes, 2)) {
              // If the found chars are all near eachother, set score to 0.
              res.score = 0;
            } else {
              // Check if term shows up in string using indexOf.
              const index = res.target.indexOf(searchTerm);
              if (index >= 0) {
                // Set new indexes if in string and set score to 0.
                let newIndexes = [];
                for (let i = index; i < index + searchTerm.length; i++) {
                  newIndexes.push(i);
                }
                res.indexes = newIndexes;
                res.score = 0;
              }
            }
          }

          // Only keep matches or 1 off typos
          result = result.filter((a) => a.score === 0);

          // Push matches to "contains" array
          for (const res of result) {
            //Search if string is already in use from other search key term
            const index = indexOfContainsArray(res, contains);
            if (index >= 0) {
              // key term string used already previously
              let originalResult = contains[index];
              if (originalResult.indexes.join(",") !== res.indexes.join(",")) {
                originalResult.indexes = originalResult.indexes.concat(
                  res.indexes
                );
              }
              originalResult.indexes.sort((a, b) => a - b);
            } else {
              contains.push(res);
            }
          }
        }

        //Sort in order it was spoken
        const transcriptList = transcript.map((item) => item.text);
        for (let res of contains) {
          res.originalIndex = transcriptList.indexOf(res.target);
        }
        contains.sort((a, b) => a.originalIndex - b.originalIndex);

        //Highlight results as needed
        let highlightedContains = [];
        for (const res of contains) {
          highlightedContains.push(
            fuzzysort.highlight(
              res,
              KEY_TERMS_BOLD_DELIMINATOR,
              KEY_TERMS_BOLD_DELIMINATOR
            )
          );
        }
        call.contains = highlightedContains;
      }
      setContainsCalls(newCalls);
    }
  }, [keyTerms, currentCase.calls]);

  /**
   * Hides or unhides a call. Sorts the hidden calls but does not sort when unhiding
   * @param {object} call the call asked to be hidden or unhidden
   */
  const hideUnhideCall = (call) => {
    const index = containsCalls.indexOf(call);
    let newContainsCalls = [...containsCalls];
    newContainsCalls[index].hide = !newContainsCalls[index].hide;
    if (newContainsCalls[index].hide) {
      //Only sort when hiding
      newContainsCalls.sort((a, b) => {
        if (a.hide && b.hide) return 0;
        if (a.hide && !b.hide) return 1;
        if (!a.hide && b.hide) return -1;
        return 0;
      });
    }
    setContainsCalls(newContainsCalls);
  };

  /**
   * Builds the jsx needed from the contains field in each call from "containsCalls"
   * @returns JSX to show on page
   */
  const buildFoundTermsJSX = () => {
    if (currentCase.title) {
      let jsx = [];
      for (const call of containsCalls) {
        if (call.contains && call.contains.length > 0) {
          if (call.hide) {
            jsx.push(
              <KeyTermWrapper key={call.id}>
                <KeyTermTop>
                  <div style={{ flexGrow: 1 }}>
                    {call.audioFileName} - {call.date} - {call.durationString}
                  </div>
                  <KeyTermsButtonsWrapper>
                    <KeyTermButton onClick={() => hideUnhideCall(call)}>
                      Unhide
                    </KeyTermButton>
                  </KeyTermsButtonsWrapper>
                </KeyTermTop>
              </KeyTermWrapper>
            );
          } else {
            jsx.push(
              <KeyTermWrapper key={call.id}>
                <KeyTermTop>
                  <div style={{ flexGrow: 1 }}>
                    {call.audioFileName} - {call.date} - {call.durationString}
                  </div>
                  <KeyTermsButtonsWrapper>
                    <KeyTermLink to={`/call/${call.id}`}>
                      View Transcript
                    </KeyTermLink>
                    <KeyTermButton
                      onClick={() => {
                        setSearchKeyTerms(false);
                        setMarkEvidenceID(call.id);
                      }}
                    >
                      Mark as Evidence
                    </KeyTermButton>
                    <KeyTermButton onClick={() => hideUnhideCall(call)}>
                      Hide
                    </KeyTermButton>
                  </KeyTermsButtonsWrapper>
                </KeyTermTop>
                <KeyTermsBody>
                  {getFinalStringJSX(call.contains, KEY_TERMS_BOLD_DELIMINATOR)}
                </KeyTermsBody>
              </KeyTermWrapper>
            );
          }
        }
      }
      if (jsx.length === 0) {
        jsx.push(
          <NoneFound key="No key terms found">No Key Terms Found</NoneFound>
        );
      }
      return jsx;
    } else {
      return <Loader />;
    }
  };

  //TODO: Must find proper ID since call still being transcribed breaks the order of list

  return (
    <Wrapper>
      <SEO
        title="Key Terms Search | WireTap"
        description="Search through your transcripts at one time using our key terms searching feature. Search through thousands of audio files for potential evidence in a matter of seconds."
      />
      <MainJumbotron
        title={`${currentCase.title} Key Terms`}
        metrics={[
          { header: "Date Made", body: currentCase.dateMade },
          { header: "Type", body: currentCase.type },
          { header: "Call Count", body: currentCase.callCount },
          { header: "Total Call Time", body: currentCase.totalDuration },
          { header: "Status", body: currentCase.status },
        ]}
        breadcrumb={[
          { name: "My Cases", link: `/home` },
          { name: currentCase.title, link: `/case/${match.params.id}` },
          { name: "Key Terms" },
        ]}
      />
      <TopRowWrapper>
        <KeyTermBackground>
          <KeyTermHeader>Selected Key Terms</KeyTermHeader>
          <KeyTermBody>{keyTermsChips}</KeyTermBody>
        </KeyTermBackground>
        <TopButtonsWrapper>
          <StyledButton
            color={"white"}
            style={{
              background: "linear-gradient(#209BCF, #26B7F5)",
              border: "none",
              marginBottom: "10px",
            }}
            onClick={() => {
              setMarkEvidenceID(false);
              setSearchKeyTerms(true);
            }}
            disabled={!Boolean(currentCase.title)}
          >
            Edit Key Terms
          </StyledButton>

          <StyledButton
            color={"#209BCF"}
            style={{
              background: "linear-gradient(#FFFFFF, #E9E9E9)",
            }}
            onClick={() => history.push(`/case/${match.params.id}`)}
            disabled={!Boolean(currentCase.title)}
          >
            Return to Case
          </StyledButton>
        </TopButtonsWrapper>
      </TopRowWrapper>
      <BodyWrapper>{buildFoundTermsJSX()}</BodyWrapper>
      <WarningErrorSnackbars
        errorMessage={ErrorSnackbar}
        setErrorMessage={setErrorSnackbar}
        warningMessage={WarnSnackbar}
        setWarningMessage={setWarnSnackbar}
      />
      <SuccessSnackbar
        successMessage={successMessage}
        setSuccessMessage={setSuccessMessage}
      />
      {Boolean(currentCase.title) && (
        <React.Fragment>
          <KeyTermsModal
            open={searchKeyTerms}
            setOpen={setSearchKeyTerms}
            caseID={match.params.id}
            startingKeyTerms={match.params.terms.split(",")}
            setSearchKeyTerms={setSearchKeyTerms}
          />
          <MarkEvidenceModal
            open={Boolean(markEvidenceID)}
            setOpen={setMarkEvidenceID}
            evidenceID={markEvidenceID}
            currentCalls={currentCase.calls}
            containsCalls={containsCalls}
            setSuccessMessage={setSuccessMessage}
            setErrorMessage={ErrorSnackbar}
            setContainsCalls={setContainsCalls}
          />
        </React.Fragment>
      )}
    </Wrapper>
  );
};

export default KeyTermsSearchPage;
