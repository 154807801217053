import React, { useState } from "react";
import styled from "styled-components";
import { classifierAccepted, classifierRejected } from "../utils/mutations";

const SummaryWrapper = styled.div`
  border: ${(props) => (props.bordered ? "2px solid black" : "none")};
  padding: ${(props) => (props.bordered ? "5px" : "0px")};
  margin-bottom: 20px;
`;

const EvidenceButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const EvidenceButton = styled.div`
  margin: 0px 10px;
  cursor: pointer;
  color: ${(props) => props.theme.linkBlue};
  font-size: 0.85rem;
  :hover {
    text-decoration: underline;
  }
`;

const SmallButton = styled(EvidenceButton)`
  font-size: 0.7rem;
`;

const SummaryHeading = styled.div`
  font-weight: 650;
  font-size: 1.3rem;
  color: ${(props) => props.theme.heading};
  text-decoration: underline;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  text-decoration: none;
`;

const SummaryHeadingSpacedButtons = styled(SummaryHeading)`
  justify-content: space-between;
`;

const ClassifierText = styled.div`
  font-weight: 500;
  font-style: italic;
`;

const ClassifierSummaryText = styled.div`
  margin-bottom: 20px;
  margin-top: 20px;
`;

const Ellipses = styled.div`
  color: grey;
  font-size: 2rem;
  font-weight: bold;
  margin-left: 10px;
`;

const ClassifierSummary = ({ currentCall, setCurrentCall }) => {
  let needsReview =
    currentCall.classifierContainsEvidence &&
    !currentCall.classifierHasBeenReviewed;
  let classifierAcceptedByUser =
    currentCall.classifierContainsEvidence &&
    currentCall.classifierHasBeenReviewed &&
    currentCall.classifierResultWasAccepted;
  let classifierRejectedByUser =
    currentCall.classifierContainsEvidence &&
    currentCall.classifierHasBeenReviewed &&
    !currentCall.classifierResultWasAccepted;
  let callHasNoEvidence = !currentCall.classifierContainsEvidence;

  const [hidden, setHidden] = useState(classifierRejectedByUser);

  if (hidden) {
    // Evidence and summary is hidden
    return (
      <SummaryWrapper style={{ marginBottom: "20px" }}>
        <SummaryHeading style={{ marginBottom: "0px" }}>
          <u>Summary</u>

          <EvidenceButtonsWrapper>
            <EvidenceButton
              onClick={() => {
                setHidden(false);
              }}
            >
              Unhide
            </EvidenceButton>
          </EvidenceButtonsWrapper>
        </SummaryHeading>
        <Ellipses>...</Ellipses>
      </SummaryWrapper>
    );
  } else if (needsReview) {
    //Call has not been reviewed but has been taged by classifier as having evidence
    return (
      <SummaryWrapper bordered>
        <SummaryHeading>
          <u>Summary</u>

          <EvidenceButtonsWrapper>
            <EvidenceButton
              onClick={async () => {
                let id = await classifierAccepted(currentCall.id);
                if (id === currentCall.id) {
                  setCurrentCall((call) => {
                    return {
                      ...call,
                      classifierHasBeenReviewed: true,
                      classifierResultWasAccepted: true,
                    };
                  });
                } else {
                  console.error(
                    "Could not update database to accept classifier"
                  );
                  console.log(id);
                }
              }}
            >
              Mark as Evidence
            </EvidenceButton>
            <EvidenceButton
              onClick={async () => {
                let id = await classifierRejected(currentCall.id);
                if (id === currentCall.id) {
                  setHidden(true);
                  setCurrentCall((call) => {
                    return {
                      ...call,
                      classifierHasBeenReviewed: true,
                      classifierResultWasAccepted: false,
                    };
                  });
                } else {
                  console.error(
                    "Could not update database to reject classifier"
                  );
                  console.log(id);
                }
              }}
            >
              Not Relevant
            </EvidenceButton>
          </EvidenceButtonsWrapper>
        </SummaryHeading>
        <ClassifierText>
          <div
            style={{
              fontSize: ".8rem",
              fontWeight: "300",
              marginBottom: "10px",
            }}
          >
            *It is important to review the full transcript and audio of the call
            to make a final determination of its relevance to your case.
          </div>
          Our analysis <b>has detected</b> potential evidence in this call for:{" "}
          <b>
            <u>{currentCall.classifierTags.join(", ")}</u>
          </b>
          .
        </ClassifierText>
        <ClassifierSummaryText>
          {currentCall.classifierSummary}
        </ClassifierSummaryText>
      </SummaryWrapper>
    );
  } else if (classifierAcceptedByUser) {
    //Call has evidence and has been accepted by user
    return (
      <SummaryWrapper bordered>
        <SummaryHeadingSpacedButtons>
          <u>Summary</u>

          <EvidenceButtonsWrapper>
            <SmallButton
              onClick={async () => {
                let id = await classifierRejected(currentCall.id);
                if (id === currentCall.id) {
                  setCurrentCall((call) => {
                    return {
                      ...call,
                      classifierHasBeenReviewed: true,
                      classifierResultWasAccepted: false,
                    };
                  });
                } else {
                  console.error(
                    "Could not update database to reject classifier"
                  );
                  console.log(id);
                }
              }}
            >
              Mark Not Evidence
            </SmallButton>
          </EvidenceButtonsWrapper>
        </SummaryHeadingSpacedButtons>
        <ClassifierText>
          Evidence: <b>{currentCall.classifierTags.join(", ")}</b>
        </ClassifierText>
        <ClassifierSummaryText>
          {currentCall.classifierSummary}
        </ClassifierSummaryText>
      </SummaryWrapper>
    );
  } else if (classifierRejectedByUser) {
    //Call has evidence and has been rejected by user
    return (
      <SummaryWrapper>
        <SummaryHeadingSpacedButtons>
          <u>Summary</u>

          <EvidenceButtonsWrapper>
            <EvidenceButton
              onClick={async () => {
                let id = await classifierAccepted(currentCall.id);
                if (id === currentCall.id) {
                  setCurrentCall((call) => {
                    return {
                      ...call,
                      classifierHasBeenReviewed: true,
                      classifierResultWasAccepted: true,
                    };
                  });
                } else {
                  console.error(
                    "Could not update database to accept classifier"
                  );
                  console.log(id);
                }
              }}
            >
              Mark as Evidence
            </EvidenceButton>
            <EvidenceButton
              onClick={() => {
                setHidden(true);
              }}
            >
              Hide
            </EvidenceButton>
          </EvidenceButtonsWrapper>
        </SummaryHeadingSpacedButtons>
        <ClassifierText>
          Our classifier has found evidence for:{" "}
          <b>{currentCall.classifierTags.join(", ")}</b>. but has been marked as
          not relevant.
        </ClassifierText>
        <ClassifierSummaryText>
          {currentCall.classifierSummary}
        </ClassifierSummaryText>
      </SummaryWrapper>
    );
  } else if (callHasNoEvidence) {
    //Call has no evidence
    return (
      <SummaryWrapper>
        <SummaryHeadingSpacedButtons>
          <u>Summary</u>

          <EvidenceButtonsWrapper>
            <EvidenceButton
              onClick={() => {
                setHidden(true);
              }}
            >
              Hide
            </EvidenceButton>
          </EvidenceButtonsWrapper>
        </SummaryHeadingSpacedButtons>
        <ClassifierText>
          Our analysis <b>did not</b> reveal any evidence in this call. However,
          we still recommend that you review the call transcript and audio to
          ensure that no relevant information has been missed. We have included
          a summary of the call below:
        </ClassifierText>
        <ClassifierSummaryText>
          {currentCall.classifierSummary}
        </ClassifierSummaryText>
      </SummaryWrapper>
    );
  } else {
    return <React.Fragment />;
  }
};

export default ClassifierSummary;
