import React, { useState, useEffect } from "react";
import styled, { ThemeProvider } from "styled-components";
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "@stripe/stripe-js";
import ReactGA from "react-ga4";
import { Analytics } from "aws-amplify";
import CookieConsent, {
  getCookieConsentValue,
  // resetCookieConsentValue,
} from "react-cookie-consent";

// Util files
import theme from "./utils/theme.js";
import { UserContext } from "./utils/context.js";
import { MAINTENANCE } from "./utils/constants";

// Pages
// import PageHeader from "./components/PageHeader.jsx";
import ResponsiveHeader from "./components/ResponsiveHeader.jsx";
import Footer from "./components/Footer.jsx";
import WelcomePage from "./pages/WelcomePage.jsx";
import HomePage from "./pages/HomePage.jsx";
import CasePage from "./pages/CasePage.jsx";
import KeyTermsSearchPage from "./pages/KeyTermSearchPage.jsx";
import CallPage from "./pages/CallPage.jsx";
import AddCasePage from "./pages/AddCasePage.jsx";
import AddCallsToCasePage from "./pages/AddCallsToCasePage.jsx";
import SignOn from "./pages/Sign On/SignOn.jsx";
import ForgotPassword from "./pages/Sign On/ForgotPasswordPage.jsx";
import PageNotFound from "./pages/PageNotFound.jsx";
import Unauthorized from "./pages/Unauthorized.jsx";
import PaymentPage from "./pages/PaymentPage.jsx";
import SuccessPaymentPage from "./pages/SuccessPaymentPage.jsx";
import CancelPaymentPage from "./pages/CancelPaymentPage";
import HelpCenterPage from "./pages/HelpCenterPage";
import BulkPurchaseSelect from "./pages/BulkPurchaseSelectPage";
import MaintenancePage from "./pages/Maintenance";
import EvidencePage from "./pages/Evidence.jsx";
import BlogPost from "./pages/BlogPost.jsx";

const PageWrapper = styled.div`
  min-height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
`;

const ContentWrapper = styled.div`
  padding-bottom: 2.5rem;
  color: ${(props) => props.theme.infoText};
  font-size: 1rem;
  flex-grow: 1;
  display: flex;
`;

const App = () => {
  const [user, setUser] = useState(undefined);

  useEffect(() => {
    const consent = getCookieConsentValue("WireTapCookieConsent");

    if (!window.location.origin.includes("localhost")) {
      // Initialize AWS Amplify Analytics for necessary cookies like "shopping cart" tracking
      Analytics.enable();

      if (consent !== "false") {
        // Initialize Google Analytics
        const gaOptions = { siteSpeedSampleRate: 100 };
        if (user) {
          gaOptions.userId = user.username;
        }
        ReactGA.initialize([
          { trackingId: "G-NB77WXJ1LX", gaOptions: gaOptions },
        ]);

        // Enable Amazon page view analytics
        if (user) {
          let attr = { ...user.attributes };
          attr["id"] = attr.sub;
          delete attr.sub;
          Analytics.autoTrack("pageView", {
            enable: true,
            eventName: "pageView",
            attributes: attr,
            type: "SPA",
            provider: "AWSPinpoint",
            getUrl: () =>
              window.location.pathname.includes("/keytermsearch")
                ? "/case/keytermsearch"
                : "/" + window.location.pathname.split("/")[1] || "/",
          });
        }
      }
    } else {
      Analytics.disable();
    }
  }, [user]);

  // User declined cookies. Turn off analytics
  const handleDecline = () => {
    ReactGA.ga("remove");
    Analytics.disable();
  };

  return (
    <PageWrapper name="App Wrapper">
      <ThemeProvider theme={theme}>
        <UserContext.Provider value={user}>
          {MAINTENANCE ? (
            <MaintenancePage />
          ) : (
            <Router name="Router">
              <ResponsiveHeader setUser={setUser} />
              <ContentWrapper name="Content Wrapper">
                <Switch>
                  <Route path="/" exact component={WelcomePage} />
                  <Route
                    path="/login"
                    exact
                    component={({ location }) => (
                      <SignOn setUser={setUser} location={location} />
                    )}
                  />
                  <Route
                    path="/forgotpassword"
                    exact
                    component={() => <ForgotPassword setUser={setUser} />}
                  />
                  <Route
                    path="/helpcenter/:pdf"
                    exact
                    component={HelpCenterPage}
                  />
                  <Route path="/home" exact component={HomePage} />
                  <Route path="/case/:id" exact component={CasePage} />
                  <Route
                    path="/case/:id/addcalls"
                    exact
                    component={AddCallsToCasePage}
                  />
                  <Route
                    path="/case/:id/keytermsearch/:terms"
                    exact
                    component={KeyTermsSearchPage}
                  />
                  <Route
                    path="/case/:id/evidence"
                    exact
                    component={EvidencePage}
                  />
                  <Route
                    path="/payment/:hours/:mins"
                    exact
                    component={PaymentPage}
                  />
                  <Route
                    path="/bulkpurchaseselect"
                    exact
                    component={BulkPurchaseSelect}
                  />
                  <Route
                    path="/successpayment/:hours/:mins"
                    exact
                    component={SuccessPaymentPage}
                  />
                  <Route
                    path="/cancelpayment/:hours/:mins"
                    exact
                    component={CancelPaymentPage}
                  />
                  <Route path="/createcase" exact component={AddCasePage} />
                  <Route path="/call/:id" exact component={CallPage} />
                  <Route path="/blog/:articleSlug" exact component={BlogPost} />
                  <Route path="/unauthorized" exact component={Unauthorized} />
                  <Route component={PageNotFound} />
                </Switch>
              </ContentWrapper>
              <Footer />
              <CookieConsent
                location="bottom"
                buttonText="Accept and Continue"
                declineButtonText="Decline"
                cookieName="WireTapCookieConsent"
                expires={365}
                enableDeclineButton
                onDecline={handleDecline}
                style={{
                  background: theme.secondaryDark,
                  color: theme.primaryWhite,
                  maxWidth: "450px",
                  bottom: "1rem",
                  left: "1rem",
                  borderRadius: "8px",
                  padding: "1rem",
                  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                  marginBottom: "5px",
                }}
                buttonStyle={{
                  background: theme.button,
                  color: theme.primaryWhite,
                  border: "none",
                  padding: "0.5rem 1rem",
                  borderRadius: "4px",
                  marginLeft: "10px",
                }}
                declineButtonStyle={{
                  background: theme.lightgrey,
                  color: theme.primaryWhite,
                  border: "none",
                  padding: "0.5rem 1rem",
                  borderRadius: "4px",
                }}
              >
                WireTap uses cookies to enhance the user experience. We do not
                collect more information than necessary and never sell your
                data. We only use cookies for our own analytics purposes and to
                provide a better user experience for you.{" "}
                <a
                  href="/helpcenter/privacy#3"
                  style={{ color: theme.primaryWhite }}
                >
                  Learn more
                </a>
              </CookieConsent>
            </Router>
          )}
        </UserContext.Provider>
      </ThemeProvider>
    </PageWrapper>
  );
};
export default App;
