import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${(props) => props.theme.medium} {
    width: 100%;
    height: 500px;
    flex-direction: row;
  }
`;

const ImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  ${(props) => props.theme.medium} {
    width: 50%;
    padding-right: 40px;
  }
`;

const TextWrapper = styled.div`
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 50px;

  ${(props) => props.theme.medium} {
    width: 50%;
    padding-right: 40px;
    padding-right: 10px;
    padding-left: 30px;
    text-align: left;
    align-items: flex-start;
    margin-bottom: 0px;
  }
`;

const StyledImage = styled.img`
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  height: auto;
  border-radius: 8px;
`;

const Title = styled.div`
  font-size: 1.6rem;
  margin-bottom: 50px;
  text-transform: uppercase;
`;

const SubTitle = styled.div`
  font-size: 1.15rem;
  margin-bottom: 40px;
  display: ${(props) => (props.display ? "default" : "none")};
`;

const Body = styled.div`
  font-size: 1rem;
`;

const SocialCarouselComponent = ({ image, title, subtitle, text, link }) => {
  const imageContent = link ? (
    <a href={link} target="_blank" rel="noopener noreferrer">
      <StyledImage src={image} />
    </a>
  ) : (
    <StyledImage src={image} />
  );
  return (
    <Wrapper>
      <TextWrapper>
        <Title>{title}</Title>
        <SubTitle display={subtitle}>{subtitle}</SubTitle>
        <Body>{text}</Body>
      </TextWrapper>
      <ImageWrapper>{imageContent}</ImageWrapper>
    </Wrapper>
  );
};

export default SocialCarouselComponent;
