import React, { useEffect, useState } from "react";
import styled from "styled-components";
import "./styles-login.css";
import SignInForm from "./SignIn.jsx";
import SignUpForm from "./SignUp.jsx";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import WarningErrorSnackbars from "../../components/WarningErrorSnackbar.jsx";
import ReactCodeInput from "react-verification-code-input";
import Logo from "../../utils/images/WT Logo.png";
import { Auth } from "aws-amplify";
import { useHistory } from "react-router-dom";
import { checkCreateUser } from "../../utils/helper functions/aws_cases_helper.js";

const Wrapper = styled.div`
  text-align: center;
  background-color: ${(props) => props.themebackgroundGrey}
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  align-self: stretch;
`;

const Heading1 = styled.h1`
  font-weight: bold;
  margin: 0;
`;

const Paragraph = styled.p`
  font-size: 0.9rem;
  line-height: 20px;
  letter-spacing: 0.5px;
  margin: 20px 0 30px;
`;

const StyledButton = styled.button`
  border-radius: 20px;
  border: ${(props) =>
    props.ghost
      ? `0.1rem solid ${props.theme.buttonBorder}`
      : "1px solid #ffffff"};
  background: ${(props) =>
    props.ghost
      ? `linear-gradient(#FFFFFF, #E9E9E9)`
      : `linear-gradient(#209BCF, #26B7F5)`};
  color: ${(props) => (props.ghost ? "#209BCF" : "#ffffff")};
  font-size: 12px;
  font-weight: bold;
  padding: 12px 45px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: transform 80ms ease-in;
  cursor: pointer;

  &:active {
    transform: scale(0.95);
  }

  &:focus {
    outline: none;
  }
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const SubmittedText = styled.div`
  margin: 0px 20px;
  text-align: center;
  font-size: 1.2rem;
`;

const VerifyWrapper = styled.div`
    margin: 0px 20px;
    display flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 60px;
`;

const ImageWrapper = styled.div`
  width: 100px;
  height: 100px;
  align-self: center;
  margin-top: 35px;
`;

const StyledImage = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

const ConfirmWrapper = styled.div`
  background-color: ${(props) => props.themebackgroundGrey}
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  align-self: stretch;
`;

const SignUpWrapper = styled.div`
  width: 500px;
  min-height: 500px;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  margin-top: 10px;
`;

const Header = styled.div`
  display flex;
  flex-direction: row;
  margin: 40px 45px 10px;
  justify-content: center;
  align-items: center;
`;

const HeadingText = styled.div`
  font-size: 2rem;
  text-align: center;
  color: ${(props) => props.theme.heading};
  font-weight: 600;
`;

const SloganWraper = styled.div`
  display flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  ${(props) => props.theme.headingLight};
  font-size: 1.5rem;
  margin-bottom: 20px;
`;

const ResendLinkText = styled.div`
  text-align: center;
  cursor: pointer;
  text-decoration: underline;
  color: #0881f1;
  margin: 25px 0 0;
  font-size: 0.9rem;
`;

export default function SignOn({ location, setUser }) {
  const [confirmEmail, setConfirmEmail] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [type, setType] = useState("signIn");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [warning, setWarning] = useState(false);
  const [confirm, setConfirm] = useState(false);
  let history = useHistory();

  const SwitchSignOnType = (text) => {
    if (text !== type) {
      setType(text);
      return;
    }
  };
  const containerClass =
    "container " + (type === "signUp" ? "right-panel-active" : "");

  // If location hash is sending to signUp, adjust the screen to start at signUp instead of log in
  useEffect(() => {
    let view = location.hash;
    if (view.length > 1 && view.substring(1).toLowerCase() === "signup") {
      setType("signUp");
    }
  }, [location.hash]);

  /**
   * Tries to confirm email from user's code sent to email.
   * @param {string} code code entered by user gotten from email
   */
  const confirmSignUp = async (code) => {
    try {
      setLoading(true);
      await Auth.confirmSignUp(confirmEmail, code);
      const user = await Auth.signIn(confirmEmail, confirmPassword);
      await checkCreateUser();
      setUser(user);
      setLoading(false);
      history.push("/home");
    } catch (error) {
      setLoading(false);
      console.error("error confirming sign up", error);
      setError(
        "Invalid code. Please check you are using the new code sent and try again."
      );
    }
  };

  return (
    <React.Fragment>
      {!confirm && (
        <Wrapper>
          <div className={containerClass} id="container">
            <SignUpForm
              setLoading={setLoading}
              setError={setError}
              setConfirm={setConfirm}
              setConfirmEmail={setConfirmEmail}
              setConfirmPassword={setConfirmPassword}
            />
            <SignInForm
              setUser={setUser}
              setLoading={setLoading}
              setError={setError}
              setConfirm={setConfirm}
              setConfirmEmail={setConfirmEmail}
              setConfirmPassword={setConfirmPassword}
              history={history}
            />
            <div className="overlay-container">
              <div className="overlay">
                <div className="overlay-panel overlay-left">
                  <Heading1>Returning User?</Heading1>
                  <Paragraph>Welcome back! Log in here.</Paragraph>
                  <StyledButton
                    ghost
                    id="signIn"
                    onClick={() => SwitchSignOnType("signIn")}
                  >
                    Log In
                  </StyledButton>
                </div>
                <div className="overlay-panel overlay-right">
                  <Heading1>First Time Here?</Heading1>
                  <Paragraph>
                    Create your WireTap account and start your case analysis
                    today!
                  </Paragraph>
                  <StyledButton
                    ghost
                    id="signUp"
                    onClick={() => SwitchSignOnType("signUp")}
                  >
                    Sign Up
                  </StyledButton>
                </div>
              </div>
            </div>
          </div>
        </Wrapper>
      )}
      {confirm && (
        <ConfirmWrapper>
          <SignUpWrapper>
            <Header>
              <HeadingText>WireTap</HeadingText>
            </Header>
            <SloganWraper>Tap Into Your Evidence</SloganWraper>
            <InputWrapper>
              <SubmittedText>
                Please enter verification code sent to your email.
              </SubmittedText>
              <VerifyWrapper>
                <ReactCodeInput required onComplete={confirmSignUp} autoFocus />
              </VerifyWrapper>
              <ResendLinkText
                onClick={async () => {
                  await Auth.resendSignUp(confirmEmail);
                  setWarning(`Verification email resent to ${confirmEmail}`);
                }}
              >
                Didn’t receive an email? Click here to resend.
              </ResendLinkText>
              <ImageWrapper>
                <StyledImage src={Logo} />
              </ImageWrapper>
            </InputWrapper>
          </SignUpWrapper>
        </ConfirmWrapper>
      )}
      {/* Additions to page such as modal and snackbar below */}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <WarningErrorSnackbars
        errorMessage={error}
        setErrorMessage={setError}
        warningMessage={warning}
        setWarningMessage={setWarning}
      />
    </React.Fragment>
  );
}
