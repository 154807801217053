import React from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const SuccessSnackbar = ({
  successMessage = false,
  setSuccessMessage = () => {},
}) => {
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  /**
   * Closes snackback when clicks close button
   * @param {event} event Event
   * @param {string} reason Why is the snackbar closing
   */
  const handleCloseSuccess = (event, reason) => {
    if (reason !== "clickaway") {
      setSuccessMessage(false);
    }
  };

  return (
    <React.Fragment>
      <Snackbar
        open={Boolean(successMessage)}
        autoHideDuration={4000}
        onClose={handleCloseSuccess}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          severity="success"
          action={
            <React.Fragment>
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleCloseSuccess}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </React.Fragment>
          }
        >
          {successMessage}
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
};

export default SuccessSnackbar;
