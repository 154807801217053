import React, { useState } from "react";
import styled from "styled-components";
import TextField from "@mui/material/TextField";
import { Analytics } from "aws-amplify";
import { Auth } from "aws-amplify";

const Heading1 = styled.h1`
  font-weight: bold;
  margin: 0;
`;

const StyledSpan = styled.span`
  font-size: 0.75rem;
  margin: 10px 0px 0px;
  color: grey;
`;

const StyledLink = styled.div`
  text-align: center;
  cursor: pointer;
  text-decoration: underline;
  color: #0881f1;
  margin: 15px 0;
  font-size: 0.85rem;
`;

const StyledButton = styled.button`
  border-radius: 20px;
  border: 1px solid #ffffff;
  background: ${(props) =>
    props.ghost
      ? `linear-gradient(#FFFFFF, #E9E9E9)`
      : `linear-gradient(#209BCF, #26B7F5)`};
  color: ${(props) => (props.ghost ? "#209BCF" : "#ffffff")};
  font-size: 12px;
  font-weight: bold;
  padding: 12px 45px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: transform 80ms ease-in;
  cursor: pointer;

  &:active {
    transform: scale(0.95);
  }

  &:focus {
    outline: none;
  }
`;

const StyledForm = styled.form`
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 50px;
  height: 100%;
  text-align: center;
`;

const StyledTextField = styled(TextField)`
  margin: 0px 20px;
  &.MuiFormControl-fullWidth {
    margin-top: 20px;
  }
  .MuiFormLabel-root.Mui-focused {
    color: ${(props) => props.theme.primaryLight};
  }
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${(props) => props.theme.primaryLight};
  }
  .MuiInput-underline:after {
    border-bottom: 2px solid ${(props) => props.theme.primaryLight};
  }
`;

function SignInForm({
  setUser,
  setLoading,
  setError,
  setConfirm,
  setConfirmEmail,
  setConfirmPassword,
  history,
}) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const sendAWSAnalyticsEvent = () => {
    try {
      let attr = { email: email };
      Analytics.record({
        name: "Logged In",
        // Attribute values must be strings
        attributes: attr,
      });
    } catch {
      console.warn("Could not send analytics to AWS");
    }
  };

  /**
   * Logs in
   * @param {event} event event
   */
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    sendAWSAnalyticsEvent();
    try {
      const user = await Auth.signIn(email, password);
      setUser(user);
      history.push("/home");
    } catch (error) {
      if (error.message === "User is not confirmed.") {
        // Unconfirmed user.
        setConfirmEmail(email);
        setConfirmPassword(password);
        setConfirm(true);
        try {
          setLoading(false);
          await Auth.resendSignUp(email);
        } catch (err) {
          console.log("error resending code: ", err);
          setError(err.message);
        }
      } else {
        console.error(error);
        setLoading(false);
        setError(error.message);
        setEmail("");
        setPassword("");
      }
    }
  };

  return (
    <div className="form-container sign-in-container">
      <StyledForm onSubmit={handleSubmit}>
        <Heading1>Log in</Heading1>
        <StyledSpan>To access your WireTap account.</StyledSpan>
        <StyledTextField
          required
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          label="Email"
          type="email"
          variant="outlined"
          fullWidth
        />

        <StyledTextField
          id="standard-password-input"
          required
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          label="Password"
          type="password"
          autoComplete="current-password"
          variant="outlined"
          fullWidth
        />
        <StyledLink
          style={{ marginBottom: "20px" }}
          onClick={() => history.push("/forgotpassword")}
        >
          Forgot Your Password?
        </StyledLink>
        <StyledButton>Log In</StyledButton>
      </StyledForm>
    </div>
  );
}

export default SignInForm;
