import {
  // name as packageName,
  version as packageVersion,
} from "../../package.json";

//App Info
export const VERSION = packageVersion;
export const MAINTENANCE = false;
export const APP_MODE = window.location.href //Deployed environment associated with URL
  .toLowerCase()
  .startsWith("http://localhost:3000")
  ? "LOCAL"
  : window.location.href.toLowerCase().includes("amplify")
  ? "DEV"
  : "PROD";

// File constants
export const MAX_FILE_SIZE_MB = 2000; //2 GB is the AWS limit
export const MAX_DURATION_PER_FILE = 60 * 60 * 4; // 60 sec * 60 mins * 4 hours

//Strings
export const IN_PROGRESS = "In Progress";
export const COMPLETE = "Complete";
export const FAILED = "Failed. Not Charged";
export const TIMEOUT =
  "Processing failed due to timeout. Ensure your file contains audible speech and is at least 10 seconds long. You have not been charged";
export const WTDeliminator = "___WTspeakers";
export const KEY_TERMS_BOLD_DELIMINATOR = "**__WT__**";

// Stripe
export const STRIPE_MODE = "LIVE"; //LIVE or TEST
export const LIVE_STRIPE_PUBLISHABLE_KEY =
  "pk_live_51KFt3dCbbuFRxEYAMjdH5UL0MXrh7LXuNKGr7tDVI2Ip7BiHsCVIRT1jSXWstJyZjQVJAdZCuyqjbOhryUfxiP9S00czgkZeOi";
export const LIVE_PRICE_ID_HOUR = "price_1KaS65CbbuFRxEYAuCnftlqC";
export const LIVE_PRICE_ID_MIN = "price_1KaS5xCbbuFRxEYAcmtS10dj";
export const LIVE_PRICE_ID_HOUR_DISCOUNT = "price_1KwrYnCbbuFRxEYAahILgkkl";

export const TEST_STRIPE_PUBLISHABLE_KEY =
  "pk_test_51KFt3dCbbuFRxEYA5Bh0aaPS7c3RMXZm4vNLjn4x64pRDW7LDyU2UA5W6Le4eWZPwKpiT7Bh7xaPm97BV9QyfYwF00jrIXMJMc";
export const TEST_PRICE_ID_HOUR = "price_1KK5C0CbbuFRxEYA3tySFa5Q";
export const TEST_PRICE_ID_MIN = "price_1KOnHMCbbuFRxEYASZsnDBZj";
export const TEST_PRICE_ID_HOUR_DISCOUNT = "price_1KwrWXCbbuFRxEYAATNPW0Xu";

//Other
export const DEFAULT_FREE_SECONDS_TO_TRANSCRIBE = 60 * 60 * 5; // 5 hours of free transcription
export const CALL_TIMEOUT_SECONDS = 60 * 8; // Times out after 8 mins.
export const DISPLAY_CLASSIFIER_IN_CALL_PAGE = true; //Displays the classifier in call page
export const PAGES_TO_REDIRECT_HOME = [
  "/home",
  "/case",
  "/payment",
  "/successpayment",
  "/createcase",
  "/call",
  "/unauthorized",
]; //If user is not logged in, the pages that start with what is in this array will redirect to the home page
