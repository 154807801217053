import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import SEO from "../components/SEO.jsx";

// Components
import MainJumbotron from "../components/MainJumbotron.jsx";
import WarningErrorSnackbars from "../components/WarningErrorSnackbar.jsx";
import StyledButton from "../components/StyledButton.jsx";

//Utils
import { UserContext } from "../utils/context.js";
import { secsToString } from "../utils/helper functions/files_data_helpers.js";

//AWS
import { subscribeUpdateUser } from "../utils/subscriptions.js";
import { getUser } from "../utils/queries.js";
import { Analytics } from "aws-amplify";

//MUI
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import TextField from "@mui/material/TextField";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;

const CardsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 40px 20px 40px;

  ${(props) => props.theme.extraSmall} {
    flex-direction: column;
  }
  ${(props) => props.theme.small} {
    flex-direction: column;
  }
  ${(props) => props.theme.medium} {
    flex-direction: row;
    margin: 40px 20px 40px;
  }
`;

const CardWrapper = styled.div`
  margin-bottom: 20px;
  ${(props) => props.theme.medium} {
    padding: 0px 10px;
    margin-bottom: 0px;
  }
`;

const CardHeader = styled.div`
  font-weight: bold;
  font-size: 1.2rem;
  text-decoration: underline;
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
`;

const CardPrice = styled.div`
  font-weight: bold;
  font-size: 2rem;
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const ListItemWrapper = styled.div``;

const StyledTextField = styled(TextField)`
  .MuiFormLabel-root.Mui-focused {
    color: ${(props) => props.theme.primaryLight};
  }
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${(props) => props.theme.primaryLight};
  }
  .MuiInput-underline:after {
    border-bottom: 2px solid ${(props) => props.theme.primaryLight};
  }
`;

const BulkPurchaseSelect = () => {
  const [secondsAvailableToTranscribe, setSecondsAvailableToTranscribe] =
    useState(undefined);
  const [ErrorSnackbar, setErrorSnackbar] = useState(false);
  const [WarnSnackbar, setWarnSnackbar] = useState(false);
  const [customHours, setCustomHours] = useState(undefined);

  const user = useContext(UserContext);

  const sendAWSAnalyticsEvent = (hours) => {
    try {
      if (user) {
        let attr = { ...user.attributes };
        attr["id"] = attr.sub;
        delete attr.sub;
        Analytics.record({
          name: "Bulk Purchase Pressed",
          attributes: attr,
          metrics: { hours: hours },
        });
      }
    } catch {
      console.warn("Could not send analytics to AWS");
    }
  };

  //Subscribe to secondsAvailableToTranscribe.
  useEffect(() => {
    if (user) {
      let sub = subscribeUpdateUser(
        user.username,
        setSecondsAvailableToTranscribe
      );
      return () => {
        if (sub) {
          sub.unsubscribe();
        }
      };
    }
  }, [user]);

  /**
   * Gets current seconds available to transcribe
   */
  useEffect(() => {
    const getSeconds = async () => {
      try {
        // Get seconds available to transcribe
        const databaseUser = await getUser(user.username);
        setSecondsAvailableToTranscribe(
          databaseUser.secondsAvailableToTranscribe
        );
      } catch (err) {
        console.error(err);
        setErrorSnackbar(
          "Error validating transcription time available. Please refresh the page."
        );
      }
    };
    if (user) {
      getSeconds();
    }
  }, [user]);

  const HoursCard = (hours, price) => {
    return (
      <CardWrapper>
        <Card sx={{ minWidth: 200 }}>
          <CardContent>
            <CardHeader>{hours} Hours</CardHeader>
            <CardPrice>{price}</CardPrice>
            <ListItemWrapper>
              <ul>
                <li>
                  Adds{" "}
                  <span
                    style={{ textDecoration: "underline", fontWeight: "bold" }}
                  >
                    {hours}
                  </span>{" "}
                  hours of transcription time
                </li>
                {hours >= 1000 && (
                  <li>
                    <b>$1000 discount.</b>
                  </li>
                )}
                <li>Hours never expire</li>
                <li>Searching, saving, and printing are all free.</li>
              </ul>
            </ListItemWrapper>
          </CardContent>
          <CardActions>
            <ButtonWrapper>
              <StyledButton
                color={"white"}
                onClick={() => {
                  sendAWSAnalyticsEvent(hours);
                  window.open(
                    `${window.location.origin}/payment/${hours}/0`,
                    "_blank"
                  );
                }}
              >
                Select
              </StyledButton>
            </ButtonWrapper>
            {/* <Button size="small">Learn More</Button> */}
          </CardActions>
        </Card>
      </CardWrapper>
    );
  };

  return (
    <Wrapper>
      {" "}
      <SEO
        title="Bulk Purchase | WireTap"
        description="Purchase hours of transcription time in bulk. Use those transcriptions on your calls, body cams, or audio file. Download the transcription of the file for future use. Search through the transcriptions for key terms."
      />
      <MainJumbotron
        title="Purchase Transcription Time"
        metrics={[
          {
            header: "Transcription Time Available",
            body: secsToString(secondsAvailableToTranscribe),
          },
        ]}
      />
      <CardsWrapper>
        {HoursCard(10, "$ 149.90")}
        {HoursCard(100, "$ 1,499.00")}
        {HoursCard(1000, "$ 13,900.00")}
      </CardsWrapper>
      <WarningErrorSnackbars
        errorMessage={ErrorSnackbar}
        setErrorMessage={setErrorSnackbar}
        warningMessage={WarnSnackbar}
        setWarningMessage={setWarnSnackbar}
      />
      <CardsWrapper>OR</CardsWrapper>
      <CardsWrapper>
        <CardWrapper>
          <Card sx={{ minWidth: 200 }}>
            <CardContent>
              <CardHeader>Choose your own hours</CardHeader>
              <CardPrice>
                <StyledTextField
                  value={customHours}
                  onChange={(e) =>
                    setCustomHours(e.target.value.replace(/\D/g, ""))
                  }
                  label="Hours"
                  variant="outlined"
                />
              </CardPrice>
              <ListItemWrapper>
                <ul>
                  <li>
                    Purchasing 1000 or more hours gives you over a $1000
                    discount. $1 discount per hour.
                  </li>
                  <li>
                    Adds how many hours you want to your available transcription
                    time
                  </li>
                  <li>Hours never expire</li>
                  <li>Searching, saving, and printing are all free.</li>
                </ul>
              </ListItemWrapper>
            </CardContent>
            <CardActions>
              <ButtonWrapper>
                <StyledButton
                  color={"white"}
                  onClick={() =>
                    window.open(
                      `${window.location.origin}/payment/${
                        customHours && customHours >= 1 ? customHours : 1
                      }/0`,
                      "_blank"
                    )
                  }
                  disabled={!customHours || customHours < 1}
                >
                  Select
                </StyledButton>
              </ButtonWrapper>
              {/* <Button size="small">Learn More</Button> */}
            </CardActions>
          </Card>
        </CardWrapper>
      </CardsWrapper>
    </Wrapper>
  );
};

export default BulkPurchaseSelect;
