export const refreshAlert = (attempt, error) => {
  console.error(error);
  try {
    if (typeof error === String) {
      if (!alert(`Error trying to ${attempt}. Error is: ${error}`)) {
        window.location.reload();
      }
    } else {
      if (error.message) {
        if (!alert(`Error trying to ${attempt}. Error is: ${error.message} `)) {
          window.location.reload();
        }
      } else {
        if (
          !alert(
            `Error trying to ${attempt}. Error is: ${JSON.stringify(error)}`
          )
        ) {
          window.location.reload();
        }
      }
    }
  } catch (e) {
    if (
      !alert(
        `Error trying to ${attempt}. Error is: ${error} | Error trying to get more info out of original error: ${e.toString()} `
      )
    ) {
      window.location.reload();
    }
  }
};
