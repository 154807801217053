import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import MainJumbotron from "../components/MainJumbotron.jsx";
import StyledButton from "../components/StyledButton.jsx";
import Autocomplete from "@mui/material/Autocomplete";
import Badge from "@mui/material/Badge";
import { useHistory } from "react-router-dom";
import { TextField } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import Tooltip from "@mui/material/Tooltip";
import { getProject } from "../utils/queries.js";
import KeyTermsModal from "../components/KeyTermsModal.jsx";
import TemplateModal from "../components/TemplateModal.jsx";
import { COMPLETE, IN_PROGRESS } from "../utils/constants.js";
import RenderCellExpand from "../components/RenderCellExpand.jsx";
import { subscribeUpdateProject } from "../utils/subscriptions.js";
import { secsToString } from "../utils/helper functions/files_data_helpers.js";
import { UserContext } from "../utils/context.js";
import SEO from "../components/SEO.jsx";
import { deleteCalls } from "../utils/mutations.js";
import Backdrop from "@mui/material/Backdrop";
import Loader from "../components/Loader.jsx";
import { getCookieConsentValue } from "react-cookie-consent";

const Wrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  min-width: 800px;
`;

const MainContentWrapper = styled.div`
  margin: 0px 60px;
`;

const MainContentHeading = styled.div`
  padding: 30px 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const TableWrapper = styled.div`
  width: 100%;
  margin-bottom: 20px;
  .MuiDataGrid-root .MuiDataGrid-row {
    cursor: pointer;
  }
  margin-bottom: 20px;
`;

const TableButtonsWrapper = styled.div`
  border-top: 1px solid rgb(74, 74, 74);
  width: 100%;
  display: flex;
  flex-direction: row;
  height: 41px;
  padding-left: 6px;
`;

/**
 * Columns of the table
 */
const columns = [
  { field: "audioFileName", headerName: "File Name", width: 300 },
  {
    field: "dateUnixTime",
    headerName: "Date",
    width: 200,
    type: "dateTime",
    valueGetter: ({ value }) => new Date(value * 1000),
  },
  {
    field: "duration",
    headerName: "Duration",
    width: 200,
    type: "number",
    headerAlign: "left",
    align: "left",
    valueFormatter: ({ value }) => secsToString(value),
  },
  {
    field: "status",
    headerName: "Transcript Status",
    width: 150,
  },
  {
    field: "contains",
    headerName: "Contains",
    width: 469,
    valueGetter: ({ value }) => value.join("___"),
    renderCell: (params) => {
      return (
        <RenderCellExpand
          value={params.value.split("___")}
          width={params.colDef.width}
        />
      );
    },
  },
];

const CasePage = ({ match }) => {
  const consent = getCookieConsentValue("WireTapCookieConsent");
  const [pageSize, setPageSize] = useState(() => {
    if (consent === "false") {
      return 50;
    } else {
      return localStorage.getItem("CallsPageSize") || 50;
    }
  });

  const [currentCase, setCurrentCase] = useState({
    title: "",
    dateMade: "",
    type: "",
    callCount: "",
    totalDuration: "",
    status: "",
    calls: [],
  });
  const [evidenceCount, setEvidenceCount] = useState(0);
  const [inputValue, setInputValue] = useState("");
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [searchKeyTerms, setSearchKeyTerms] = useState(false);
  const [subscription, setSubscription] = useState(undefined);
  const [selectedRows, setSelectedRows] = useState([]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [sortModel, setSortModel] = useState([
    {
      field: "contains",
      sort: "desc",
    },
  ]);

  const history = useHistory();
  const user = useContext(UserContext);

  // Gets case from AWS
  useEffect(() => {
    const getCase = async () => {
      const caseID = match.params.id;
      const projectInfo = await getProject(caseID, false);
      if (typeof projectInfo === "string") {
        console.error(`Failed to receive project due to error: ${projectInfo}`);
        history.push("/unauthorized");
        return;
      }
      setCurrentCase(projectInfo);
    };
    if (user) {
      getCase();
    }
  }, [match.params.id, user, history]);

  // Subscribes to project when call not done transcribing
  useEffect(() => {
    const caseID = match.params.id;
    const setNewCall = async (call) => {
      const callIndex = currentCase.calls.findIndex(
        (element) => element.id === call.id
      );
      if (callIndex === -1) {
        setCurrentCase(await getProject(caseID, false));
      } else {
        //Merge dictionaries for a call and check the status of the project
        let newCalls = [...currentCase.calls];
        newCalls[callIndex] = { ...newCalls[callIndex], ...call };

        //Check if project is still in progress or not.
        let projectStatus = COMPLETE;
        for (const tempCall of newCalls) {
          if (tempCall.status === IN_PROGRESS) {
            projectStatus = IN_PROGRESS;
          }
        }
        setCurrentCase({
          ...currentCase,
          calls: newCalls,
          status: projectStatus,
        });
      }
    };

    if (currentCase.calls.some((call) => !call.transcriptionReady)) {
      // If any call is not done transcribing
      if (!subscription) {
        // check if a subscription is already going on
        const sub = subscribeUpdateProject(caseID, setNewCall); // If not, create a subscription
        setSubscription(sub);
      }
    } else if (subscription) {
      subscription.unsubscribe();
    }
    return () => {
      if (subscription) {
        subscription.unsubscribe();
        setSubscription(undefined);
      }
    };
  }, [currentCase, match.params.id, subscription]);

  // Updates calls to have a proper status of in progress or complete
  useEffect(() => {
    if (currentCase.title) {
      let rows = [];
      currentCase.calls.forEach((item, index) => {
        if (
          item.audioFileName.toLowerCase().includes(inputValue.toLowerCase())
        ) {
          if (item.status === COMPLETE) {
            item.status = `${COMPLETE} ✓`;
          } else if (item.status === IN_PROGRESS) {
            item.status = `${IN_PROGRESS}...`;
          }
          rows.push(item);
        }
      });
      setRows(rows);
      setLoading(false);
    }
  }, [currentCase.title, currentCase.calls, inputValue]);

  // Updates evidence count to be used on the badge for "evidence found" button by looping through calls in currentCase
  useEffect(() => {
    if (currentCase.calls.length > 0) {
      let count = 0;
      for (const call of currentCase.calls) {
        if (
          call.classifierContainsEvidence &&
          !call.classifierHasBeenReviewed
        ) {
          count++;
        }
      }
      setEvidenceCount(count);
    }
  }, [currentCase.calls]);

  return (
    <Wrapper>
      <SEO
        title="Case | WireTap"
        description="Review your audio files for a single case. Search through the audio files looking for specific key terms. "
      />
      <MainJumbotron
        title={currentCase.title}
        metrics={[
          { header: "Date Made", body: currentCase.dateMade },
          { header: "Type", body: currentCase.type },
          { header: "Call Count", body: currentCase.callCount },
          { header: "Total Call Time", body: currentCase.totalDuration },
          { header: "Status", body: currentCase.status },
        ]}
        breadcrumb={[
          { name: "My Cases", link: `/home` },
          { name: currentCase.title },
        ]}
      />

      <MainContentWrapper>
        <MainContentHeading>
          <Autocomplete
            id="combo-box-demo"
            options={currentCase.calls}
            getOptionLabel={(option) => option.audioFileName}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
            }}
            style={{ width: 300 }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Search for call"
                variant="outlined"
              />
            )}
          />
          <div>
            <Badge
              badgeContent={evidenceCount}
              sx={{
                "& .MuiBadge-badge": {
                  color: "white",
                  backgroundColor: "red",
                },
              }}
            >
              <StyledButton
                color={"white"}
                style={{
                  background: "linear-gradient(#209BCF, #26B7F5)",
                }}
                onClick={() =>
                  history.push(`/case/${match.params.id}/evidence`)
                }
              >
                Evidence Found
              </StyledButton>
            </Badge>
            <StyledButton
              color={"white"}
              style={{
                background: "linear-gradient(#209BCF, #26B7F5)",
                marginLeft: "20px",
              }}
              onClick={() => setSearchKeyTerms(true)}
            >
              Find Key Terms
            </StyledButton>
            <StyledButton
              color={"#209BCF"}
              style={{
                background: "linear-gradient(#FFFFFF, #E9E9E9)",
                marginLeft: "20px",
              }}
              onClick={() => history.push(`/case/${match.params.id}/addcalls`)}
            >
              + Add new calls
            </StyledButton>
          </div>
        </MainContentHeading>
        <TableButtonsWrapper>
          {selectedRows.length > 0 && (
            <Tooltip title="Delete" arrow>
              <IconButton
                aria-label="delete"
                onClick={() => setOpenDeleteModal(true)}
              >
                <DeleteIcon sx={{ color: "rgb(74, 74, 74)" }} />
              </IconButton>
            </Tooltip>
          )}
        </TableButtonsWrapper>
        <TableWrapper>
          <DataGrid
            rows={rows}
            columns={columns}
            disableColumnFilter
            pageSize={parseInt(pageSize)}
            autoHeight
            disableColumnMenu
            sortModel={sortModel}
            onSortModelChange={(model) => setSortModel(model)}
            rowsPerPageOptions={[5, 10, 20, 50, 100]}
            checkboxSelection
            onSelectionModelChange={(selectedRowIds) => {
              setSelectedRows(selectedRowIds);
            }}
            onPageSizeChange={(newPageSize) => {
              if (consent !== "false") {
                localStorage.setItem("CallsPageSize", newPageSize);
              }
              setPageSize(newPageSize);
            }}
            onRowClick={(gridRowParam, event) => {
              history.push(`/call/${gridRowParam.row.id}`);
            }}
            loading={loading}
          />
        </TableWrapper>
      </MainContentWrapper>
      <KeyTermsModal
        open={searchKeyTerms}
        setOpen={setSearchKeyTerms}
        caseID={match.params.id}
      />
      <TemplateModal
        open={openDeleteModal}
        setOpen={setOpenDeleteModal}
        header={selectedRows.length > 1 ? "Delete Files" : "Delete File"}
        subHeader={`Deleting ${selectedRows.length} file${
          selectedRows.length > 1 ? "s" : ""
        }`}
        bodyElements={
          <span style={{ marginTop: "10px" }}>
            You are about to delete {selectedRows.length} file
            {selectedRows.length > 1 ? "s" : ""}. <b>This cannot be undone.</b>{" "}
            Please confirm you wish to delete{" "}
            {selectedRows.length > 1 ? "these files" : "this file"}.
          </span>
        }
        size="small"
        closeButton="Delete"
        closeCallback={async () => {
          setDeleteLoading(true);
          await deleteCalls(selectedRows);
          const caseID = match.params.id;
          setCurrentCase(await getProject(caseID, false));
          setDeleteLoading(false);
        }}
      />
      <Backdrop open={deleteLoading}>
        <Loader width={40} height={40} fill="white" />
      </Backdrop>
    </Wrapper>
  );
};

export default CasePage;
