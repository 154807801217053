import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import Loader from "../components/Loader.jsx";
import { API } from "aws-amplify";
import { loadStripe } from "@stripe/stripe-js";
import { UserContext } from "../utils/context.js";
import {
  STRIPE_MODE,
  LIVE_STRIPE_PUBLISHABLE_KEY,
  LIVE_PRICE_ID_HOUR,
  LIVE_PRICE_ID_MIN,
  LIVE_PRICE_ID_HOUR_DISCOUNT,
  TEST_STRIPE_PUBLISHABLE_KEY,
  TEST_PRICE_ID_HOUR,
  TEST_PRICE_ID_MIN,
  TEST_PRICE_ID_HOUR_DISCOUNT,
} from "../utils/constants.js";
import WarningErrorSnackbars from "../components/WarningErrorSnackbar.jsx";
import SEO from "../components/SEO.jsx";
import { Analytics } from "aws-amplify";

const Wrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Title = styled.h1``;

const SubTitle = styled.h3``;

const PRICE_ID_HOUR =
  STRIPE_MODE === "LIVE" ? LIVE_PRICE_ID_HOUR : TEST_PRICE_ID_HOUR;
const PRICE_ID_HOUR_DISCOUNT =
  STRIPE_MODE === "LIVE"
    ? LIVE_PRICE_ID_HOUR_DISCOUNT
    : TEST_PRICE_ID_HOUR_DISCOUNT;
const PRICE_ID_MIN =
  STRIPE_MODE === "LIVE" ? LIVE_PRICE_ID_MIN : TEST_PRICE_ID_MIN;
const stripePromise =
  STRIPE_MODE === "LIVE"
    ? loadStripe(LIVE_STRIPE_PUBLISHABLE_KEY)
    : loadStripe(TEST_STRIPE_PUBLISHABLE_KEY);

const PaymentPage = ({ match }) => {
  const [error, setError] = useState("");
  const hours = parseInt(match.params.hours);
  const mins = parseInt(match.params.mins);
  const user = useContext(UserContext);

  // Render the payment page
  useEffect(() => {
    const redirectToCheckout = async () => {
      const fetchSession = async () => {
        const client_ref_id = user.username;
        const name = `${user.attributes.given_name} ${user.attributes.family_name}`;
        const apiName = "stripeAPI";
        const apiEndpoint = "/checkout";
        let lineItems = [];
        if (hours >= 1000) {
          lineItems.push({
            price: PRICE_ID_HOUR_DISCOUNT, // The priceId of the product being purchased, retrievable from the Stripe dashboard
            quantity: hours,
          });
        } else if (hours > 0) {
          lineItems.push({
            price: PRICE_ID_HOUR, // The priceId of the product being purchased, retrievable from the Stripe dashboard
            quantity: hours,
          });
        }
        if (mins > 0) {
          lineItems.push({
            price: PRICE_ID_MIN,
            quantity: mins,
          });
        }

        if (lineItems.length === 0) {
          setError("Not Purchasing any transcription time.");
          console.error("Not Purchasing any transcription time.");
          return;
        }

        if (hours === 0 && mins < 2) {
          setError("Must purchase at least 2 minutes.");
          console.error("Must purchase at least 2 minutes.");
          return;
        }

        //Send AWS Analytics
        try {
          if (user) {
            let attr = { ...user.attributes };
            attr["id"] = attr.sub;
            delete attr.sub;
            Analytics.record({
              name: "Going to Payment",
              attributes: attr,
              metrics: { hours: hours, mins: mins },
              immediate: true,
            });
          }
        } catch {
          console.warn("Could not send analytics to AWS");
        }

        const body = {
          client_reference_id: client_ref_id,
          name: name,
          lineItems: lineItems,
          customer_email: user.attributes.email,
          success_url:
            window.location.origin + `/successpayment/${hours}/${mins}`,
          cancel_url:
            window.location.origin + `/cancelpayment/${hours}/${mins}`, //window.location.origin + "/home",
          mode: "payment",
        };
        const session = await API.post(apiName, apiEndpoint, { body });
        return session;
      };

      let session = undefined;
      try {
        session = await fetchSession();
        const sessionId = session.id;
        const stripe = await stripePromise;
        stripe.redirectToCheckout({ sessionId });
      } catch (error) {
        if (session) {
          console.error(session.raw.message);
        } else {
          console.error(error);
        }
      }
    };
    if (user) {
      redirectToCheckout();
    }
  }, [hours, mins, user]);

  return (
    <Wrapper>
      <SEO
        title="Payment | WireTap"
        description="Hold on just a second as we load the payment page."
      />
      <Title>Loading Payment Page</Title>
      <Loader />
      <SubTitle>
        Paying for: {hours > 0 ? `${hours} hours` : ``}{" "}
        {mins > 0 ? `${mins} mins` : ``}
      </SubTitle>
      <WarningErrorSnackbars errorMessage={error} setErrorMessage={setError} />
    </Wrapper>
  );
};

export default PaymentPage;
